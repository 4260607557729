import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  PartnerAgentByIDErrorResponse, PartnerAgentIDsResponse,
  PartnerAgentsErrorResponse,
  PartnerAgentsRequestParams,
  PartnerAgentsResponse,
  PostPartnerAgentErrorResponse,
  PostPartnerAgentRequestParams,
  PutPartnerAgentErrorResponse,
  PutPartnerAgentRequestParams,
} from '../../api/partnerAgents'
import PartnerAgent from '../../model/PartnerAgent'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchPartnerAgentsAction {
  type: typeof types.FETCH_PARTNER_AGENTS
  success: undefined
  payload: PartnerAgentsRequestParams
}

export interface ResolveFetchPartnerAgentsAction {
  type: typeof types.FETCH_PARTNER_AGENTS
  success: boolean
  payload: {
    partnerAgents: PartnerAgent[]
    paginationInfo: PaginationInfo
    requestParams: PartnerAgentsRequestParams
  }
}

export interface RejectFetchPartnerAgentsAction {
  type: typeof types.FETCH_PARTNER_AGENTS
  success: boolean
  payload: {
    error: Error
    requestParams: PartnerAgentsRequestParams
  }
}

export interface FetchPartnerAgentByIDAction {
  type: typeof types.FETCH_PARTNER_AGENT_BY_ID
  success: undefined
  payload: {
    partnerAgentID: number
  }
}

export interface ResolveFetchPartnerAgentByIDAction {
  type: typeof types.FETCH_PARTNER_AGENT_BY_ID
  success: boolean
  payload: {
    partnerAgent: PartnerAgent
  }
}

export interface RejectFetchPartnerAgentByIDAction {
  type: typeof types.FETCH_PARTNER_AGENT_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      partnerAgentID: number
    }
  }
}

export interface UpdatePartnerAgentAction {
  type: typeof types.UPDATE_PARTNER_AGENT
  success: undefined
  payload: PutPartnerAgentRequestParams
}

export interface ResolveUpdatePartnerAgentAction {
  type: typeof types.UPDATE_PARTNER_AGENT
  success: boolean
  payload: {
    partnerAgent: PartnerAgent
  }
}

export interface RejectUpdatePartnerAgentAction {
  type: typeof types.UPDATE_PARTNER_AGENT
  success: boolean
  payload: PutPartnerAgentErrorResponse
}


export interface DeletePartnerAgentAction {
  type: typeof types.DELETE_PARTNER_AGENT
  success: undefined
  payload: {
    partnerAgentID: number
  }
}

export interface ResolveDeletePartnerAgentAction {
  type: typeof types.DELETE_PARTNER_AGENT
  success: boolean
  payload: {
    partnerAgent: PartnerAgent
  }
}

export interface RejectDeletePartnerAgentAction {
  type: typeof types.DELETE_PARTNER_AGENT
  success: boolean
  payload: PartnerAgentByIDErrorResponse
}

export interface CreatePartnerAgentAction {
  type: typeof types.CREATE_PARTNER_AGENT
  success: undefined
  payload: PostPartnerAgentRequestParams
}

export interface ResolveCreatePartnerAgentAction {
  type: typeof types.CREATE_PARTNER_AGENT
  success: boolean
  payload: {
    partnerAgent: PartnerAgent
  }
}

export interface RejectCreatePartnerAgentAction {
  type: typeof types.CREATE_PARTNER_AGENT
  success: boolean
  payload: PostPartnerAgentErrorResponse
}

export const fetchPartnerAgents = (sorting: string, page: number, limit: number, search: string): FetchPartnerAgentsAction => ({
  type:    types.FETCH_PARTNER_AGENTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchPartnerAgents = ({
                                        partnerAgents,
                                        paginationInfo,
                                        requestParams,
                                      }: PartnerAgentsResponse): ResolveFetchPartnerAgentsAction => ({
  type:    types.FETCH_PARTNER_AGENTS,
  success: true,
  payload: {
    partnerAgents,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchPartnerAgents = ({ error, requestParams }: PartnerAgentsErrorResponse): RejectFetchPartnerAgentsAction => ({
  type:    types.FETCH_PARTNER_AGENTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchPartnerAgentByID = (partnerAgentID: number): FetchPartnerAgentByIDAction => ({
  type:    types.FETCH_PARTNER_AGENT_BY_ID,
  success: undefined,
  payload: {
    partnerAgentID,
  },
})

export const resolveFetchPartnerAgentByID = (partnerAgent: PartnerAgent): ResolveFetchPartnerAgentByIDAction => ({
  type:    types.FETCH_PARTNER_AGENT_BY_ID,
  success: true,
  payload: {
    partnerAgent,
  },
})

export const rejectFetchPartnerAgentByID = ({
                                          error,
                                          requestParams,
                                        }: PartnerAgentByIDErrorResponse): RejectFetchPartnerAgentByIDAction => ({
  type:    types.FETCH_PARTNER_AGENT_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updatePartnerAgent = (partnerAgentID: number, name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): UpdatePartnerAgentAction => ({
  type:    types.UPDATE_PARTNER_AGENT,
  success: undefined,
  payload: {
    partnerAgentID,
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveUpdatePartnerAgent = (partnerAgent: PartnerAgent): ResolveUpdatePartnerAgentAction => ({
  type:    types.UPDATE_PARTNER_AGENT,
  success: true,
  payload: {
    partnerAgent,
  },
})

export const rejectUpdatePartnerAgent = (payload: PutPartnerAgentErrorResponse): RejectUpdatePartnerAgentAction => ({
  type:    types.UPDATE_PARTNER_AGENT,
  success: false,
  payload,
})


export const deletePartnerAgent = (partnerAgentID: number): DeletePartnerAgentAction => ({
  type:    types.DELETE_PARTNER_AGENT,
  success: undefined,
  payload: {
    partnerAgentID,
  },
})

export const resolveDeletePartnerAgent = (partnerAgent: PartnerAgent): ResolveDeletePartnerAgentAction => ({
  type:    types.DELETE_PARTNER_AGENT,
  success: true,
  payload: {
    partnerAgent,
  },
})

export const rejectDeletePartnerAgent = (payload: PartnerAgentByIDErrorResponse): RejectDeletePartnerAgentAction => ({
  type:    types.DELETE_PARTNER_AGENT,
  success: false,
  payload,
})


export const createPartnerAgent = (name: string, company: string | null,  phoneNumber: string | null, emailAddress: string | null): CreatePartnerAgentAction => ({
  type:    types.CREATE_PARTNER_AGENT,
  success: undefined,
  payload: {
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveCreatePartnerAgent = (partnerAgent: PartnerAgent): ResolveCreatePartnerAgentAction => ({
  type:    types.CREATE_PARTNER_AGENT,
  success: true,
  payload: {
    partnerAgent,
  },
})

export const rejectCreatePartnerAgent = (payload: PostPartnerAgentErrorResponse): RejectCreatePartnerAgentAction => ({
  type:    types.CREATE_PARTNER_AGENT,
  success: false,
  payload,
})

export type PartnerAgentsActions =
  FetchPartnerAgentsAction
  | RejectFetchPartnerAgentsAction
  | ResolveFetchPartnerAgentsAction
  | FetchPartnerAgentByIDAction
  | ResolveFetchPartnerAgentByIDAction
  | RejectFetchPartnerAgentByIDAction
  | UpdatePartnerAgentAction
  | RejectUpdatePartnerAgentAction
  | ResolveUpdatePartnerAgentAction
  | CreatePartnerAgentAction
  | ResolveCreatePartnerAgentAction
  | RejectCreatePartnerAgentAction
  | DeletePartnerAgentAction
  | ResolveDeletePartnerAgentAction
  | RejectDeletePartnerAgentAction
  | ClearFlashMessagesAction
  | LocationChangeAction
