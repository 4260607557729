import { createSelector } from 'reselect'
import { SmsBlastsRequestParams } from '../../api/smsBlasts'
import SmsBlast from '../../model/SmsBlast'
import { RootReducerState } from '../'
import { SmsBlastsReducerPage } from '../reducers/smsBlasts'

export const hashKeyForSmsBlastPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search,
                                   }: SmsBlastsRequestParams): string => `${page},${limit},${sorting}`

const smsBlastsByIDSelector = (state: RootReducerState): { [id: number]: SmsBlast } => state.smsBlasts.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.smsBlasts.errorByID
const smsBlastIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.smsBlasts.isFetchingByID
const smsBlastPageSelector = (state: RootReducerState, props: any): SmsBlastsReducerPage => state.smsBlasts.pages[hashKeyForSmsBlastPage(props)] || new SmsBlastsReducerPage()
export const smsBlastFlashMessageSelector = (state: RootReducerState): string | null => state.smsBlasts.successFlashMessage
// TODO: fix for react router
export const smsBlastIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.smsBlastID || -1
}

export const smsBlastWithIDFromProps = createSelector(
  [smsBlastsByIDSelector, smsBlastIDFromPropsSelector],
  (smsBlasts, smsBlastID) => smsBlasts[smsBlastID] ,
)

export const errorForSmsBlastWithIDFromProps = createSelector(
  [errorsSelector, smsBlastIDFromPropsSelector],
  (smsBlasts, smsBlastID) => smsBlasts[smsBlastID],
)

export const isFetchingForSmsBlastWithIDFromProps = createSelector(
  [smsBlastIsFetchingByIDSelector, smsBlastIDFromPropsSelector],
  (smsBlasts, smsBlastID) => smsBlasts[smsBlastID] || false,
)

export const smsBlastsByPageSelector = createSelector(
  [smsBlastsByIDSelector, smsBlastPageSelector],
  (smsBlasts, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(smsBlastID => smsBlasts[smsBlastID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [smsBlastPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [smsBlastPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [smsBlastPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [smsBlastPageSelector],
  (page) => page.error || null,
)

