import { createSelector } from 'reselect'
import { PartnerAgentsRequestParams } from '../../api/partnerAgents'
import PartnerAgent from '../../model/PartnerAgent'
import { RootReducerState } from '../'
import { PartnerAgentsReducerPage } from '../reducers/partnerAgents'

export const hashKeyForPartnerAgentPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: PartnerAgentsRequestParams): string => `${page},${limit},${sorting},${search}`

export const partnerAgentsSelector = (state: RootReducerState): { [id: number]: PartnerAgent } => state.partnerAgents.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.partnerAgents.errorByID
const partnerAgentIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.partnerAgents.isFetchingByID

const partnerAgentPageSelector = (state: RootReducerState, props: any): PartnerAgentsReducerPage => state.partnerAgents.pages[hashKeyForPartnerAgentPage(props)] || new PartnerAgentsReducerPage()
export const partnerAgentFlashMessageSelector = (state: RootReducerState): string | null => state.partnerAgents.successFlashMessage

// TODO: fix for react router
export const partnerAgentIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.partnerAgentID || -1
}

export const partnerAgentWithIDFromProps = createSelector(
  [partnerAgentsSelector, partnerAgentIDFromPropsSelector],
  (partnerAgents, partnerAgentID) => partnerAgents[partnerAgentID],
)

export const errorForPartnerAgentWithIDFromProps = createSelector(
  [errorsSelector, partnerAgentIDFromPropsSelector],
  (partnerAgents, partnerAgentID) => partnerAgents[partnerAgentID],
)

export const isFetchingForPartnerAgentWithIDFromProps = createSelector(
  [partnerAgentIsFetchingByIDSelector, partnerAgentIDFromPropsSelector],
  (partnerAgents, partnerAgentID) => partnerAgents[partnerAgentID] || false,
)

export const partnerAgentsByPageSelector = createSelector(
  [partnerAgentsSelector, partnerAgentPageSelector],
  (partnerAgents, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(partnerAgentID => partnerAgents[partnerAgentID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [partnerAgentPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [partnerAgentPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [partnerAgentPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [partnerAgentPageSelector],
  (page) => page.error || null,
)

