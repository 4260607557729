import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  PaymentAccountByIDErrorResponse, PaymentAccountIDsResponse,
  PaymentAccountsErrorResponse,
  PaymentAccountsRequestParams,
  PaymentAccountsResponse,
  PostPaymentAccountErrorResponse,
  PostPaymentAccountRequestParams,
  PutPaymentAccountErrorResponse,
  PutPaymentAccountRequestParams,
} from '../../api/paymentAccounts'
import PaymentAccount from '../../model/PaymentAccount'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchPaymentAccountsAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNTS
  success: undefined
  payload: PaymentAccountsRequestParams
}

export interface ResolveFetchPaymentAccountsAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNTS
  success: boolean
  payload: {
    paymentAccounts: PaymentAccount[]
    paginationInfo: PaginationInfo
    requestParams: PaymentAccountsRequestParams
  }
}

export interface RejectFetchPaymentAccountsAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNTS
  success: boolean
  payload: {
    error: Error
    requestParams: PaymentAccountsRequestParams
  }
}

export interface FetchPaymentAccountByIDAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNT_BY_ID
  success: undefined
  payload: {
    paymentAccountID: number
  }
}

export interface ResolveFetchPaymentAccountByIDAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNT_BY_ID
  success: boolean
  payload: {
    paymentAccount: PaymentAccount
  }
}

export interface RejectFetchPaymentAccountByIDAction {
  type: typeof types.FETCH_PAYMENT_ACCOUNT_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      paymentAccountID: number
    }
  }
}

export interface UpdatePaymentAccountAction {
  type: typeof types.UPDATE_PAYMENT_ACCOUNT
  success: undefined
  payload: PutPaymentAccountRequestParams
}

export interface ResolveUpdatePaymentAccountAction {
  type: typeof types.UPDATE_PAYMENT_ACCOUNT
  success: boolean
  payload: {
    paymentAccount: PaymentAccount
  }
}

export interface RejectUpdatePaymentAccountAction {
  type: typeof types.UPDATE_PAYMENT_ACCOUNT
  success: boolean
  payload: PutPaymentAccountErrorResponse
}


export interface DeletePaymentAccountAction {
  type: typeof types.DELETE_PAYMENT_ACCOUNT
  success: undefined
  payload: {
    paymentAccountID: number
  }
}

export interface ResolveDeletePaymentAccountAction {
  type: typeof types.DELETE_PAYMENT_ACCOUNT
  success: boolean
  payload: {
    paymentAccount: PaymentAccount
  }
}

export interface RejectDeletePaymentAccountAction {
  type: typeof types.DELETE_PAYMENT_ACCOUNT
  success: boolean
  payload: PaymentAccountByIDErrorResponse
}

export interface CreatePaymentAccountAction {
  type: typeof types.CREATE_PAYMENT_ACCOUNT
  success: undefined
  payload: PostPaymentAccountRequestParams
}

export interface ResolveCreatePaymentAccountAction {
  type: typeof types.CREATE_PAYMENT_ACCOUNT
  success: boolean
  payload: {
    paymentAccount: PaymentAccount
  }
}

export interface RejectCreatePaymentAccountAction {
  type: typeof types.CREATE_PAYMENT_ACCOUNT
  success: boolean
  payload: PostPaymentAccountErrorResponse
}

export const fetchPaymentAccounts = (sorting: string, page: number, limit: number, search: string): FetchPaymentAccountsAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchPaymentAccounts = ({
                                        paymentAccounts,
                                        paginationInfo,
                                        requestParams,
                                      }: PaymentAccountsResponse): ResolveFetchPaymentAccountsAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNTS,
  success: true,
  payload: {
    paymentAccounts,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchPaymentAccounts = ({ error, requestParams }: PaymentAccountsErrorResponse): RejectFetchPaymentAccountsAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchPaymentAccountByID = (paymentAccountID: number): FetchPaymentAccountByIDAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNT_BY_ID,
  success: undefined,
  payload: {
    paymentAccountID,
  },
})

export const resolveFetchPaymentAccountByID = (paymentAccount: PaymentAccount): ResolveFetchPaymentAccountByIDAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNT_BY_ID,
  success: true,
  payload: {
    paymentAccount,
  },
})

export const rejectFetchPaymentAccountByID = ({
                                          error,
                                          requestParams,
                                        }: PaymentAccountByIDErrorResponse): RejectFetchPaymentAccountByIDAction => ({
  type:    types.FETCH_PAYMENT_ACCOUNT_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updatePaymentAccount = (paymentAccountID: number, name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): UpdatePaymentAccountAction => ({
  type:    types.UPDATE_PAYMENT_ACCOUNT,
  success: undefined,
  payload: {
    paymentAccountID,
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveUpdatePaymentAccount = (paymentAccount: PaymentAccount): ResolveUpdatePaymentAccountAction => ({
  type:    types.UPDATE_PAYMENT_ACCOUNT,
  success: true,
  payload: {
    paymentAccount,
  },
})

export const rejectUpdatePaymentAccount = (payload: PutPaymentAccountErrorResponse): RejectUpdatePaymentAccountAction => ({
  type:    types.UPDATE_PAYMENT_ACCOUNT,
  success: false,
  payload,
})


export const deletePaymentAccount = (paymentAccountID: number): DeletePaymentAccountAction => ({
  type:    types.DELETE_PAYMENT_ACCOUNT,
  success: undefined,
  payload: {
    paymentAccountID,
  },
})

export const resolveDeletePaymentAccount = (paymentAccount: PaymentAccount): ResolveDeletePaymentAccountAction => ({
  type:    types.DELETE_PAYMENT_ACCOUNT,
  success: true,
  payload: {
    paymentAccount,
  },
})

export const rejectDeletePaymentAccount = (payload: PaymentAccountByIDErrorResponse): RejectDeletePaymentAccountAction => ({
  type:    types.DELETE_PAYMENT_ACCOUNT,
  success: false,
  payload,
})


export const createPaymentAccount = (name: string, company: string | null,  phoneNumber: string | null, emailAddress: string | null): CreatePaymentAccountAction => ({
  type:    types.CREATE_PAYMENT_ACCOUNT,
  success: undefined,
  payload: {
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveCreatePaymentAccount = (paymentAccount: PaymentAccount): ResolveCreatePaymentAccountAction => ({
  type:    types.CREATE_PAYMENT_ACCOUNT,
  success: true,
  payload: {
    paymentAccount,
  },
})

export const rejectCreatePaymentAccount = (payload: PostPaymentAccountErrorResponse): RejectCreatePaymentAccountAction => ({
  type:    types.CREATE_PAYMENT_ACCOUNT,
  success: false,
  payload,
})

export type PaymentAccountsActions =
  FetchPaymentAccountsAction
  | RejectFetchPaymentAccountsAction
  | ResolveFetchPaymentAccountsAction
  | FetchPaymentAccountByIDAction
  | ResolveFetchPaymentAccountByIDAction
  | RejectFetchPaymentAccountByIDAction
  | UpdatePaymentAccountAction
  | RejectUpdatePaymentAccountAction
  | ResolveUpdatePaymentAccountAction
  | CreatePaymentAccountAction
  | ResolveCreatePaymentAccountAction
  | RejectCreatePaymentAccountAction
  | DeletePaymentAccountAction
  | ResolveDeletePaymentAccountAction
  | RejectDeletePaymentAccountAction
  | ClearFlashMessagesAction
  | LocationChangeAction
