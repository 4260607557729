import { createSelector } from 'reselect'
import { InvoicesRequestParams } from '../../api/invoices'
import Invoice from '../../model/Invoice'
import { RootReducerState } from '../'
import PaginationInfo from '../../model/PaginationInfo'
import { InvoicesReducerPage } from '../reducers/invoices'

export const hashKeyForInvoicePage = ({
                                     sorting,
                                     page,
                                     limit,
                                     filters,
                                     search = "",
                                   }: InvoicesRequestParams): string => `${page},${limit},${sorting},${JSON.stringify(filters)},${search}`

const invoicesSelector = (state: RootReducerState): { [id: number]: Invoice } => state.invoices.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.invoices.errorByID
const invoiceIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.invoices.isFetchingByID
const invoicePageSelector = (state: RootReducerState, props: any): InvoicesReducerPage | null => state.invoices.pages[hashKeyForInvoicePage(props)] || null
export const invoiceFlashMessageSelector = (state: RootReducerState): string | null => state.invoices.successFlashMessage

// TODO: fix for react router
export const invoiceIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.invoiceID || -1
}

export const invoiceWithIDFromProps = createSelector(
  [invoicesSelector, invoiceIDFromPropsSelector],
  (invoices, invoiceID) => invoices[invoiceID] ,
)

export const errorForInvoiceWithIDFromProps = createSelector(
  [errorsSelector, invoiceIDFromPropsSelector],
  (invoices, invoiceID) => invoices[invoiceID],
)

export const isFetchingForInvoiceWithIDFromProps = createSelector(
  [invoiceIsFetchingByIDSelector, invoiceIDFromPropsSelector],
  (isFetching, invoiceID) => isFetching[invoiceID] || false,
)

export const invoicesByPageSelector = createSelector(
  [invoicesSelector, invoicePageSelector],
  (invoices, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(invoiceID => invoices[invoiceID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [invoicePageSelector],
  (page) => page?.paginationInfo || new PaginationInfo(),
)

export const pageIsFetchingSelector = createSelector(
  [invoicePageSelector],
  (page) => page?.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [invoicePageSelector],
  (page) => page?.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [invoicePageSelector],
  (page) => page?.error || null,
)

