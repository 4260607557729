import { push, replace } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import branchVisibilityForm from '../../components/ProductForm/BranchVisibilityForm'
import PaginationInfo from '../../model/PaginationInfo'
import Product from '../../model/Product'
import { ResolveFetchCategoriesAction, ResolveFetchCategoryByIDAction } from '../actions/categories'
import {
  CreateProductAction,
  DeleteProductAction,
  FetchProductByIDAction,
  FetchProductsAction,
  ProductsActions,
  rejectCreateProduct,
  RejectCreateProductAction,
  rejectDeleteProduct,
  RejectDeleteProductAction,
  rejectFetchProductByID,
  RejectFetchProductByIDAction,
  rejectFetchProducts,
  RejectFetchProductsAction,
  rejectUpdateProduct,
  RejectUpdateProductAction,
  resolveCreateProduct,
  ResolveCreateProductAction,
  resolveDeleteProduct,
  ResolveDeleteProductAction,
  resolveFetchProductByID,
  ResolveFetchProductByIDAction,
  resolveFetchProducts,
  ResolveFetchProductsAction,
  resolveUpdateProduct,
  ResolveUpdateProductAction,
  UpdateProductAction,
} from '../actions/products'
import { ResolveCreatePurchasePromoAction } from '../actions/promos'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForProductPage } from '../selectors/products'

export interface IDMappedProducts {
  [id: number]: Product
}

interface ProductsReducerState {
  byID: IDMappedProducts
  pages: { [hash: string]: ProductsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
  variantIsFetchingByID: { [id: number]: boolean }
  variantErrorByID: { [id: number]: Error | null }
}

export class ProductsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: ProductsReducerState = {
  byID:                  {},
  pages:                 {},
  isFetchingByID:        {},
  successFlashMessage:   null,
  errorByID:             {},
  variantIsFetchingByID: {},
  variantErrorByID:      {},
}

export default (state: ProductsReducerState = initialState, action: ProductsActions): ProductsReducerState | Loop<ProductsReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchProductsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new ProductsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForProductPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getProducts, {
              successActionCreator: resolveFetchProducts,
              failActionCreator:    rejectFetchProducts,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProductsAction
          const { products, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForProductPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       products.map((product: Product) => product.id),
            paginationInfo: paginationInfo,
          }

          // Map product ids to products
          let idMappedProducts: IDMappedProducts = {}
          products.forEach(product => {
            idMappedProducts[product.id] = product
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedProducts),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchProductsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForProductPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PRODUCT_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchProductByIDAction
          const { productID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [productID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [productID]: null,
              },
            },
            Cmd.run(API.getProductByID, {
              successActionCreator: resolveFetchProductByID,
              failActionCreator:    rejectFetchProductByID,
              args:                 [productID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProductByIDAction
          const { product } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [product.id]: product,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [product.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchProductByIDAction
          const { error, requestParams } = payload
          const { productID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PRODUCT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateProductAction
          const {
                  productID,
                  name,
                  description,
                  categoryID,
                  normalPrice,
                  memberPrice,
                  primaryAttribute,
                  secondaryAttribute,
                  branchVisibility
                } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [productID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [productID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putProduct, {
              successActionCreator: resolveUpdateProduct,
              failActionCreator:    rejectUpdateProduct,
              args:                 [productID, name, description, categoryID, normalPrice, memberPrice, branchVisibility, primaryAttribute, secondaryAttribute],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateProductAction
          const { product } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [product.id]: product,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [product.id]: false,
              },
              successFlashMessage: 'Product successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateProductAction
          const { error, requestParams } = payload
          const { productID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PRODUCT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteProductAction
          const { productID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [productID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [productID]: null,
              },
            },
            Cmd.run(API.deleteProduct, {
              successActionCreator: resolveDeleteProduct,
              failActionCreator:    rejectDeleteProduct,
              args:                 [productID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteProductAction
          const { product } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[product.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [product.id]: false,
              },
              successFlashMessage: 'Product successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/products')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteProductAction
          const { error, requestParams } = payload
          const { productID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [productID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [productID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PRODUCT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateProductAction
          const { name, description, categoryID, normalPrice, memberPrice, branchVisibility, primaryAttribute, secondaryAttribute } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postProduct, {
              successActionCreator: resolveCreateProduct,
              failActionCreator:    rejectCreateProduct,
              args:                 [name, description, categoryID, normalPrice, memberPrice, branchVisibility, primaryAttribute, secondaryAttribute],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateProductAction
          const { product } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [product.id]: product,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Product successfully created!',
            },
            Cmd.list([
              Cmd.action(replace(`/products/${product.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateProductAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

    case actionTypes.FETCH_CATEGORIES: {
      let { success } = action
      switch (success) {

        case true: {
          let { payload } = action as ResolveFetchCategoriesAction
          const { categories } = payload

          let byID = { ...state.byID }
          categories.forEach(category => {
            if (category.products != null) {
              category.products.forEach(product => {
                // Very important to copy lest we fall into infinite recursion
                product.category = category.getCopy()
                byID[product.id] = product
              })
            }
          })

          return {
            ...state,
            byID,
          }
        }

        default:
          return state
      }
    }

    case actionTypes.FETCH_CATEGORY_BY_ID: {
      let { success } = action
      switch (success) {

        case true: {
          let { payload } = action as ResolveFetchCategoryByIDAction
          const { category } = payload

          let byID = { ...state.byID }
          if (category.products != null) {
            category.products.forEach(product => {
              product.category = category
              byID[product.id] = product
            })
          }

          return {
            ...state,
            byID,
          }
        }

        default:
          return state
      }
    }

    case actionTypes.UPDATE_PURCHASE_PROMO:
    case actionTypes.CREATE_PURCHASE_PROMO: {
      let { success } = action
      switch (success) {
        case true: {
          let { payload } = action as ResolveCreatePurchasePromoAction
          const { promo } = payload

          // Get the purchase requirement
          if (promo.purchaseRequirement == null) {
            return state
          }

          // Get the product that should be updated
          let productID = promo.purchaseRequirement.productID
          let product = state.byID[productID]
          if (product == null) {
            return state
          }

          // Modify and set the product
          product.name = promo.name
          product.description = promo.description
          product.normalPrice = promo.purchaseRequirement.normalPrice
          product.memberPrice = promo.purchaseRequirement.memberPrice

          let byID = {
            ...state.byID,
            [productID]: product,
            [666]:       product,
          }

          return {
            ...state,
            byID,
          }

        }

        default:
          return state
      }

      break
    }


  }

  // Default
  return state
}
