import { PaymentMethod } from './Invoice'

export default class PaymentAccount {
  id: number
  name: string
  merchantID: number
  paymentMethod: PaymentMethod
  accountID: string
  branchesUsedAt: number[]

  constructor(json: PaymentAccountJSON) {
    this.id = json.id
    this.name = json.name
    this.merchantID = json.merchantID
    this.paymentMethod = PaymentMethod.byID(json.paymentMethod)
    this.accountID = json.accountID
    this.branchesUsedAt = json.branchesUsedAt
  }

  get [Symbol.toStringTag]() {
    return 'PaymentAccount'
  }
}

export interface PaymentAccountJSON {
  id: number
  name: string
  merchantID: number
  paymentMethod: string
  accountID: string
  branchesUsedAt: number[]
}
