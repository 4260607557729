export default class PartnerAgent {
  id: number
  name: string
  company: string | null
  phoneNumber: string | null
  emailAddress: string | null
  createdAt: Date

  constructor(json: PartnerAgentJSON) {
    this.id = json.id
    this.name = json.name
    this.company = json.company
    this.phoneNumber = json.phoneNumber
    this.emailAddress = json.emailAddress
    this.createdAt = new Date(json.createdAt)
  }

  get [Symbol.toStringTag]() {
    return 'PartnerAgent'
  }
}

export interface PartnerAgentJSON {
  id: number
  name: string
  company: string | null
  phoneNumber: string | null
  emailAddress: string | null
  createdAt: string
}
