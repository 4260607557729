import { REMOVE_FLASH_MESSAGE, SHOW_FLASH_MESSAGE } from '../constants/ActionTypes'
import * as types from '../constants/ActionTypes'
import { FetchProductsAction } from './products'

export interface ToggleNavigationAction {
  type: typeof types.TOGGLE_NAVIGATION
}

export interface ShowFlashMessageAction {
  type: typeof types.SHOW_FLASH_MESSAGE
  payload: FlashMessage
}

export interface RemoveFlashMessageAction {
  type: typeof types.REMOVE_FLASH_MESSAGE
  payload: FlashMessage
}

export class FlashMessage {
  message: string
  type: FlashMessageType

  constructor(message: string, type: FlashMessageType) {
    this.message = message
    this.type = type
  }
}

export enum FlashMessageType {
  Error,
  Success,
  Warning
}

export const toggleNavigation = (): ToggleNavigationAction => ({
  type: types.TOGGLE_NAVIGATION,
})

export const flash = (message: string, type: FlashMessageType): ShowFlashMessageAction => ({
  type:    types.SHOW_FLASH_MESSAGE,
  payload: new FlashMessage(message, type),
})

export const removeFlash = (message: FlashMessage): RemoveFlashMessageAction => ({
  type:    types.REMOVE_FLASH_MESSAGE,
  payload: message,
})

export type UIActionTypes =
  ToggleNavigationAction
  | ShowFlashMessageAction
  | RemoveFlashMessageAction