import Branch, { BranchJSON } from '../model/Branch'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const putBranch = async (requestParams: PutBranchRequestParams): Promise<Branch> => {
  // Build request
  const url = urlForEndpoint(`branches/${requestParams.branchID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    ...requestParams,
    branchID: undefined
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { branch } = await parseResponse(response)
    return new Branch(branch as BranchJSON)
  } catch (err) {
    throw new LoopError(err, requestParams)
  }
}

export interface BranchByIDRequestParams {
  branchID: number
}

export interface BranchByIDErrorResponse {
  error: Error
  requestParams: BranchByIDRequestParams
}

export interface PostBranchRequestParams {
  name: string
  address: string
  phoneNumber: string
  registrationNumber: string
  receiptLogo: string
}

export interface PutBranchRequestParams extends PostBranchRequestParams {
  branchID: number
}

export interface PutBranchErrorResponse {
  error: Error
  requestParams: PutBranchRequestParams
}