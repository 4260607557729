import { Money } from 'ts-money'
import Promo, { ProductIDQuantity, PromoJSON, PromoType } from '../model/Promo'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getPromos = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<PromosResponse> => {

  // Build request
  const url = urlForEndpoint(`promos`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promos: promosJSON, paginationInfo} = await parseResponse(response)

    let promos = promosJSON.map((promoJSON: PromoJSON)  => new Promo(promoJSON))

    return {
      promos,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, {sorting, page, limit, search})
  }
}

export const getPromoByID = async (promoID: number): Promise<Promo> => {

  // Build request
  const url = urlForEndpoint(`promos/${promoID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {promoID})
  }

}

export const putPurchasePromo = async(promoID: number, name: string, description: string, normalPrice: Money, memberPrice: Money, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): Promise<Promo>  => {
  // Build request
  const url = urlForEndpoint(`promos/${promoID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    description,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
    awardValidityDays,
    awardedProductQuantities
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {promoID, name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities})
  }
}

export const putLoyaltyPromo = async(promoID: number, name: string, description: string, timeLimitDays: number, invoicesRequired: number, selectedProductIDs: Array<number>, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): Promise<Promo>  => {
  // Build request
  const url = urlForEndpoint(`promos/${promoID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    description,
    timeLimitDays,
    invoicesRequired,
    selectedProductIDs,
    awardValidityDays,
    awardedProductQuantities
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {promoID, name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities})
  }
}


export const deletePromo = async(promoID: number): Promise<Promo>  => {
  // Build request
  const url = urlForEndpoint(`promos/${promoID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {promoID})
  }
}

export const postPurchasePromo = async(name: string, description: string, normalPrice: Money, memberPrice: Money, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): Promise<Promo>  => {
  // Build request
  const url = urlForEndpoint(`promos`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    promoType: PromoType.Purchase.id,
    name,
    description,
    awardValidityDays,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
    awardedProductQuantities
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities})
  }
}


export const postLoyaltyPromo = async(name: string, description: string, timeLimitDays: number, invoicesRequired: number, selectedProductIDs: Array<number>, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): Promise<Promo>  => {
  // Build request
  const url = urlForEndpoint(`promos`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    promoType: PromoType.Loyalty.id,
    name,
    description,
    timeLimitDays,
    invoicesRequired,
    selectedProductIDs,
    awardValidityDays,
    awardedProductQuantities
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {promo} = await parseResponse(response)
    return new Promo(promo as PromoJSON)
  } catch (err) {
    throw new LoopError(err, {name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities})
  }
}

export interface PromosResponse {
  promos: Promo[]
  paginationInfo: PaginationInfo
  requestParams: PromosRequestParams
}

export interface PromosErrorResponse {
  error: Error
  requestParams: PromosRequestParams
}

export interface PromosRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface PromoByIDRequestParams {
  promoID: number
}

export interface PromoByIDErrorResponse {
  error: Error
  requestParams: PromoByIDRequestParams
}

export interface PostPurchasePromoRequestParams {
  name: string
  description: string
  awardValidityDays: number
  normalPrice: Money
  memberPrice: Money
  awardedProductQuantities: ProductIDQuantity[]
}

export interface PostPurchasePromoErrorResponse {
  error: Error
  requestParams: PostPurchasePromoRequestParams
}

export interface PutPurchasePromoRequestParams extends PostPurchasePromoRequestParams{
  promoID: number
}

export interface PutPurchasePromoErrorResponse {
  error: Error
  requestParams: PutPurchasePromoRequestParams
}

export interface PostLoyaltyPromoRequestParams {
  name: string
  description: string
  timeLimitDays: number
  invoicesRequired: number
  selectedProductIDs: Array<number>
  awardValidityDays: number
  awardedProductQuantities: ProductIDQuantity[]
}

export interface PostLoyaltyPromoErrorResponse {
  error: Error
  requestParams: PostLoyaltyPromoRequestParams
}

export interface PutLoyaltyPromoRequestParams extends PostLoyaltyPromoRequestParams{
  promoID: number
}

export interface PutLoyaltyPromoErrorResponse {
  error: Error
  requestParams: PutLoyaltyPromoRequestParams
}