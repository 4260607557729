import { Money } from 'ts-money'
import {
  PostBundleErrorResponse,
  PostBundleRequestParams,
  BundleByIDErrorResponse,
  BundlesErrorResponse,
  BundlesRequestParams,
  BundlesResponse,
  PutBundleErrorResponse,
  PutBundleRequestParams
} from '../../api/bundles'
import PaginationInfo from '../../model/PaginationInfo'
import Bundle from '../../model/Bundle'
import { ProductIDQuantity } from '../../model/Promo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchBundlesAction {
  type: typeof types.FETCH_BUNDLES
  success: undefined
  payload: BundlesRequestParams
}

export interface ResolveFetchBundlesAction {
  type: typeof types.FETCH_BUNDLES
  success: boolean
  payload: {
    bundles: Bundle[]
    paginationInfo: PaginationInfo
    requestParams: BundlesRequestParams
  }
}

export interface RejectFetchBundlesAction {
  type: typeof types.FETCH_BUNDLES
  success: boolean
  payload: {
    error: Error
    requestParams: BundlesRequestParams
  }
}

export interface FetchBundleByIDAction {
  type: typeof types.FETCH_BUNDLE_BY_ID
  success: undefined
  payload: {
    bundleID: number
  }
}

export interface ResolveFetchBundleByIDAction {
  type: typeof types.FETCH_BUNDLE_BY_ID
  success: boolean
  payload: {
    bundle: Bundle
  }
}

export interface RejectFetchBundleByIDAction {
  type: typeof types.FETCH_BUNDLE_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      bundleID: number
    }
  }
}

export interface UpdateBundleAction {
  type: typeof types.UPDATE_BUNDLE
  success: undefined
  payload: PutBundleRequestParams
}

export interface ResolveUpdateBundleAction {
  type: typeof types.UPDATE_BUNDLE
  success: boolean
  payload: {
    bundle: Bundle
  }
}

export interface RejectUpdateBundleAction {
  type: typeof types.UPDATE_BUNDLE
  success: boolean
  payload: PutBundleErrorResponse
}


export interface DeleteBundleAction {
  type: typeof types.DELETE_BUNDLE
  success: undefined
  payload: {
    bundleID: number
  }
}

export interface ResolveDeleteBundleAction {
  type: typeof types.DELETE_BUNDLE
  success: boolean
  payload: {
    bundle: Bundle
  }
}

export interface RejectDeleteBundleAction {
  type: typeof types.DELETE_BUNDLE
  success: boolean
  payload: BundleByIDErrorResponse
}

export interface CreateBundleAction {
  type: typeof types.CREATE_BUNDLE
  success: undefined
  payload: PostBundleRequestParams
}

export interface ResolveCreateBundleAction {
  type: typeof types.CREATE_BUNDLE
  success: boolean
  payload: {
    bundle: Bundle
  }
}

export interface RejectCreateBundleAction {
  type: typeof types.CREATE_BUNDLE
  success: boolean
  payload: PostBundleErrorResponse
}


export const fetchBundles = (sorting: string, page: number, limit: number, search: string): FetchBundlesAction => ({
  type:    types.FETCH_BUNDLES,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchBundles = ({
                                       bundles,
                                       paginationInfo,
                                       requestParams,
                                     }: BundlesResponse): ResolveFetchBundlesAction => ({
  type:    types.FETCH_BUNDLES,
  success: true,
  payload: {
    bundles,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchBundles = ({ error, requestParams }: BundlesErrorResponse): RejectFetchBundlesAction => ({
  type:    types.FETCH_BUNDLES,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchBundleByID = (bundleID: number): FetchBundleByIDAction => ({
  type:    types.FETCH_BUNDLE_BY_ID,
  success: undefined,
  payload: {
    bundleID,
  },
})

export const resolveFetchBundleByID = (bundle: Bundle): ResolveFetchBundleByIDAction => ({
  type:    types.FETCH_BUNDLE_BY_ID,
  success: true,
  payload: {
    bundle,
  },
})

export const rejectFetchBundleByID = ({
                                         error,
                                         requestParams,
                                       }: BundleByIDErrorResponse): RejectFetchBundleByIDAction => ({
  type:    types.FETCH_BUNDLE_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateBundle = (bundleID: number, name: string, price: Money, productIDQuantities: ProductIDQuantity[]): UpdateBundleAction => ({
  type:    types.UPDATE_BUNDLE,
  success: undefined,
  payload: {
    bundleID,
    name,
    price,
    productIDQuantities,
  },
})

export const resolveUpdateBundle = (bundle: Bundle): ResolveUpdateBundleAction => ({
  type:    types.UPDATE_BUNDLE,
  success: true,
  payload: {
    bundle,
  },
})

export const rejectUpdateBundle = (payload: PutBundleErrorResponse): RejectUpdateBundleAction => ({
  type:    types.UPDATE_BUNDLE,
  success: false,
  payload,
})


export const deleteBundle = (bundleID: number): DeleteBundleAction => ({
  type:    types.DELETE_BUNDLE,
  success: undefined,
  payload: {
    bundleID,
  },
})

export const resolveDeleteBundle = (bundle: Bundle): ResolveDeleteBundleAction => ({
  type:    types.DELETE_BUNDLE,
  success: true,
  payload: {
    bundle,
  },
})

export const rejectDeleteBundle = (payload: BundleByIDErrorResponse): RejectDeleteBundleAction => ({
  type:    types.DELETE_BUNDLE,
  success: false,
  payload,
})


export const createBundle = (name: string, price: Money, productIDQuantities: ProductIDQuantity[]): CreateBundleAction => ({
  type:    types.CREATE_BUNDLE,
  success: undefined,
  payload: {
    name,
    price,
    productIDQuantities,
  },
})

export const resolveCreateBundle = (bundle: Bundle): ResolveCreateBundleAction => ({
  type:    types.CREATE_BUNDLE,
  success: true,
  payload: {
    bundle,
  },
})

export const rejectCreateBundle = (payload: PostBundleErrorResponse): RejectCreateBundleAction => ({
  type:    types.CREATE_BUNDLE,
  success: false,
  payload,
})

export type BundlesActions =
  FetchBundlesAction
  | RejectFetchBundlesAction
  | ResolveFetchBundlesAction
  | FetchBundleByIDAction
  | ResolveFetchBundleByIDAction
  | RejectFetchBundleByIDAction
  | UpdateBundleAction
  | RejectUpdateBundleAction
  | ResolveUpdateBundleAction
  | CreateBundleAction
  | ResolveCreateBundleAction
  | RejectCreateBundleAction
  | DeleteBundleAction
  | ResolveDeleteBundleAction
  | RejectDeleteBundleAction
  | ClearFlashMessagesAction
