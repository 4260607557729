import Product, { ProductJSON } from './Product'

export default class Category {
  id: number
  name: string
  products: Product[] | null
  isSystemGenerated: boolean

  constructor(json: CategoryJSON) {
    this.id = json.id
    this.name = json.name
    this.products = null
    this.isSystemGenerated = json.isSystemGenerated
    if (json.products) {
      this.products = json.products.map(productJSON => new Product(productJSON))
    }
  }

  getCopy() {
    let {id, name, isSystemGenerated} = this
    return new Category({
      id, name, isSystemGenerated,
      products: null
    })
  }

  get [Symbol.toStringTag]() {
    return 'Category'
  }
}

export interface CategoryJSON {
  id: number
  name: string
  products: ProductJSON[] | null
  isSystemGenerated: boolean
}
