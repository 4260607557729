import Customer, { CustomerJSON } from './Customer'

export default class SmsMessage {
  id: number
  customer: Customer
  customerID: number
  recipientNumber: string
  message: string
  startedAt: Date | null
  sentAt: Date | null
  failedAt: Date | null
  errorMessage: string | null
  creditCost: number

  constructor(json: SmsMessageJSON) {
    this.id = json.id
    this.recipientNumber = json.recipientNumber
    this.message = json.message
    this.customer = new Customer(json.customer)
    this.customerID = json.customerID
    this.startedAt = json.startedAt != null ? new Date(json.startedAt) : null
    this.sentAt = json.sentAt != null ? new Date(json.sentAt) : null
    this.failedAt = json.failedAt != null ? new Date(json.failedAt) : null
    this.errorMessage = json.errorMessage
    this.creditCost = json.creditCost
  }

  get [Symbol.toStringTag]() {
    return 'SmsMessage'
  }
}

export interface SmsMessageJSON {
  id: number
  customer: CustomerJSON
  customerID: number
  recipientNumber: string
  message: string
  startedAt: string | null
  sentAt: string | null
  failedAt: string | null
  errorMessage: string | null
  creditCost: number
}
