import { DashboardErrorResponse, DashboardRequestParams, DashboardResponse } from '../../api/dashboard'
import Dashboard from '../../model/Dashboard'
import * as types from '../constants/ActionTypes'

export interface SetDashboardPeriodAction {
  type: typeof types.SET_DASHBOARD_PERIOD
  period: 'week' | 'month' | 'today' | 'last-week' | 'last-month' | 'year-to-date'
}

export interface SetDashboardBranchAction {
  type: typeof types.SET_DASHBOARD_BRANCH_ID
  branchID: number | null
}

export interface FetchDashboardAction {
  type: typeof types.FETCH_DASHBOARD
  success: undefined
  payload: DashboardRequestParams
}

export interface ResolveFetchDashboardAction {
  type: typeof types.FETCH_DASHBOARD
  success: boolean
  payload: {
    dashboard: Dashboard
  }
}

export interface RejectFetchDashboardAction {
  type: typeof types.FETCH_DASHBOARD
  success: boolean
  payload: {
    error: Error
    requestParams: DashboardRequestParams
  }
}

export const fetchDashboard = (period: 'week' | 'month' | 'today' | 'last-week' | 'last-month' | 'year-to-date', branchID?: number): FetchDashboardAction => ({
  type:    types.FETCH_DASHBOARD,
  success: undefined,
  payload: {
    period,
    branchID,
  },
})

export const resolveFetchDashboard = ({ dashboard }: DashboardResponse): ResolveFetchDashboardAction => ({
  type:    types.FETCH_DASHBOARD,
  success: true,
  payload: {
    dashboard,
  },
})

export const rejectFetchDashboard = ({ error, requestParams }: DashboardErrorResponse): RejectFetchDashboardAction => ({
  type:    types.FETCH_DASHBOARD,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const setDashboardPeriod = (period: 'week' | 'month' | 'today' | 'last-week' | 'last-month' | 'year-to-date'): SetDashboardPeriodAction => ({
  type: types.SET_DASHBOARD_PERIOD,
  period,
})

export const setDashboardBranchID = (branchID: number | null): SetDashboardBranchAction => ({
  type: types.SET_DASHBOARD_BRANCH_ID,
  branchID,
})


export type DashboardActions =
  FetchDashboardAction
  | RejectFetchDashboardAction
  | ResolveFetchDashboardAction
  | SetDashboardPeriodAction
  | SetDashboardBranchAction
