import { createRouterMiddleware, onLocationChanged } from '@lagunovsky/redux-react-router'
// @ts-ignore fuck react router
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
// @ts-ignore
import logger from 'redux-logger'
import { install } from 'redux-loop'
import getRootReducer from './reducers'

export var history = createBrowserHistory()

let middlewares = [createRouterMiddleware(history)]


if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const store = createStore(
  // @ts-ignore
  getRootReducer(history),
  compose(
    install(),
    applyMiddleware(
      ...middlewares
    ),
  ),
)

export type RootReducerState = ReturnType<typeof store.getState>

export default store