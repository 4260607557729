import { createSelector } from 'reselect'
import { PaymentAccountsRequestParams } from '../../api/paymentAccounts'
import PaymentAccount from '../../model/PaymentAccount'
import { RootReducerState } from '../'
import { PaymentAccountsReducerPage } from '../reducers/paymentAccounts'

export const hashKeyForPaymentAccountPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: PaymentAccountsRequestParams): string => `${page},${limit},${sorting},${search}`

export const paymentAccountsSelector = (state: RootReducerState): { [id: number]: PaymentAccount } => state.paymentAccounts.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.paymentAccounts.errorByID
const paymentAccountIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.paymentAccounts.isFetchingByID

const paymentAccountPageSelector = (state: RootReducerState, props: any): PaymentAccountsReducerPage => state.paymentAccounts.pages[hashKeyForPaymentAccountPage(props)] || new PaymentAccountsReducerPage()
export const paymentAccountFlashMessageSelector = (state: RootReducerState): string | null => state.paymentAccounts.successFlashMessage

// TODO: fix for react router
export const paymentAccountIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.paymentAccountID || -1
}

export const paymentAccountWithIDFromProps = createSelector(
  [paymentAccountsSelector, paymentAccountIDFromPropsSelector],
  (paymentAccounts, paymentAccountID) => paymentAccounts[paymentAccountID],
)

export const errorForPaymentAccountWithIDFromProps = createSelector(
  [errorsSelector, paymentAccountIDFromPropsSelector],
  (paymentAccounts, paymentAccountID) => paymentAccounts[paymentAccountID],
)

export const isFetchingForPaymentAccountWithIDFromProps = createSelector(
  [paymentAccountIsFetchingByIDSelector, paymentAccountIDFromPropsSelector],
  (paymentAccounts, paymentAccountID) => paymentAccounts[paymentAccountID] || false,
)

export const paymentAccountsByPageSelector = createSelector(
  [paymentAccountsSelector, paymentAccountPageSelector],
  (paymentAccounts, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(paymentAccountID => paymentAccounts[paymentAccountID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [paymentAccountPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [paymentAccountPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [paymentAccountPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [paymentAccountPageSelector],
  (page) => page.error || null,
)

