import { Money, Currencies } from 'ts-money'
import { ProductQuantity, ProductQuantityJSON } from './Promo'

export default class Bundle {
  id: number
  name: string
  price: Money

  generatedProductID: number
  productQuantities: ProductQuantity[]

  constructor(json: BundleJSON) {
    this.id = json.id
    this.name = json.name
    this.price = Money.fromInteger(json.price, Currencies.MYR)

    this.generatedProductID = json.generatedProductID
    this.productQuantities = json.productQuantities.map(j => new ProductQuantity(j))
  }

  get [Symbol.toStringTag]() {
    return 'Product'
  }
}

export interface BundleJSON {
  id: number
  name: string
  price: number

  generatedProductID: number
  productQuantities: ProductQuantityJSON[]
}
