import Customer, { CustomerJSON } from './Customer'
import Invoice, { InvoiceJSON } from './Invoice'

export default class Vehicle {
  id: number
  licensePlate: string
  owners: Customer[] | null
  invoices: Invoice[] | null
  vin: string | null
  make: string | null
  modelName: string | null
  year: number | null
  color: string | null
  createdAt: Date

  constructor(json: VehicleJSON) {
    this.id = json.id
    this.licensePlate = json.licensePlate
    this.owners = null
    if (json.owners) {
      this.owners = json.owners.map(customerJSON => new Customer(customerJSON))
    }
    this.invoices = null
    if (json.invoices) {
      this.invoices = json.invoices.map(invoiceJSON => new Invoice(invoiceJSON))
    }
    this.createdAt = new Date(json.createdAt)
    this.vin = json.vin
    this.make = json.make
    this.modelName = json.modelName
    this.year = json.year
    this.color = json.color
  }

  get [Symbol.toStringTag]() {
    return 'Vehicle'
  }
}

export interface VehicleJSON {
  id: number
  licensePlate: string
  owners: CustomerJSON[] | null
  invoices: InvoiceJSON[] | null
  createdAt: string
  vin: string | null
  make: string | null
  modelName: string | null
  year: number | null
  color: string | null
}

export interface VehicleFilters {
  make: string | null
  model: string | null
  year: number | null
}
