import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import PaymentAccount from '../../model/PaymentAccount'
import {
  CreatePaymentAccountAction,
  DeletePaymentAccountAction,
  FetchPaymentAccountByIDAction,
  FetchPaymentAccountsAction,
  PaymentAccountsActions,
  rejectCreatePaymentAccount,
  RejectCreatePaymentAccountAction,
  rejectDeletePaymentAccount,
  RejectDeletePaymentAccountAction,
  rejectFetchPaymentAccountByID,
  RejectFetchPaymentAccountByIDAction,
  rejectFetchPaymentAccounts,
  RejectFetchPaymentAccountsAction,
  rejectUpdatePaymentAccount,
  RejectUpdatePaymentAccountAction,
  resolveCreatePaymentAccount,
  ResolveCreatePaymentAccountAction,
  resolveDeletePaymentAccount,
  ResolveDeletePaymentAccountAction,
  resolveFetchPaymentAccountByID,
  ResolveFetchPaymentAccountByIDAction,
  resolveFetchPaymentAccounts,
  ResolveFetchPaymentAccountsAction,
  resolveUpdatePaymentAccount,
  ResolveUpdatePaymentAccountAction,
  UpdatePaymentAccountAction,
} from '../actions/paymentAccounts'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForPaymentAccountPage } from '../selectors/paymentAccounts'

interface IDMappedPaymentAccounts {
  [id: number]: PaymentAccount
}

interface PaymentAccountsReducerState {
  byID: IDMappedPaymentAccounts
  pages: { [hash: string]: PaymentAccountsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PaymentAccountsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PaymentAccountsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: PaymentAccountsReducerState = initialState, action: PaymentAccountsActions): PaymentAccountsReducerState | Loop<PaymentAccountsReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_PAYMENT_ACCOUNTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPaymentAccountsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new PaymentAccountsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForPaymentAccountPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getPaymentAccounts, {
              successActionCreator: resolveFetchPaymentAccounts,
              failActionCreator:    rejectFetchPaymentAccounts,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPaymentAccountsAction
          const { paymentAccounts, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForPaymentAccountPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       paymentAccounts.map((paymentAccount: PaymentAccount) => paymentAccount.id),
            paginationInfo: paginationInfo,
          }

          // Map paymentAccount ids to paymentAccounts
          let idMappedPaymentAccounts: IDMappedPaymentAccounts = {}
          paymentAccounts.forEach(paymentAccount => {
            idMappedPaymentAccounts[paymentAccount.id] = paymentAccount
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedPaymentAccounts),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchPaymentAccountsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForPaymentAccountPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PAYMENT_ACCOUNT_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPaymentAccountByIDAction
          const { paymentAccountID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
            },
            Cmd.run(API.getPaymentAccountByID, {
              successActionCreator: resolveFetchPaymentAccountByID,
              failActionCreator:    rejectFetchPaymentAccountByID,
              args:                 [paymentAccountID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPaymentAccountByIDAction
          const { paymentAccount } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [paymentAccount.id]: paymentAccount,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccount.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPaymentAccountByIDAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PAYMENT_ACCOUNT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdatePaymentAccountAction
          const { paymentAccountID, name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putPaymentAccount, {
              successActionCreator: resolveUpdatePaymentAccount,
              failActionCreator:    rejectUpdatePaymentAccount,
              args:                 [paymentAccountID, name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdatePaymentAccountAction
          const { paymentAccount } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [paymentAccount.id]: paymentAccount,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [paymentAccount.id]: false,
              },
              successFlashMessage: 'Partner agent successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdatePaymentAccountAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PAYMENT_ACCOUNT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeletePaymentAccountAction
          const { paymentAccountID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [paymentAccountID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [paymentAccountID]: null,
              },
            },
            Cmd.run(API.deletePaymentAccount, {
              successActionCreator: resolveDeletePaymentAccount,
              failActionCreator:    rejectDeletePaymentAccount,
              args:                 [paymentAccountID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeletePaymentAccountAction
          const { paymentAccount } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[paymentAccount.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [paymentAccount.id]: false,
              },
              successFlashMessage: 'Partner agent successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/partner-agents')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeletePaymentAccountAction
          const { error, requestParams } = payload
          const { paymentAccountID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [paymentAccountID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [paymentAccountID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PAYMENT_ACCOUNT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreatePaymentAccountAction
          const { name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postPaymentAccount, {
              successActionCreator: resolveCreatePaymentAccount,
              failActionCreator:    rejectCreatePaymentAccount,
              args:                 [name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreatePaymentAccountAction
          const { paymentAccount } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [paymentAccount.id]: paymentAccount,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Partner agent successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/partner-agents/${paymentAccount.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreatePaymentAccountAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
