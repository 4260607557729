import {
  PutBranchErrorResponse,
  PutBranchRequestParams,
} from '../../api/branches'
import Branch from '../../model/Branch'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface UpdateBranchAction {
  type: typeof types.UPDATE_BRANCH
  success: undefined
  payload: PutBranchRequestParams
}

export interface ResolveUpdateBranchAction {
  type: typeof types.UPDATE_BRANCH
  success: boolean
  payload: {
    branch: Branch
  }
}

export interface RejectUpdateBranchAction {
  type: typeof types.UPDATE_BRANCH
  success: boolean
  payload: PutBranchErrorResponse
}

export const updateBranch = (requestParams: PutBranchRequestParams): UpdateBranchAction => ({
  type:    types.UPDATE_BRANCH,
  success: undefined,
  payload: requestParams,
})

export const resolveUpdateBranch = (branch: Branch): ResolveUpdateBranchAction => ({
  type:    types.UPDATE_BRANCH,
  success: true,
  payload: {
    branch,
  },
})

export const rejectUpdateBranch = (payload: PutBranchErrorResponse): RejectUpdateBranchAction => ({
  type:    types.UPDATE_BRANCH,
  success: false,
  payload,
})


export type BranchesActions =
  | UpdateBranchAction
  | RejectUpdateBranchAction
  | ResolveUpdateBranchAction
  | ClearFlashMessagesAction
