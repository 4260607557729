import { Money } from 'ts-money'
import {
  InvoiceByIDErrorResponse,
  InvoicesErrorResponse,
  InvoicesRequestParams,
  InvoicesResponse, PaymentResponse, PutInvoicePaymentErrorResponse, PutInvoicePaymentRequestParams,
} from '../../api/invoices'
import { PutProductErrorResponse, PutProductRequestParams } from '../../api/products'
import PaginationInfo from '../../model/PaginationInfo'
import Invoice, { InvoiceFilters } from '../../model/Invoice'
import Product from '../../model/Product'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'
import { RejectUpdateProductAction, ResolveUpdateProductAction, UpdateProductAction } from './products'


export interface FetchInvoicesAction {
  type: typeof types.FETCH_INVOICES
  success: undefined
  payload: InvoicesRequestParams
}

export interface ResolveFetchInvoicesAction {
  type: typeof types.FETCH_INVOICES
  success: boolean
  payload: {
    invoices: Invoice[]
    paginationInfo: PaginationInfo
    requestParams: InvoicesRequestParams
  }
}

export interface RejectFetchInvoicesAction {
  type: typeof types.FETCH_INVOICES
  success: boolean
  payload: {
    error: Error
    requestParams: InvoicesRequestParams
  }
}

export interface FetchInvoiceByIDAction {
  type: typeof types.FETCH_INVOICE_BY_ID
  success: undefined
  payload: {
    invoiceID: number
  }
}

export interface ResolveFetchInvoiceByIDAction {
  type: typeof types.FETCH_INVOICE_BY_ID
  success: boolean
  payload: {
    invoice: Invoice
  }
}

export interface RejectFetchInvoiceByIDAction {
  type: typeof types.FETCH_INVOICE_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      invoiceID: number
    }
  }
}

export interface DeleteInvoiceAction {
  type: typeof types.DELETE_INVOICE
  success: undefined
  payload: {
    invoiceID: number
  }
}

export interface ResolveDeleteInvoiceAction {
  type: typeof types.DELETE_INVOICE
  success: boolean
  payload: {
    invoice: Invoice
  }
}

export interface RejectDeleteInvoiceAction {
  type: typeof types.DELETE_INVOICE
  success: boolean
  payload: InvoiceByIDErrorResponse
}


export interface FullyRefundInvoiceAction {
  type: typeof types.FULLY_REFUND_INVOICE
  success: undefined
  payload: {
    invoiceID: number
    refundReason: string
    paymentAccountID: number
    transactionID: string
  }
}

export interface ResolveFullyRefundInvoiceAction {
  type: typeof types.FULLY_REFUND_INVOICE
  success: boolean
  payload: {
    invoice: Invoice
  }
}

export interface RejectFullyRefundInvoiceAction {
  type: typeof types.FULLY_REFUND_INVOICE
  success: boolean
  payload: InvoiceByIDErrorResponse
}


export interface UpdateInvoicePaymentAction {
  type: typeof types.UPDATE_INVOICE_PAYMENT
  success: undefined
  payload: PutInvoicePaymentRequestParams
}

export interface ResolveUpdateInvoicePaymentAction {
  type: typeof types.UPDATE_INVOICE_PAYMENT
  success: boolean
  payload: PaymentResponse
}

export interface RejectUpdateInvoicePaymentAction {
  type: typeof types.UPDATE_INVOICE_PAYMENT
  success: boolean
  payload: PutInvoicePaymentErrorResponse
}



export const fetchInvoices = (sorting: string, page: number, limit: number, filters: InvoiceFilters, search: string): FetchInvoicesAction => ({
  type:    types.FETCH_INVOICES,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const resolveFetchInvoices = ({
                                       invoices,
                                       paginationInfo,
                                       requestParams,
                                     }: InvoicesResponse): ResolveFetchInvoicesAction => ({
  type:    types.FETCH_INVOICES,
  success: true,
  payload: {
    invoices,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchInvoices = ({ error, requestParams }: InvoicesErrorResponse): RejectFetchInvoicesAction => ({
  type:    types.FETCH_INVOICES,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchInvoiceByID = (invoiceID: number): FetchInvoiceByIDAction => ({
  type:    types.FETCH_INVOICE_BY_ID,
  success: undefined,
  payload: {
    invoiceID,
  },
})

export const resolveFetchInvoiceByID = (invoice: Invoice): ResolveFetchInvoiceByIDAction => ({
  type:    types.FETCH_INVOICE_BY_ID,
  success: true,
  payload: {
    invoice,
  },
})

export const rejectFetchInvoiceByID = ({
                                         error,
                                         requestParams,
                                       }: InvoiceByIDErrorResponse): RejectFetchInvoiceByIDAction => ({
  type:    types.FETCH_INVOICE_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const deleteInvoice = (invoiceID: number): DeleteInvoiceAction => ({
  type:    types.DELETE_INVOICE,
  success: undefined,
  payload: {
    invoiceID,
  },
})

export const resolveDeleteInvoice = (invoice: Invoice): ResolveDeleteInvoiceAction => ({
  type:    types.DELETE_INVOICE,
  success: true,
  payload: {
    invoice,
  },
})

export const rejectDeleteInvoice = (payload: InvoiceByIDErrorResponse): RejectDeleteInvoiceAction => ({
  type:    types.DELETE_INVOICE,
  success: false,
  payload,
})


export const fullyRefundInvoice = (invoiceID: number, refundReason: string, paymentAccountID: number, transactionID: string): FullyRefundInvoiceAction => ({
  type:    types.FULLY_REFUND_INVOICE,
  success: undefined,
  payload: {
    invoiceID,
    refundReason,
    paymentAccountID,
    transactionID,
  },
})

export const resolveFullyRefundInvoice = (invoice: Invoice): ResolveFullyRefundInvoiceAction => ({
  type:    types.FULLY_REFUND_INVOICE,
  success: true,
  payload: {
    invoice,
  },
})

export const rejectFullyRefundInvoice = (payload: InvoiceByIDErrorResponse): RejectFullyRefundInvoiceAction => ({
  type:    types.FULLY_REFUND_INVOICE,
  success: false,
  payload,
})

export const updateInvoicePayment = (payload: PutInvoicePaymentRequestParams): UpdateInvoicePaymentAction => ({
  type:    types.UPDATE_INVOICE_PAYMENT,
  success: undefined,
  payload,
})

export const resolveUpdateInvoicePayment = (response: PaymentResponse): ResolveUpdateInvoicePaymentAction => ({
  type:    types.UPDATE_INVOICE_PAYMENT,
  success: true,
  payload: response,
})

export const rejectUpdateInvoicePayment = (payload: PutInvoicePaymentErrorResponse): RejectUpdateInvoicePaymentAction => ({
  type:    types.UPDATE_INVOICE_PAYMENT,
  success: false,
  payload,
})

export type InvoicesActions =
  FetchInvoicesAction
  | RejectFetchInvoicesAction
  | ResolveFetchInvoicesAction
  | FetchInvoiceByIDAction
  | ResolveFetchInvoiceByIDAction
  | RejectFetchInvoiceByIDAction
  | ClearFlashMessagesAction
  | DeleteInvoiceAction
  | ResolveDeleteInvoiceAction
  | RejectDeleteInvoiceAction
  | FullyRefundInvoiceAction
  | ResolveFullyRefundInvoiceAction
  | RejectFullyRefundInvoiceAction
  | UpdateInvoicePaymentAction
  | RejectUpdateInvoicePaymentAction
  | ResolveUpdateInvoicePaymentAction
