import Category, { CategoryJSON } from '../model/Category'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getCategories = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<CategoriesResponse> => {

  // Build request
  const url = urlForEndpoint(`categories`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {categories: categoriesJSON, paginationInfo} = await parseResponse(response)

    let categories = categoriesJSON.map((categoryJSON: CategoryJSON)  => new Category(categoryJSON))

    return {
      categories,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, {sorting, page, limit, search})
  }
}

export const getCategoryByID = async (categoryID: number): Promise<Category> => {

  // Build request
  const url = urlForEndpoint(`categories/${categoryID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {category} = await parseResponse(response)
    return new Category(category as CategoryJSON)
  } catch (err) {
    throw new LoopError(err, {categoryID})
  }

}

export const putCategory = async(categoryID: number, name: string): Promise<Category>  => {
  // Build request
  const url = urlForEndpoint(`categories/${categoryID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {category} = await parseResponse(response)
    return new Category(category as CategoryJSON)
  } catch (err) {
    throw new LoopError(err, {categoryID, name})
  }
}

export const deleteCategory = async(categoryID: number): Promise<Category>  => {
  // Build request
  const url = urlForEndpoint(`categories/${categoryID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {category} = await parseResponse(response)
    return new Category(category as CategoryJSON)
  } catch (err) {
    throw new LoopError(err, {categoryID})
  }
}

export const postCategory = async(name: string): Promise<Category>  => {
  // Build request
  const url = urlForEndpoint(`categories`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {category} = await parseResponse(response)
    return new Category(category as CategoryJSON)
  } catch (err) {
    throw new LoopError(err, {name})
  }
}

export interface CategoriesResponse {
  categories: Category[]
  paginationInfo: PaginationInfo
  requestParams: CategoriesRequestParams
}

export interface CategoriesErrorResponse {
  error: Error
  requestParams: CategoriesRequestParams
}

export interface CategoriesRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface CategoryByIDRequestParams {
  categoryID: number
}

export interface CategoryByIDErrorResponse {
  error: Error
  requestParams: CategoryByIDRequestParams
}

export interface PostCategoryRequestParams {
  name: string
}

export interface PostCategoryErrorResponse {
  error: Error
  requestParams: PostCategoryRequestParams
}

export interface PutCategoryRequestParams extends PostCategoryRequestParams{
  categoryID: number
}

export interface PutCategoryErrorResponse {
  error: Error
  requestParams: PutCategoryRequestParams
}