import PartnerAgent, {PartnerAgentJSON } from '../model/PartnerAgent'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getPartnerAgents = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<PartnerAgentsResponse> => {
  // Build request
  const url = urlForEndpoint(`partner-agents`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { partnerAgents: partnerAgentsJSON, paginationInfo } = await parseResponse(response)

    let partnerAgents = partnerAgentsJSON.map((partnerAgentJSON: PartnerAgentJSON) => new PartnerAgent(partnerAgentJSON))

    return {
      partnerAgents,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getPartnerAgentByID = async (partnerAgentID: number): Promise<PartnerAgent> => {

  // Build request
  const url = urlForEndpoint(`partner-agents/${partnerAgentID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { partnerAgent } = await parseResponse(response)
    return new PartnerAgent(partnerAgent as PartnerAgentJSON)
  } catch (err) {
    throw new LoopError(err, { partnerAgentID })
  }

}

export const putPartnerAgent = async (partnerAgentID: number, name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): Promise<PartnerAgent> => {
  // Build request
  const url = urlForEndpoint(`partner-agents/${partnerAgentID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    company,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { partnerAgent } = await parseResponse(response)
    return new PartnerAgent(partnerAgent as PartnerAgentJSON)
  } catch (err) {
    throw new LoopError(err, { partnerAgentID, name, phoneNumber, emailAddress })
  }
}

export const deletePartnerAgent = async (partnerAgentID: number): Promise<PartnerAgent> => {
  // Build request
  const url = urlForEndpoint(`partner-agents/${partnerAgentID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { partnerAgent } = await parseResponse(response)
    return new PartnerAgent(partnerAgent as PartnerAgentJSON)
  } catch (err) {
    throw new LoopError(err, { partnerAgentID })
  }
}

export const postPartnerAgent = async (name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): Promise<PartnerAgent> => {
  // Build request
  const url = urlForEndpoint(`partner-agents`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    company,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { partnerAgent } = await parseResponse(response)
    return new PartnerAgent(partnerAgent as PartnerAgentJSON)
  } catch (err) {
    throw new LoopError(err, { name, phoneNumber, emailAddress })
  }
}

export interface PartnerAgentIDsResponse {
  partnerAgentIDs: number[]
  requestParams: PartnerAgentsRequestParams
}


export interface PartnerAgentsResponse {
  partnerAgents: PartnerAgent[]
  paginationInfo: PaginationInfo
  requestParams: PartnerAgentsRequestParams
}

export interface PartnerAgentsErrorResponse {
  error: Error
  requestParams: PartnerAgentsRequestParams
}

export interface PartnerAgentsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface PartnerAgentByIDRequestParams {
  partnerAgentID: number
}

export interface PartnerAgentByIDErrorResponse {
  error: Error
  requestParams: PartnerAgentByIDRequestParams
}

export interface PostPartnerAgentRequestParams {
  name: string
  company: string | null
  phoneNumber: string | null
  emailAddress: string | null
}

export interface PostPartnerAgentErrorResponse {
  error: Error
  requestParams: PostPartnerAgentRequestParams
}

export interface PutPartnerAgentRequestParams extends PostPartnerAgentRequestParams {
  partnerAgentID: number
}

export interface PutPartnerAgentErrorResponse {
  error: Error
  requestParams: PutPartnerAgentRequestParams
}