import { createRouterReducer } from '@lagunovsky/redux-react-router'
import { combineReducers } from 'redux-loop'
import { HistoryType } from '../history'
import authentication from './authentication'
import bundles from './bundles'
import categories from './categories'
import dashboard from './dashboard'
import invoices from './invoices'
import partnerAgents from './partnerAgents'
import paymentAccounts from './paymentAccounts'
import payments from './payments'
import productCreditTransactions from './productCreditTransactions'
import products from './products'
import customers from './customers'
import vehicles from './vehicles'
import profile from './profile'
import promos from './promos'
import ui from './ui'
import smsBlasts from './smsBlasts'
import employees from './employees'



const rootReducer = (history: HistoryType) => {
  return combineReducers({
    // @ts-ignore
    router: createRouterReducer(history),
    // @ts-ignore
    dashboard,
    // @ts-ignore
    categories,
    // @ts-ignore
    products,
    // @ts-ignore
    promos,
    // @ts-ignore
    invoices,
    // @ts-ignore
    authentication,
    // @ts-ignore
    customers,
    // @ts-ignore
    vehicles,
    // @ts-ignore
    profile,
    // @ts-ignore
    ui,
    // @ts-ignore
    smsBlasts,
    // @ts-ignore
    employees,
    // @ts-ignore
    partnerAgents,
    // @ts-ignore
    bundles,
    // @ts-ignore
    paymentAccounts,
    // @ts-ignore
    productCreditTransactions,
    // @ts-ignore
    payments,
  })
}

export default rootReducer
export type IRootState = ReturnType<typeof rootReducer>