import { createSelector } from 'reselect'
import { BundlesRequestParams } from '../../api/bundles'
import Bundle from '../../model/Bundle'
import { RootReducerState } from '../'
import { BundlesReducerPage } from '../reducers/bundles'

export const hashKeyForBundlePage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search,
                                   }: BundlesRequestParams): string => `${page},${limit},${sorting}`

const bundlesSelector = (state: RootReducerState): { [id: number]: Bundle } => state.bundles.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.bundles.errorByID
const bundleIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.bundles.isFetchingByID
const bundlePageSelector = (state: RootReducerState, props: any): BundlesReducerPage => state.bundles.pages[hashKeyForBundlePage(props)] || new BundlesReducerPage()
export const bundleFlashMessageSelector = (state: RootReducerState): string | null => state.bundles.successFlashMessage

// TODO: fix for react router
export const bundleIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.bundleID || -1
}

export const bundleWithIDFromProps = createSelector(
  [bundlesSelector, bundleIDFromPropsSelector],
  (bundles, bundleID) => bundles[bundleID] ,
)

export const errorForBundleWithIDFromProps = createSelector(
  [errorsSelector, bundleIDFromPropsSelector],
  (bundles, bundleID) => bundles[bundleID],
)

export const isFetchingForBundleWithIDFromProps = createSelector(
  [bundleIsFetchingByIDSelector, bundleIDFromPropsSelector],
  (bundles, bundleID) => bundles[bundleID] || false,
)

export const bundlesByPageSelector = createSelector(
  [bundlesSelector, bundlePageSelector],
  (bundles, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(bundleID => bundles[bundleID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [bundlePageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [bundlePageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [bundlePageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [bundlePageSelector],
  (page) => page.error || null,
)

