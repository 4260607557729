import { Currencies, Money } from 'ts-money'
import Product, { ProductJSON } from './Product'
import ProductVariant, { ProductVariantJSON } from './ProductVariant'

export default class Promo {
  id: number
  name: string
  description: string
  promoType: string

  awardValidityDays: number
  awardedProductQuantities: ProductQuantity[]

  loyaltyRequirement?: LoyaltyRequirement
  purchaseRequirement?: PurchaseRequirement

  constructor(json: PromoJSON) {
    this.id = json.id
    this.name = json.name
    this.description = json.description
    this.promoType = json.promoType
    this.awardValidityDays = json.awardValidityDays
    this.awardedProductQuantities = json.awardedProductQuantities.map((json: ProductQuantityJSON) => new ProductQuantity(json))

    if (json.loyaltyRequirement) {
      this.loyaltyRequirement = new LoyaltyRequirement(json.loyaltyRequirement)
    }
    if (json.purchaseRequirement) {
      this.purchaseRequirement = new PurchaseRequirement(json.purchaseRequirement)
    }
  }

  get [Symbol.toStringTag]() {
    return 'Promo'
  }
}

export interface PromoJSON {
  id: number
  name: string
  description: string
  promoType: string

  awardValidityDays: number
  awardedProductQuantities: ProductQuantityJSON[]

  purchaseRequirement: PurchaseRequirementJSON
  loyaltyRequirement: LoyaltyRequirementJSON
}

export class ProductQuantity {
  product: Product
  variant: ProductVariant | null
  quantity: number
  isVehicleRestricted?: boolean

  constructor(json: ProductQuantityJSON) {
    this.quantity = json.quantity

    if (typeof json.product == typeof Product) {
      this.product = json.product as Product
    } else {
      this.product = new Product(json.product as ProductJSON)
    }

    if (typeof json.variant == typeof ProductVariant) {
      this.variant = json.variant as ProductVariant
    } else if (json.variant != null) {
      this.variant = new ProductVariant(json.variant as ProductVariantJSON)
    } else {
      this.variant = null
    }

    this.isVehicleRestricted = json.isVehicleRestricted
  }

  get [Symbol.toStringTag]() {
    return 'ProductQuantity'
  }
}

export interface ProductQuantityJSON {
  quantity: number
  product: ProductJSON | Product
  variant: ProductVariantJSON | ProductVariant | null
  isVehicleRestricted?: boolean
}

export class PurchaseRequirement {
  normalPrice: Money
  memberPrice: Money
  productID: number

  constructor(json: PurchaseRequirementJSON) {
    this.normalPrice = Money.fromInteger(json.normalPrice, Currencies.MYR)
    this.memberPrice = Money.fromInteger(json.memberPrice, Currencies.MYR)
    this.productID = json.productID
  }

  get [Symbol.toStringTag]() {
    return 'PurchaseRequirement'
  }
}

export interface PurchaseRequirementJSON {
  normalPrice: number
  memberPrice: number
  productID: number
}


export class LoyaltyRequirement {
  validProducts: number[]
  timeLimitDays: number
  invoicesRequired: number

  constructor(json: LoyaltyRequirementJSON) {
    this.timeLimitDays = json.timeLimitDays
    this.invoicesRequired = json.invoicesRequired
    this.validProducts = json.validProducts
  }

  get [Symbol.toStringTag]() {
    return 'QualifyingProductQuantity'
  }
}

export interface LoyaltyRequirementJSON {
  validProducts: number[]
  timeLimitDays: number
  invoicesRequired: number
}


export class ProductIDQuantity {
  productID: number
  variantID: number | null
  quantity: number
  isVehicleRestricted?: boolean

  constructor(productID: number, variantID: number | null, quantity: number, isVehicleRestricted?: boolean) {
    this.quantity = quantity
    this.variantID = variantID
    this.productID = productID
    this.isVehicleRestricted = isVehicleRestricted
  }
}

export class PromoType {
  static Invalid = new PromoType('invalid', 'Select a promo type:', '')
  static Purchase = new PromoType('purchase', 'Pre-purchase', 'The customer pays in advance for a bundle of products which they can claim at a later date')
  static Loyalty = new PromoType('loyalty', 'Loyalty', 'The customer is awarded products if they purchase all the specified products within the specified time period')

  id: string
  name: string
  description: string

  constructor(id: string, name: string, description: string) {
    this.id = id
    this.name = name
    this.description = description
  }

  static byID = (promoTypeID: string): PromoType => {
    switch (promoTypeID) {
      case PromoType.Purchase.id:
        return PromoType.Purchase

      case PromoType.Loyalty.id:
        return PromoType.Loyalty

      default:
        return PromoType.Invalid
    }
  }

  static All = [PromoType.Invalid, PromoType.Purchase, PromoType.Loyalty]
}
