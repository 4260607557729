import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Customer, { CustomerStats } from '../../model/Customer'
import ProductCreditBalance from '../../model/ProductCreditBalance'
import {
  CreateCustomerAction,
  DeleteCustomerAction,
  FetchCustomerByIDAction,
  FetchCustomersAction,
  CustomersActions,
  rejectCreateCustomer,
  RejectCreateCustomerAction,
  rejectDeleteCustomer,
  RejectDeleteCustomerAction,
  rejectFetchCustomerByID,
  RejectFetchCustomerByIDAction,
  rejectFetchCustomers,
  RejectFetchCustomersAction,
  rejectUpdateCustomer,
  RejectUpdateCustomerAction,
  resolveCreateCustomer,
  ResolveCreateCustomerAction,
  resolveDeleteCustomer,
  ResolveDeleteCustomerAction,
  resolveFetchCustomerByID,
  ResolveFetchCustomerByIDAction,
  resolveFetchCustomers,
  ResolveFetchCustomersAction,
  resolveUpdateCustomer,
  ResolveUpdateCustomerAction,
  UpdateCustomerAction,
  SelectAllCustomersAction,
  resolveSelectAllCustomers,
  rejectSelectAllCustomers,
  ResolveSelectAllCustomersAction,
  RejectSelectAllCustomersAction,
  SelectAllCustomersOnPageAction,
  FetchCustomerStatsByIDAction,
  resolveFetchCustomerStatsByID,
  rejectFetchCustomerStatsByID,
  ResolveFetchCustomerStatsByIDAction, RejectFetchCustomerStatsByIDAction,
} from '../actions/customers'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForCustomerPage } from '../selectors/customers'

interface IDMappedCustomers {
  [id: number]: Customer
}

interface CustomersReducerState {
  byID: IDMappedCustomers
  statsByID: Map<number, CustomerStats>
  creditBalancesByID: Map<number, Array<ProductCreditBalance>>
  isFetchingStatsByID: Map<number, boolean>
  pages: { [hash: string]: CustomersReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
  isSelectedByID: Map<number, boolean>
  isSelecting: boolean
  selectAllState: null | 'page' | 'all'
}

export class CustomersReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: CustomersReducerState = {
  byID:                {},
  statsByID:           new Map<number, CustomerStats>(),
  creditBalancesByID:  new Map<number, Array<ProductCreditBalance>>(),
  isFetchingStatsByID: new Map<number, boolean>(),
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
  isSelectedByID:      new Map<number, boolean>(),
  isSelecting:         false,
  selectAllState:      null,
}

export default (state: CustomersReducerState = initialState, action: CustomersActions): CustomersReducerState | Loop<CustomersReducerState> => {
  switch (action.type) {

    case ROUTER_ON_LOCATION_CHANGED: {
      let { payload } = action as LocationChangeAction
      let { location } = payload
      let { selectAllState } = state
      if (location.pathname != '/customers' && state.isSelectedByID.size > 0) {
        return {
          ...state,
          selectAllState: null,
          isSelectedByID: new Map<number, boolean>(),
        }
      } else {
        return state
      }
    }

    case actionTypes.TOGGLE_SELECTION_BY_ID: {
      let isSelectedByID = new Map(state.isSelectedByID)
      let { payload } = action

      isSelectedByID.set(payload, !(isSelectedByID.get(payload) || false))
      return {
        ...state,
        isSelectedByID,
        selectAllState: null,
      }
    }

    case actionTypes.DESELECT_ALL: {
      return {
        ...state,
        selectAllState: null,
        isSelectedByID: new Map<number, boolean>(),
      }
    }

    case actionTypes.SELECT_ALL_ON_PAGE: {
      const { selectAllState } = state
      let { payload } = action as SelectAllCustomersOnPageAction

      const pageKey = hashKeyForCustomerPage(payload)
      let page = state.pages[pageKey]

      // New selection map
      let isSelectedByID = new Map<number, boolean>()

      page.childIDs.forEach(customerID => {
        isSelectedByID.set(customerID, true)
      })

      return {
        ...state,
        isSelectedByID,
        selectAllState: 'page',
      }
    }

    case actionTypes.SELECT_ALL: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          // Just select all on page at first
          const { selectAllState } = state
          let { payload } = action as SelectAllCustomersAction
          if (selectAllState == null) {
            const pageKey = hashKeyForCustomerPage(payload)
            let page = state.pages[pageKey]

            // New selection map
            let isSelectedByID = new Map(state.isSelectedByID)

            page.childIDs.forEach(customerID => {
              isSelectedByID.set(customerID, true)
            })

            return {
              ...state,
              isSelectedByID,
            }
          } else if (selectAllState == 'page') {
            const { sorting, page, limit, filters, search } = payload

            // Set state and fetch
            return loop(
              Object.assign({}, state, {
                isSelecting: true,
              }),
              Cmd.run(API.getCustomerIDs, {
                successActionCreator: resolveSelectAllCustomers,
                failActionCreator:    rejectSelectAllCustomers,
                args:                 [sorting, page, limit, filters, search],
              }),
            )

          } else {
            return state
          }

        }

        case true: {
          let { payload } = action as ResolveSelectAllCustomersAction
          const { customerIDs, requestParams } = payload

          // New selection map
          let isSelectedByID = new Map(state.isSelectedByID)

          customerIDs.forEach(customerID => {
            isSelectedByID.set(customerID, true)
          })

          // Place in correct page
          return {
            ...state,
            isSelectedByID,
            selectAllState: 'all',
            isSelecting:    false,
          }
        }

        case false: {
          const { payload } = action as RejectSelectAllCustomersAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForCustomerPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages:       Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
            isSelecting: false,
          })
        }
      }

      break
    }

    case actionTypes.FETCH_CUSTOMERS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchCustomersAction
          const { sorting, page, limit, filters, search } = payload

          // Build page object
          const pageObject = new CustomersReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForCustomerPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getCustomers, {
              successActionCreator: resolveFetchCustomers,
              failActionCreator:    rejectFetchCustomers,
              args:                 [sorting, page, limit, filters, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchCustomersAction
          const { customers, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForCustomerPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       customers.map((customer: Customer) => customer.id),
            paginationInfo: paginationInfo,
          }

          // Map customer ids to customers
          let idMappedCustomers: IDMappedCustomers = {}
          customers.forEach(customer => {
            idMappedCustomers[customer.id] = customer
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedCustomers),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchCustomersAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForCustomerPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_CUSTOMER_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchCustomerByIDAction
          const { customerID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [customerID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [customerID]: null,
              },
            },
            Cmd.run(API.getCustomerByID, {
              successActionCreator: resolveFetchCustomerByID,
              failActionCreator:    rejectFetchCustomerByID,
              args:                 [customerID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchCustomerByIDAction
          const { customer } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [customer.id]: customer,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [customer.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchCustomerByIDAction
          const { error, requestParams } = payload
          const { customerID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [customerID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [customerID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.FETCH_CUSTOMER_STATS_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchCustomerStatsByIDAction
          const { customerID } = payload

          let isFetchingStatsByID = new Map(state.isFetchingStatsByID)
          isFetchingStatsByID.set(+customerID, true)

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingStatsByID,
              errorByID: {
                ...state.errorByID,
                [customerID]: null,
              },
            },
            Cmd.run(API.getCustomerStatsByID, {
              successActionCreator: resolveFetchCustomerStatsByID,
              failActionCreator:    rejectFetchCustomerStatsByID,
              args:                 [customerID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchCustomerStatsByIDAction
          const { stats, creditBalances } = payload

          let isFetchingStatsByID = new Map(state.isFetchingStatsByID)
          isFetchingStatsByID.set(stats.customerID, false)

          let statsByID = new Map(state.statsByID)
          statsByID.set(stats.customerID, stats)

          let creditBalancesByID = new Map(state.creditBalancesByID)
          creditBalancesByID.set(stats.customerID, creditBalances)

          return {
            ...state,
            creditBalancesByID,
            isFetchingStatsByID,
            statsByID,
          }
        }

        case false: {
          const { payload } = action as RejectFetchCustomerStatsByIDAction
          const { error, requestParams } = payload
          const { customerID } = requestParams

          let isFetchingStatsByID = new Map(state.isFetchingStatsByID)
          isFetchingStatsByID.set(customerID, false)

          // Place in correct page
          return {
            ...state,
            isFetchingStatsByID,
            errorByID: {
              ...state.errorByID,
              [customerID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_CUSTOMER: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateCustomerAction
          const { customerID, name, phoneNumber, emailAddress, address, dateOfBirth, gender } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [customerID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [customerID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putCustomer, {
              successActionCreator: resolveUpdateCustomer,
              failActionCreator:    rejectUpdateCustomer,
              args:                 [customerID, name, phoneNumber, emailAddress, address, gender, dateOfBirth],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateCustomerAction
          const { customer } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [customer.id]: customer,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [customer.id]: false,
              },
              successFlashMessage: 'Customer successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateCustomerAction
          const { error, requestParams } = payload
          const { customerID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [customerID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [customerID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_CUSTOMER: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteCustomerAction
          const { customerID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [customerID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [customerID]: null,
              },
            },
            Cmd.run(API.deleteCustomer, {
              successActionCreator: resolveDeleteCustomer,
              failActionCreator:    rejectDeleteCustomer,
              args:                 [customerID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteCustomerAction
          const { customer } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[customer.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [customer.id]: false,
              },
              successFlashMessage: 'Customer successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/customers')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteCustomerAction
          const { error, requestParams } = payload
          const { customerID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [customerID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [customerID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_CUSTOMER: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateCustomerAction
          const { name, phoneNumber, emailAddress, address, dateOfBirth, gender } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postCustomer, {
              successActionCreator: resolveCreateCustomer,
              failActionCreator:    rejectCreateCustomer,
              args:                 [name, phoneNumber, emailAddress, address, dateOfBirth, gender],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateCustomerAction
          const { customer } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [customer.id]: customer,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Customer successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/customers/${customer.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateCustomerAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
