import { Money } from 'ts-money'
import { ProductsRequestParams, PutProductErrorResponse } from '../../api/products'
import {
  ChangePasswordErrorResponse,
  ChangePasswordRequestParams,
  ProfileErrorResponse,
  ProfileResponse,
  UpdateProfileErrorResponse,
  UpdateProfileRequestParams,
} from '../../api/profile'
import Manager from '../../model/Manager'
import Product from '../../model/Product'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'
import { RejectUpdateProductAction, ResolveUpdateProductAction, UpdateProductAction } from './products'

export interface FetchProfileAction {
  type: typeof types.FETCH_PROFILE
  success: undefined
}

export interface ResolveFetchProfileAction {
  type: typeof types.FETCH_PROFILE
  success: boolean
  payload: {
    profileResponse: ProfileResponse
  }
}

export interface RejectFetchProfileAction {
  type: typeof types.FETCH_PROFILE
  success: boolean
  payload: {
    error: Error
  }
}

export interface UpdateProfileAction {
  type: typeof types.UPDATE_PROFILE
  payload: UpdateProfileRequestParams
  success: undefined
}

export interface ResolveUpdateProfileAction {
  type: typeof types.UPDATE_PROFILE
  success: boolean
  payload: {
    manager: Manager
  }
}

export interface RejectUpdateProfileAction {
  type: typeof types.UPDATE_PROFILE
  success: boolean
  payload: {
    error: Error
    requestParams: UpdateProfileRequestParams
  }
}

export interface ChangePasswordAction {
  type: typeof types.CHANGE_PASSWORD
  payload: ChangePasswordRequestParams
  success: undefined
}

export interface ResolveChangePasswordAction {
  type: typeof types.CHANGE_PASSWORD
  success: boolean
  payload: {
    manager: Manager
  }
}

export interface RejectChangePasswordAction {
  type: typeof types.CHANGE_PASSWORD
  success: boolean
  payload: {
    error: Error
    requestParams: ChangePasswordRequestParams
  }
}


export const fetchProfile = (): FetchProfileAction => ({
  type:    types.FETCH_PROFILE,
  success: undefined,
})

export const resolveFetchProfile = (profileResponse: ProfileResponse): ResolveFetchProfileAction => ({
  type:    types.FETCH_PROFILE,
  success: true,
  payload: {
    profileResponse
  },
})

export const rejectFetchProfile = ({ error }: ProfileErrorResponse): RejectFetchProfileAction => ({
  type:    types.FETCH_PROFILE,
  success: false,
  payload: {
    error,
  },
})

export const updateProfile = (payload: UpdateProfileRequestParams): UpdateProfileAction => ({
  type:    types.UPDATE_PROFILE,
  success: undefined,
  payload
})

export const resolveUpdateProfile = (manager: Manager): ResolveUpdateProfileAction => ({
  type:    types.UPDATE_PROFILE,
  success: true,
  payload: {
    manager,
  },
})

export const rejectUpdateProfile = (payload: UpdateProfileErrorResponse): RejectUpdateProfileAction => ({
  type:    types.UPDATE_PROFILE,
  success: false,
  payload,
})


export const changePassword = (payload: ChangePasswordRequestParams): ChangePasswordAction => ({
  type:    types.CHANGE_PASSWORD,
  success: undefined,
  payload
})

export const resolveChangePassword = (manager: Manager): ResolveChangePasswordAction => ({
  type:    types.CHANGE_PASSWORD,
  success: true,
  payload: {
    manager,
  },
})

export const rejectChangePassword = (payload: ChangePasswordErrorResponse): RejectChangePasswordAction => ({
  type:    types.CHANGE_PASSWORD,
  success: false,
  payload,
})


export type ProfileActions =
  FetchProfileAction
  | RejectFetchProfileAction
  | ResolveFetchProfileAction
  | UpdateProfileAction
  | ResolveUpdateProfileAction
  | RejectUpdateProfileAction
  | ChangePasswordAction
  | ResolveChangePasswordAction
  | RejectChangePasswordAction
  | ClearFlashMessagesAction
