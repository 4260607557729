import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, token, urlForEndpoint } from './helpers'

export const getSalesDataCSV = async (startDate: string, endDate: string): Promise<string> => {
  // Build request
  const url = urlForEndpoint(`sales-data-csv`, {
    startDate,
    endDate,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)
    return response.text()
  } catch (err) {
    throw new LoopError(err, { startDate, endDate })
  }
}

export const getCustomerVehicleDataCSV = async (): Promise<string> => {
  // Build request
  const url = urlForEndpoint(`customer-vehicle-data-csv`, {})
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)
    return response.text()
  } catch (err) {
    throw new LoopError(err, {})
  }
}

