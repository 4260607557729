import { Money } from 'ts-money'
import SmsBlast, { SmsBlastJSON } from '../model/SmsBlast'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getSmsBlasts = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<SmsBlastsResponse> => {

  // Build request
  const url = urlForEndpoint(`sms-blasts`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { smsBlasts: smsBlastsJSON, paginationInfo } = await parseResponse(response)

    let smsBlasts = smsBlastsJSON.map((smsBlastJSON: SmsBlastJSON) => new SmsBlast(smsBlastJSON))

    return {
      smsBlasts,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getSmsBlastByID = async (smsBlastID: number): Promise<SmsBlast> => {

  // Build request
  const url = urlForEndpoint(`sms-blasts/${smsBlastID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { smsBlast } = await parseResponse(response)
    return new SmsBlast(smsBlast as SmsBlastJSON)
  } catch (err) {
    throw new LoopError(err, { smsBlastID })
  }

}

export const putSmsBlast = async (smsBlastID: number, filters: string, title: string, body: string, recipientIDs: number[]): Promise<SmsBlast> => {
  // Build request
  const url = urlForEndpoint(`sms-blasts/${smsBlastID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    filters,
    title,
    body,
    recipientIDs,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { smsBlast } = await parseResponse(response)
    return new SmsBlast(smsBlast as SmsBlastJSON)
  } catch (err) {
    throw new LoopError(err, {
      smsBlastID,
      filters,
      title,
      body,
      recipientIDs,
    })
  }
}

export const deleteSmsBlast = async (smsBlastID: number): Promise<SmsBlast> => {
  // Build request
  const url = urlForEndpoint(`sms-blasts/${smsBlastID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { smsBlast } = await parseResponse(response)
    return new SmsBlast(smsBlast as SmsBlastJSON)
  } catch (err) {
    throw new LoopError(err, { smsBlastID })
  }
}

export const postSmsBlast = async (filters: string, title: string, body: string, recipientIDs: number[]): Promise<SmsBlast> => {
  // Build request
  const url = urlForEndpoint(`sms-blasts`)

  const request = newRequest(HTTPMethods.POST, token())

  request.body = JSON.stringify({
    filters,
    title,
    body,
    recipientIDs,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { smsBlast } = await parseResponse(response)
    return new SmsBlast(smsBlast as SmsBlastJSON)
  } catch (err) {
    throw new LoopError(err, { filters, title, body, recipientIDs })
  }
}


export const postSmsBlastSend = async (smsBlastID: number): Promise<SmsBlast> => {
  // Build request
  const url = urlForEndpoint(`sms-blasts/${smsBlastID}/send`)

  const request = newRequest(HTTPMethods.POST, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { smsBlast } = await parseResponse(response)
    return new SmsBlast(smsBlast as SmsBlastJSON)
  } catch (err) {
    throw new LoopError(err, { smsBlastID })
  }
}

export interface SmsBlastsResponse {
  smsBlasts: SmsBlast[]
  paginationInfo: PaginationInfo
  requestParams: SmsBlastsRequestParams
}

export interface SmsBlastsErrorResponse {
  error: Error
  requestParams: SmsBlastsRequestParams
}

export interface SmsBlastsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface SmsBlastByIDRequestParams {
  smsBlastID: number
}

export interface SmsBlastByIDErrorResponse {
  error: Error
  requestParams: SmsBlastByIDRequestParams
}

export interface PostSmsBlastRequestParams {
  filters: string
  title: string
  body: string
  recipientIDs: number[]
}

export interface PostSmsBlastErrorResponse {
  error: Error
  requestParams: PostSmsBlastRequestParams
}

export interface PutSmsBlastRequestParams extends PostSmsBlastRequestParams {
  smsBlastID: number
}

export interface PutSmsBlastErrorResponse {
  error: Error
  requestParams: PutSmsBlastRequestParams
}

export interface PostSmsBlastVariantRequestParams {
  index: number
  smsBlastID: number
  primaryAttributeValue: string
  secondaryAttributeValue: string | null
  normalPrice: Money
  memberPrice: Money
}

export interface PostSmsBlastVariantErrorResponse {
  error: Error
  requestParams: PostSmsBlastVariantRequestParams
}

export interface PutSmsBlastVariantRequestParams extends PostSmsBlastVariantRequestParams {
  variantID: number
}

export interface PutSmsBlastVariantErrorResponse {
  error: Error
  requestParams: PutSmsBlastVariantRequestParams
}