import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, urlForEndpoint } from './helpers'

export const postLogIn = async (emailAddress: string, password: string): Promise<LogInResponse> => {

  // Build request
  const url = urlForEndpoint(`login`)
  const request = newRequest(HTTPMethods.POST)
  request.body = JSON.stringify({
    emailAddress,
    password,
  })

  // Handle errors and return response
  try {
    const response = await fetchWithErrors(url, request)

    // Handle auth failure
    let responseStatusNumber = Number(response.status)
    if (responseStatusNumber >= 400 && responseStatusNumber <= 599) {
      throw await response.json()
    }

    const { token, expire } = await parseResponse(response)
    let expiration = new Date(expire)

    return { token, expiration }
  } catch (err) {
    throw err
  }
}

export interface LogInResponse {
  token: string
  expiration: Date
}

export const postAdminLogIn = async (managerID: number, token: string): Promise<LogInResponse> => {

  // Build request
  const url = urlForEndpoint(`token-auth`)
  const request = newRequest(HTTPMethods.POST)
  request.body = JSON.stringify({
    managerID,
    token,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { token, expire } = await parseResponse(response)
    let expiration = new Date(expire)

    return { token, expiration }
  } catch (err) {
    throw err
  }
}

export interface LogInResponse {
  token: string
  expiration: Date
}
