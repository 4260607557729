import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import Branch from '../../model/Branch'
import Manager from '../../model/Manager'
import Merchant from '../../model/Merchant'
import {
  BranchesActions, rejectUpdateBranch,
  RejectUpdateBranchAction, resolveUpdateBranch,
  ResolveUpdateBranchAction,
  UpdateBranchAction,
} from '../actions/branches'
import {
  ChangePasswordAction,
  ProfileActions,
  rejectChangePassword, RejectChangePasswordAction,
  rejectFetchProfile,
  RejectFetchProfileAction,
  rejectUpdateProfile,
  RejectUpdateProfileAction,
  resolveChangePassword,
  ResolveChangePasswordAction,
  resolveFetchProfile,
  ResolveFetchProfileAction,
  resolveUpdateProfile,
  ResolveUpdateProfileAction,
  UpdateProfileAction,
} from '../actions/profile'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import * as actionTypes from '../constants/ActionTypes'

interface ProfileReducerState {
  merchant: Merchant | null
  manager: Manager | null
  branchesByID: Map<number, Branch> | null
  branchIsFetchingByID: Map<number, boolean>
  branchErrorByID: Map<number, Error>
  isFetching: boolean
  error: Error | null
  successFlashMessage?: string
}

const initialState: ProfileReducerState = {
  merchant:             null,
  manager:              null,
  branchesByID:         null,
  isFetching:           false,
  error:                null,
  branchIsFetchingByID: new Map<number, boolean>(),
  branchErrorByID:      new Map<number, Error>(),
}

export default function profile(state: ProfileReducerState = initialState, action: ProfileActions | BranchesActions): ProfileReducerState | Loop<ProfileReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          return loop(
            {
              ...state,
              isFetching: true,
            },
            Cmd.run(API.getProfile, {
              successActionCreator: resolveFetchProfile,
              failActionCreator:    rejectFetchProfile,
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchProfileAction
          const { profileResponse } = payload
          const { manager, merchant, branches } = profileResponse
          let branchesByID = new Map<number, Branch>
          branches.forEach(branch => branchesByID.set(branch.id, branch))

          return {
            ...state,
            isFetching: false,
            manager,
            merchant,
            branchesByID,
          }
        }

        case false: {
          const { payload } = action as RejectFetchProfileAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PROFILE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateProfileAction

          return loop(
            {
              ...state,
              err:        null,
              isFetching: true,
            },
            Cmd.run(API.putManager, {
              successActionCreator: resolveUpdateProfile,
              failActionCreator:    rejectUpdateProfile,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateProfileAction
          const { manager } = payload

          return loop({
              ...state,
              isFetching:          false,
              manager,
              successFlashMessage: 'Profile updated successfully!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateProfileAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_BRANCH: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateBranchAction

          let { branchID } = payload
          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          let branchErrorByID = new Map(state.branchErrorByID)

          branchIsFetchingByID.set(branchID, true)
          branchErrorByID.delete(branchID)

          return loop(
            {
              ...state,
              branchIsFetchingByID,
              branchErrorByID,
            },
            Cmd.run(API.putBranch, {
              successActionCreator: resolveUpdateBranch,
              failActionCreator:    rejectUpdateBranch,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateBranchAction
          const { branch } = payload

          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          branchIsFetchingByID.set(branch.id, false)
          let branchesByID = new Map(state.branchesByID)
          branchesByID.set(branch.id, branch)

          return loop({
              ...state,
              branchIsFetchingByID,
              branchesByID,
              successFlashMessage: 'Branch updated successfully!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateBranchAction
          const { error, requestParams } = payload

          let { branchID } = requestParams
          let branchIsFetchingByID = new Map(state.branchIsFetchingByID)
          let branchErrorByID = new Map(state.branchErrorByID)

          branchIsFetchingByID.set(branchID, false)
          branchErrorByID.set(branchID, error)

          return {
            ...state,
            branchIsFetchingByID,
            branchErrorByID,
          }
        }
      }

      break
    }

    case actionTypes.CHANGE_PASSWORD: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as ChangePasswordAction

          return loop(
            {
              ...state,
              err:        null,
              isFetching: true,
            },
            Cmd.run(API.postChangePassword, {
              successActionCreator: resolveChangePassword,
              failActionCreator:    rejectChangePassword,
              args:                 [payload],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveChangePasswordAction
          const { manager } = payload

          return loop({
              ...state,
              isFetching:          false,
              manager,
              successFlashMessage: 'Password changed successfully!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectChangePasswordAction
          const { error } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: undefined,
      }
    }

    default:
      return Object.assign({}, state)
  }
}
