import { Currencies, Money } from 'ts-money'
import { DateRangeFilter, PaymentMethod } from './Invoice'

export default class Payment {
  id: number
  branchID: number
  amount: Money
  createdAt: Date
  paymentMethod: PaymentMethod
  transactionID: string
  notes: string
  paymentAccountID: number
  invoiceID: number

  constructor(json: PaymentJSON) {
    this.id = json.id
    this.branchID = json.branchID
    this.invoiceID = json.invoiceID

    this.amount = Money.fromInteger(json.amount, Currencies.MYR)
    this.createdAt = new Date(json.createdAt)
    this.paymentMethod = PaymentMethod.byID(json.paymentMethod)
    this.transactionID = json.transactionID
    this.notes = json.notes

    this.paymentAccountID = json.paymentAccountID
  }

  get [Symbol.toStringTag]() {
    return 'Payment'
  }
}

export interface PaymentJSON {
  id: number
  branchID: number
  amount: number
  createdAt: string
  paymentMethod: string
  notes: string
  transactionID: string
  paymentAccountID: number
  invoiceID: number
}

export interface PaymentFilters {
  dateRange: DateRangeFilter | null
  paymentMethod: PaymentMethod | null
  paymentAccount: number | null
  branch: number | null
}