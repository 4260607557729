import { Money } from 'ts-money'
import {
  PostProductErrorResponse,
  PostProductRequestParams, PostProductVariantErrorResponse, PostProductVariantRequestParams,
  ProductByIDErrorResponse,
  ProductsErrorResponse,
  ProductsRequestParams,
  ProductsResponse, ProductVariantResponse,
  PutProductErrorResponse,
  PutProductRequestParams, PutProductVariantErrorResponse, PutProductVariantRequestParams,
} from '../../api/products'
import PaginationInfo from '../../model/PaginationInfo'
import Product from '../../model/Product'
import * as types from '../constants/ActionTypes'
import { ResolveFetchCategoriesAction, ResolveFetchCategoryByIDAction } from './categories'
import { ClearFlashMessagesAction } from './general'
import { ResolveCreatePurchasePromoAction, ResolveUpdatePurchasePromoAction } from './promos'


export interface FetchProductsAction {
  type: typeof types.FETCH_PRODUCTS
  success: undefined
  payload: ProductsRequestParams
}

export interface ResolveFetchProductsAction {
  type: typeof types.FETCH_PRODUCTS
  success: boolean
  payload: {
    products: Product[]
    paginationInfo: PaginationInfo
    requestParams: ProductsRequestParams
  }
}

export interface RejectFetchProductsAction {
  type: typeof types.FETCH_PRODUCTS
  success: boolean
  payload: {
    error: Error
    requestParams: ProductsRequestParams
  }
}

export interface FetchProductByIDAction {
  type: typeof types.FETCH_PRODUCT_BY_ID
  success: undefined
  payload: {
    productID: number
  }
}

export interface ResolveFetchProductByIDAction {
  type: typeof types.FETCH_PRODUCT_BY_ID
  success: boolean
  payload: {
    product: Product
  }
}

export interface RejectFetchProductByIDAction {
  type: typeof types.FETCH_PRODUCT_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      productID: number
    }
  }
}

export interface UpdateProductAction {
  type: typeof types.UPDATE_PRODUCT
  success: undefined
  payload: PutProductRequestParams
}

export interface ResolveUpdateProductAction {
  type: typeof types.UPDATE_PRODUCT
  success: boolean
  payload: {
    product: Product
  }
}

export interface RejectUpdateProductAction {
  type: typeof types.UPDATE_PRODUCT
  success: boolean
  payload: PutProductErrorResponse
}


export interface DeleteProductAction {
  type: typeof types.DELETE_PRODUCT
  success: undefined
  payload: {
    productID: number
  }
}

export interface ResolveDeleteProductAction {
  type: typeof types.DELETE_PRODUCT
  success: boolean
  payload: {
    product: Product
  }
}

export interface RejectDeleteProductAction {
  type: typeof types.DELETE_PRODUCT
  success: boolean
  payload: ProductByIDErrorResponse
}

export interface CreateProductAction {
  type: typeof types.CREATE_PRODUCT
  success: undefined
  payload: PostProductRequestParams
}

export interface ResolveCreateProductAction {
  type: typeof types.CREATE_PRODUCT
  success: boolean
  payload: {
    product: Product
  }
}

export interface RejectCreateProductAction {
  type: typeof types.CREATE_PRODUCT
  success: boolean
  payload: PostProductErrorResponse
}


export const fetchProducts = (sorting: string, page: number, limit: number, search: string): FetchProductsAction => ({
  type:    types.FETCH_PRODUCTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchProducts = ({
                                       products,
                                       paginationInfo,
                                       requestParams,
                                     }: ProductsResponse): ResolveFetchProductsAction => ({
  type:    types.FETCH_PRODUCTS,
  success: true,
  payload: {
    products,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchProducts = ({ error, requestParams }: ProductsErrorResponse): RejectFetchProductsAction => ({
  type:    types.FETCH_PRODUCTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchProductByID = (productID: number): FetchProductByIDAction => ({
  type:    types.FETCH_PRODUCT_BY_ID,
  success: undefined,
  payload: {
    productID,
  },
})

export const resolveFetchProductByID = (product: Product): ResolveFetchProductByIDAction => ({
  type:    types.FETCH_PRODUCT_BY_ID,
  success: true,
  payload: {
    product,
  },
})

export const rejectFetchProductByID = ({
                                         error,
                                         requestParams,
                                       }: ProductByIDErrorResponse): RejectFetchProductByIDAction => ({
  type:    types.FETCH_PRODUCT_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateProduct = (productID: number, name: string, description: string, categoryID: number, normalPrice: Money, memberPrice: Money, branchVisibility: number[], primaryAttribute: string | null, secondaryAttribute: string | null): UpdateProductAction => ({
  type:    types.UPDATE_PRODUCT,
  success: undefined,
  payload: {
    productID,
    name,
    description,
    categoryID,
    normalPrice,
    memberPrice,
    primaryAttribute,
    secondaryAttribute,
    branchVisibility,
  },
})

export const resolveUpdateProduct = (product: Product): ResolveUpdateProductAction => ({
  type:    types.UPDATE_PRODUCT,
  success: true,
  payload: {
    product,
  },
})

export const rejectUpdateProduct = (payload: PutProductErrorResponse): RejectUpdateProductAction => ({
  type:    types.UPDATE_PRODUCT,
  success: false,
  payload,
})


export const deleteProduct = (productID: number): DeleteProductAction => ({
  type:    types.DELETE_PRODUCT,
  success: undefined,
  payload: {
    productID,
  },
})

export const resolveDeleteProduct = (product: Product): ResolveDeleteProductAction => ({
  type:    types.DELETE_PRODUCT,
  success: true,
  payload: {
    product,
  },
})

export const rejectDeleteProduct = (payload: ProductByIDErrorResponse): RejectDeleteProductAction => ({
  type:    types.DELETE_PRODUCT,
  success: false,
  payload,
})


export const createProduct = (name: string, description: string, categoryID: number, normalPrice: Money, memberPrice: Money, branchVisibility: number[], primaryAttribute: string | null, secondaryAttribute: string | null): CreateProductAction => ({
  type:    types.CREATE_PRODUCT,
  success: undefined,
  payload: {
    name,
    description,
    categoryID,
    normalPrice,
    memberPrice,
    primaryAttribute,
    secondaryAttribute,
    branchVisibility
  },
})

export const resolveCreateProduct = (product: Product): ResolveCreateProductAction => ({
  type:    types.CREATE_PRODUCT,
  success: true,
  payload: {
    product,
  },
})

export const rejectCreateProduct = (payload: PostProductErrorResponse): RejectCreateProductAction => ({
  type:    types.CREATE_PRODUCT,
  success: false,
  payload,
})


export type ProductsActions =
  FetchProductsAction
  | RejectFetchProductsAction
  | ResolveFetchProductsAction
  | FetchProductByIDAction
  | ResolveFetchProductByIDAction
  | RejectFetchProductByIDAction
  | UpdateProductAction
  | RejectUpdateProductAction
  | ResolveUpdateProductAction
  | CreateProductAction
  | ResolveCreateProductAction
  | RejectCreateProductAction
  | DeleteProductAction
  | ResolveDeleteProductAction
  | RejectDeleteProductAction
  | ClearFlashMessagesAction
  // The following actions have effects on the states herein
  | ResolveFetchCategoriesAction
  | ResolveFetchCategoryByIDAction
  | ResolveCreatePurchasePromoAction
  | ResolveUpdatePurchasePromoAction
