import { createSelector } from 'reselect'
import { PaymentsRequestParams } from '../../api/payments'
import Payment from '../../model/Payment'
import { RootReducerState } from '../'
import PaginationInfo from '../../model/PaginationInfo'
import { PaymentsReducerPage } from '../reducers/payments'

export const hashKeyForPaymentPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     filters,
                                     search = "",
                                   }: PaymentsRequestParams): string => `${page},${limit},${sorting},${JSON.stringify(filters)},${search}`

const paymentsSelector = (state: RootReducerState): { [id: number]: Payment } => state.payments.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.payments.errorByID
const paymentIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.payments.isFetchingByID
const paymentPageSelector = (state: RootReducerState, props: any): PaymentsReducerPage | null => state.payments.pages[hashKeyForPaymentPage(props)] || null
export const paymentFlashMessageSelector = (state: RootReducerState): string | null => state.payments.successFlashMessage

// TODO: fix for react router
export const paymentIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.paymentID || -1
}

export const paymentWithIDFromProps = createSelector(
  [paymentsSelector, paymentIDFromPropsSelector],
  (payments, paymentID) => payments[paymentID] ,
)

export const errorForPaymentWithIDFromProps = createSelector(
  [errorsSelector, paymentIDFromPropsSelector],
  (payments, paymentID) => payments[paymentID],
)

export const isFetchingForPaymentWithIDFromProps = createSelector(
  [paymentIsFetchingByIDSelector, paymentIDFromPropsSelector],
  (isFetching, paymentID) => isFetching[paymentID] || false,
)

export const paymentsByPageSelector = createSelector(
  [paymentsSelector, paymentPageSelector],
  (payments, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(paymentID => payments[paymentID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [paymentPageSelector],
  (page) => page?.paginationInfo || new PaginationInfo(),
)

export const pageIsFetchingSelector = createSelector(
  [paymentPageSelector],
  (page) => page?.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [paymentPageSelector],
  (page) => page?.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [paymentPageSelector],
  (page) => page?.error || null,
)

