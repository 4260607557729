import { createSelector } from 'reselect'
import { EmployeesRequestParams } from '../../api/employees'
import Employee from '../../model/Employee'
import { RootReducerState } from '../'
import { EmployeesReducerPage } from '../reducers/employees'

export const hashKeyForEmployeePage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: EmployeesRequestParams): string => `${page},${limit},${sorting},${search}`

export const employeesSelector = (state: RootReducerState): { [id: number]: Employee } => state.employees.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.employees.errorByID
const employeeIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.employees.isFetchingByID

const employeePageSelector = (state: RootReducerState, props: any): EmployeesReducerPage => state.employees.pages[hashKeyForEmployeePage(props)] || new EmployeesReducerPage()
export const employeeFlashMessageSelector = (state: RootReducerState): string | null => state.employees.successFlashMessage

// TODO: fix for react router
export const employeeIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.employeeID || -1
}

export const employeeWithIDFromProps = createSelector(
  [employeesSelector, employeeIDFromPropsSelector],
  (employees, employeeID) => employees[employeeID],
)

export const errorForEmployeeWithIDFromProps = createSelector(
  [errorsSelector, employeeIDFromPropsSelector],
  (employees, employeeID) => employees[employeeID],
)

export const isFetchingForEmployeeWithIDFromProps = createSelector(
  [employeeIsFetchingByIDSelector, employeeIDFromPropsSelector],
  (employees, employeeID) => employees[employeeID] || false,
)

export const employeesByPageSelector = createSelector(
  [employeesSelector, employeePageSelector],
  (employees, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(employeeID => employees[employeeID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [employeePageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [employeePageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [employeePageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [employeePageSelector],
  (page) => page.error || null,
)

