import { Currencies, Money } from 'ts-money'
import Invoice, { InvoiceJSON } from './Invoice'
import Vehicle, { VehicleJSON } from './Vehicle'

export default class Customer {
  id: number
  name: string
  phoneNumber: string
  emailAddress: string | null
  vehicles: Vehicle[] | null
  invoices: Invoice[] | null
  createdAt: Date
  extraData: CustomerData

  constructor(json: CustomerJSON) {
    this.id = json.id
    this.name = json.name
    this.phoneNumber = json.phoneNumber
    this.emailAddress = json.emailAddress
    this.vehicles = null
    if (json.vehicles) {
      this.vehicles = json.vehicles.map(vehicleJSON => new Vehicle(vehicleJSON))
    }
    this.invoices = null
    if (json.invoices) {
      this.invoices = json.invoices.map(invoiceJSON => new Invoice(invoiceJSON))
    }
    this.createdAt = new Date(json.createdAt)
    this.extraData = new CustomerData(json.extraData)
  }

  get [Symbol.toStringTag]() {
    return 'Customer'
  }
}

class CustomerData {
  address: string | null
  gender: string | null
  dateOfBirth: string | null

  constructor(json: CustomerDataJSON) {
    this.address = json.address
    this.gender = json.gender
    this.dateOfBirth = json.dateOfBirth
  }

  get [Symbol.toStringTag]() {
    return 'CustomerData'
  }
}

export class CustomerStats {
  customerID: number
  numberOfInvoices: number
  lifetimeSpend: Money
  averageBasketSize: Money

  constructor(json: CustomerStatsJSON) {
    this.customerID = json.customerID
    this.numberOfInvoices = json.numberOfInvoices
    this.lifetimeSpend = Money.fromInteger(json.lifetimeSpend, Currencies.MYR)
    this.averageBasketSize = Money.fromInteger(json.averageBasketSize, Currencies.MYR)
  }

  get [Symbol.toStringTag]() {
    return 'CustomerStats'
  }
}

export interface CustomerJSON {
  id: number
  name: string
  phoneNumber: string
  emailAddress: string | null
  vehicles: VehicleJSON[] | null
  invoices: InvoiceJSON[] | null
  createdAt: string
  extraData: CustomerDataJSON
}

interface CustomerDataJSON {
  address: string
  gender: string
  dateOfBirth: string
}

export interface CustomerStatsJSON {
  customerID: number
  numberOfInvoices: number
  lifetimeSpend: number
  averageBasketSize: number
}

export interface CustomerFilters {
  lastPurchasedProduct: number[] | null
  frequencyOfVisits: FrequencyOfVisitsFilter | null
  numberOfVisits: NumberOfVisitsFilter | null
}

export interface FrequencyOfVisitsFilter {
  s: string
  e: string
  n: number
  c: number
}

export interface NumberOfVisitsFilter {
  n: number
  c: number
}