import { Card, Container, Divider, Typography } from '@mui/material'
import React from 'react'

interface IProps {
  title: string,
  body: string,
  children: any
}

let ErrorPage = ({ title, body, children }: IProps) => {
  return (
    <Container>
      <Card sx={{ p: 3, mt: 13 }}>
        <Typography variant="h2" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h3" component="h2" gutterBottom>
          {body}
        </Typography>
        <Divider sx={{mt: 2, mb: 2}}/>
        {children}
      </Card>
    </Container>
  )
}

export default ErrorPage