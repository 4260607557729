import { Currencies, Money } from 'ts-money'
import Category, { CategoryJSON } from './Category'

export default class CategoryValue {
  category: Category
  salesAmount: Money

  constructor(json: CategoryValueJSON) {
    this.category = new Category(json.category)
    this.salesAmount = Money.fromInteger(json.salesAmount, Currencies.MYR)
  }

  get [Symbol.toStringTag]() {
    return 'CategoryValue'
  }
}

export interface CategoryValueJSON {
  category: CategoryJSON
  salesAmount: number
}
