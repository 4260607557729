import { createSelector } from 'reselect'
import { CategoriesRequestParams } from '../../api/categories'
import Category from '../../model/Category'
import { RootReducerState } from '../'
import { CategoriesReducerPage } from '../reducers/categories'

export const hashKeyForCategoryPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search,
                                   }: CategoriesRequestParams): string => `${page},${limit},${sorting}`

const categoriesByIDSelector = (state: RootReducerState): { [id: number]: Category } => state.categories.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.categories.errorByID
const categoryIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.categories.isFetchingByID
const categoryPageSelector = (state: RootReducerState, props: any): CategoriesReducerPage => state.categories.pages[hashKeyForCategoryPage(props)] || new CategoriesReducerPage()
export const categoryFlashMessageSelector = (state: RootReducerState): string | null => state.categories.successFlashMessage
// TODO: fix for react router
export const categoryIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.categoryID || -1
}

export const categoryWithIDFromProps = createSelector(
  [categoriesByIDSelector, categoryIDFromPropsSelector],
  (categories, categoryID) => categories[categoryID] ,
)

export const errorForCategoryWithIDFromProps = createSelector(
  [errorsSelector, categoryIDFromPropsSelector],
  (categories, categoryID) => categories[categoryID],
)

export const isFetchingForCategoryWithIDFromProps = createSelector(
  [categoryIsFetchingByIDSelector, categoryIDFromPropsSelector],
  (categories, categoryID) => categories[categoryID] || false,
)

export const categoriesByPageSelector = createSelector(
  [categoriesByIDSelector, categoryPageSelector],
  (categories, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(categoryID => categories[categoryID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [categoryPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [categoryPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [categoryPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [categoryPageSelector],
  (page) => page.error || null,
)

