import { Money, Currencies } from 'ts-money'
import Category, { CategoryJSON } from './Category'

export default class ProductVariant {
  id: number
  normalPrice: Money
  memberPrice: Money
  primaryAttributeValue: string
  secondaryAttributeValue: string | null

  constructor(json: ProductVariantJSON) {
    this.id = json.id
    this.normalPrice = Money.fromInteger(json.normalPrice, Currencies.MYR)
    this.memberPrice = Money.fromInteger(json.memberPrice, Currencies.MYR)
    this.primaryAttributeValue = json.primaryAttributeValue
    this.secondaryAttributeValue = json.secondaryAttributeValue || null
  }

  get [Symbol.toStringTag]() {
    return 'ProductVariant'
  }

  get descriptor(): string {
    if (this.secondaryAttributeValue != null) {
      return `${this.primaryAttributeValue}, ${this.secondaryAttributeValue}`
    } else {
      return `${this.primaryAttributeValue}`
    }
  }
}

export interface ProductVariantJSON {
  id: number
  normalPrice: number
  memberPrice: number
  primaryAttributeValue: string
  secondaryAttributeValue: string | null
}
