import { createSelector } from 'reselect'
import { ProductCreditTransactionsRequestParams } from '../../api/productCreditTransactions'
import ProductCreditTransaction from '../../model/ProductCreditTransaction'
import { RootReducerState } from '../'
import { ProductCreditTransactionsReducerPage } from '../reducers/productCreditTransactions'

export const hashKeyForProductCreditTransactionPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search = "",
                                   }: ProductCreditTransactionsRequestParams): string => `${page},${limit},${sorting},${search}`

export const productCreditTransactionsSelector = (state: RootReducerState): { [id: number]: ProductCreditTransaction } => state.productCreditTransactions.byID

const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.productCreditTransactions.errorByID
const productCreditTransactionIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.productCreditTransactions.isFetchingByID

const productCreditTransactionPageSelector = (state: RootReducerState, props: any): ProductCreditTransactionsReducerPage => state.productCreditTransactions.pages[hashKeyForProductCreditTransactionPage(props)] || new ProductCreditTransactionsReducerPage()
export const productCreditTransactionFlashMessageSelector = (state: RootReducerState): string | null => state.productCreditTransactions.successFlashMessage

// TODO: fix for react router
export const productCreditTransactionIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return +props.productCreditTransactionID || -1
}

export const productCreditTransactionWithIDFromProps = createSelector(
  [productCreditTransactionsSelector, productCreditTransactionIDFromPropsSelector],
  (productCreditTransactions, productCreditTransactionID) => productCreditTransactions[productCreditTransactionID],
)

export const errorForProductCreditTransactionWithIDFromProps = createSelector(
  [errorsSelector, productCreditTransactionIDFromPropsSelector],
  (productCreditTransactions, productCreditTransactionID) => productCreditTransactions[productCreditTransactionID],
)

export const isFetchingForProductCreditTransactionWithIDFromProps = createSelector(
  [productCreditTransactionIsFetchingByIDSelector, productCreditTransactionIDFromPropsSelector],
  (productCreditTransactions, productCreditTransactionID) => productCreditTransactions[productCreditTransactionID] || false,
)

export const productCreditTransactionsByPageSelector = createSelector(
  [productCreditTransactionsSelector, productCreditTransactionPageSelector],
  (productCreditTransactions, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(productCreditTransactionID => productCreditTransactions[productCreditTransactionID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [productCreditTransactionPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [productCreditTransactionPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [productCreditTransactionPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [productCreditTransactionPageSelector],
  (page) => page.error || null,
)

