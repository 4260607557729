import PaymentAccount, { PaymentAccountJSON } from './PaymentAccount'

export default class Branch {
  id: number
  name: string
  address: string
  registrationNumber: string
  phoneNumber: string
  invoiceFooterNotes: string | null
  receiptLogo: string
  paymentAccounts: PaymentAccount[]

  constructor(json: BranchJSON) {
    this.id = json.id
    this.name = json.name
    this.address = json.address
    this.receiptLogo = json.receiptLogo
    this.registrationNumber = json.registrationNumber
    this.invoiceFooterNotes = json.invoiceFooterNotes
    this.phoneNumber = json.phoneNumber
    if (json.paymentAccounts != null) {
      this.paymentAccounts = json.paymentAccounts.map(paj => new PaymentAccount(paj))
    } else {
      this.paymentAccounts = []
    }
  }

  get [Symbol.toStringTag]() {
    return 'Branch'
  }
}

export interface BranchJSON {
  id: number
  name: string
  address: string
  registrationNumber: string
  phoneNumber: string
  invoiceFooterNotes: string | null
  receiptLogo: string

  paymentAccounts: PaymentAccountJSON[]
}
