import { createSelector } from 'reselect'
import { VehiclesRequestParams } from '../../api/vehicles'
import Vehicle from '../../model/Vehicle'
import { RootReducerState } from '../'
import { VehiclesReducerPage } from '../reducers/vehicles'

export const hashKeyForVehiclePage = ({
                                     sorting,
                                     page,
                                     limit,
                                     filters,
                                     search = "",
                                   }: VehiclesRequestParams): string => `${page},${limit},${sorting},${JSON.stringify(filters)},${search}`

const vehiclesSelector = (state: RootReducerState): { [id: number]: Vehicle } => state.vehicles.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.vehicles.errorByID
const vehicleIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.vehicles.isFetchingByID
const vehiclePageSelector = (state: RootReducerState, props: any): VehiclesReducerPage => state.vehicles.pages[hashKeyForVehiclePage(props)] || new VehiclesReducerPage()
export const vehicleFlashMessageSelector = (state: RootReducerState): string | null => state.vehicles.successFlashMessage

// TODO: fix for react router
export const vehicleIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.vehicleID || -1
}

export const vehicleWithIDFromProps = createSelector(
  [vehiclesSelector, vehicleIDFromPropsSelector],
  (vehicles, vehicleID) => vehicles[vehicleID] ,
)

export const errorForVehicleWithIDFromProps = createSelector(
  [errorsSelector, vehicleIDFromPropsSelector],
  (vehicles, vehicleID) => vehicles[vehicleID],
)

export const isFetchingForVehicleWithIDFromProps = createSelector(
  [vehicleIsFetchingByIDSelector, vehicleIDFromPropsSelector],
  (vehicles, vehicleID) => vehicles[vehicleID] || false,
)

export const vehiclesByPageSelector = createSelector(
  [vehiclesSelector, vehiclePageSelector],
  (vehicles, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(vehicleID => vehicles[vehicleID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [vehiclePageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [vehiclePageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [vehiclePageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [vehiclePageSelector],
  (page) => page.error || null,
)

