export default class Employee {
  id: number
  name: string
  icNumber: string
  phoneNumber: string | null
  emailAddress: string | null
  createdAt: Date

  constructor(json: EmployeeJSON) {
    this.id = json.id
    this.name = json.name
    this.icNumber = json.icNumber
    this.phoneNumber = json.phoneNumber
    this.emailAddress = json.emailAddress
    this.createdAt = new Date(json.createdAt)
  }

  get [Symbol.toStringTag]() {
    return 'Employee'
  }
}

export interface EmployeeJSON {
  id: number
  name: string
  icNumber: string
  phoneNumber: string | null
  emailAddress: string | null
  createdAt: string
}
