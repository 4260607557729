import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import Category from '../../model/Category'
import PaginationInfo from '../../model/PaginationInfo'
import {
  CategoriesActions,
  CreateCategoryAction,
  DeleteCategoryAction,
  FetchCategoriesAction,
  FetchCategoryByIDAction,
  rejectCreateCategory,
  RejectCreateCategoryAction,
  rejectDeleteCategory,
  RejectDeleteCategoryAction,
  rejectFetchCategories,
  RejectFetchCategoriesAction,
  rejectFetchCategoryByID,
  RejectFetchCategoryByIDAction,
  rejectUpdateCategory,
  RejectUpdateCategoryAction,
  resolveCreateCategory,
  ResolveCreateCategoryAction,
  resolveDeleteCategory,
  ResolveDeleteCategoryAction,
  resolveFetchCategories,
  ResolveFetchCategoriesAction,
  resolveFetchCategoryByID,
  ResolveFetchCategoryByIDAction,
  resolveUpdateCategory,
  ResolveUpdateCategoryAction,
  UpdateCategoryAction,
} from '../actions/categories'
import {
  ResolveCreateProductAction,
  ResolveDeleteProductAction,
  ResolveUpdateProductAction,
} from '../actions/products'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForCategoryPage } from '../selectors/categories'

interface CategoriesReducerState {
  byID: Record<number, Category>
  pages: { [hash: string]: CategoriesReducerPage }
  isFetchingByID: { [id: number]: boolean }
  errorByID: { [id: number]: Error | null }
  successFlashMessage: string | null
}

export class CategoriesReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: CategoriesReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  errorByID:           {},
  successFlashMessage: null,
}

export default (state: CategoriesReducerState = initialState, action: CategoriesActions): CategoriesReducerState | Loop<CategoriesReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_CATEGORIES: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchCategoriesAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new CategoriesReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForCategoryPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getCategories, {
              successActionCreator: resolveFetchCategories,
              failActionCreator:    rejectFetchCategories,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchCategoriesAction
          const { categories, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForCategoryPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       categories.map((category: Category) => category.id),
            paginationInfo: paginationInfo,
          }

          // Map category ids to categories
          let idMappedCategories: Record<number, Category> = {}
          categories.forEach(category => {
            idMappedCategories[category.id] = category
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedCategories),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchCategoriesAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForCategoryPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_CATEGORY_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchCategoryByIDAction
          const { categoryID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [categoryID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [categoryID]: null,
              },
            },
            Cmd.run(API.getCategoryByID, {
              successActionCreator: resolveFetchCategoryByID,
              failActionCreator:    rejectFetchCategoryByID,
              args:                 [categoryID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchCategoryByIDAction
          const { category } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [category.id]: category,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [category.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchCategoryByIDAction
          const { error, requestParams } = payload
          const { categoryID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [categoryID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [categoryID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_CATEGORY: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateCategoryAction
          const { categoryID, name } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [categoryID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [categoryID]: null,
              },
            },
            Cmd.run(API.putCategory, {
              successActionCreator: resolveUpdateCategory,
              failActionCreator:    rejectUpdateCategory,
              args:                 [categoryID, name],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateCategoryAction
          const { category } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [category.id]: category,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [category.id]: false,
              },
              successFlashMessage: 'Category successfully updated!',
            },
            Cmd.list([
              Cmd.action(push('/categories')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateCategoryAction
          const { error, requestParams } = payload
          const { categoryID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [categoryID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [categoryID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_CATEGORY: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteCategoryAction
          const { categoryID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [categoryID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [categoryID]: null,
              },
            },
            Cmd.run(API.deleteCategory, {
              successActionCreator: resolveDeleteCategory,
              failActionCreator:    rejectDeleteCategory,
              args:                 [categoryID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteCategoryAction
          const { category } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[category.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [category.id]: false,
              },
              successFlashMessage: 'Category successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/categories')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteCategoryAction
          const { error, requestParams } = payload
          const { categoryID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [categoryID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [categoryID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_CATEGORY: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateCategoryAction
          const { name } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [-1]: null,
              },
            },
            Cmd.run(API.postCategory, {
              successActionCreator: resolveCreateCategory,
              failActionCreator:    rejectCreateCategory,
              args:                 [name],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateCategoryAction
          const { category } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [category.id]: category,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Category successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/categories/`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateCategoryAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PRODUCT: {
      let { success } = action
      switch (success) {
        case true: {
          let { payload } = action as ResolveDeleteProductAction
          const { product: deletedProduct } = payload

          let oldCategoryID = deletedProduct.category!.id

          // This should be the category that contains the product
          let category = {
            ...state.byID[oldCategoryID],
          } as Category
          category.products = category.products?.slice() || null

          // Remove product from its category
          const deletedIndex = category.products?.findIndex(product => deletedProduct.id == product.id)
          if (deletedIndex && deletedIndex != -1) {
            // Remove from products
            category.products?.splice(deletedIndex, 1)
            return {
              ...state,
              byID: {
                ...state.byID,
                [category.id]: category
              }
            }
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PRODUCT: {
      let { success } = action
      switch (success) {
        case true: {
          let { payload } = action as ResolveCreateProductAction
          const { product } = payload

          // Add product to category
          let category = {
            ...state.byID[product.category!.id],
          } as Category
          category.products?.push(product)

          // Replace item in byID
          let byID = {
            ...state.byID,
          }
          byID[category.id] = category

          return {
            ...state,
            byID,
          }

        }
      }

      break
    }

    case actionTypes.UPDATE_PRODUCT: {
      let { success } = action
      switch (success) {
        case true: {
          let { payload } = action as ResolveUpdateProductAction
          const { product: editedProduct } = payload

          // Check if the product's category has changed
          let category = { ...state.byID[editedProduct.category!.id] } as Category
          const oldIndex = category.products?.findIndex(product => editedProduct.id == product.id)
          if (oldIndex !== -1) {
            // Category has not changed. Just update product in category
            let newProducts = category.products?.map(p => {
              if (p.id == editedProduct.id) {
                return editedProduct
              }
              return p
            }) || null
            category.products = newProducts
            let byID = {
              ...state.byID,
            }
            byID[category.id] = category
          }

          // Category has changed
          let byID = {
            ...state.byID,
          }

          // Remove from old category
          for (const oldCategoryID in byID) {
            // Copy, lest we modify in memory
            let oldCategory = {
              ...byID[oldCategoryID],
              products: byID[oldCategoryID].products?.slice(),
            } as Category
            const deletedIndex = category.products?.findIndex(product => editedProduct.id == product.id)
            if (deletedIndex && deletedIndex != -1) {
              category.products?.splice(deletedIndex, 1)
              byID[category.id] = category
              break
            }
          }

          // Add category
          category.products?.push(editedProduct)
          byID[category.id] = category

          return {
            ...state,
            byID,
          }
        }
      }

      break
    }


    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }
  }

  // Default
  return state
}
