import { createSelector } from 'reselect'
import { PromosRequestParams } from '../../api/promos'
import Promo from '../../model/Promo'
import { RootReducerState } from '../'
import { PromosReducerPage } from '../reducers/promos'

export const hashKeyForPromoPage = ({
                                     sorting,
                                     page,
                                     limit,
                                     search,
                                   }: PromosRequestParams): string => `${page},${limit},${sorting}`

const promosSelector = (state: RootReducerState): { [id: number]: Promo } => state.promos.byID
const errorsSelector = (state: RootReducerState): { [id: number]: Error | null } => state.promos.errorByID
const promoIsFetchingByIDSelector = (state: RootReducerState): { [id: number]: boolean } => state.promos.isFetchingByID
const promoPageSelector = (state: RootReducerState, props: any): PromosReducerPage => state.promos.pages[hashKeyForPromoPage(props)] || new PromosReducerPage()
export const promoFlashMessageSelector = (state: RootReducerState): string | null => state.promos.successFlashMessage

// TODO: fix for react router
export const promoIDFromPropsSelector = (state: RootReducerState, props: any): number => {
  return props.promoID || -1
}

export const promoWithIDFromProps = createSelector(
  [promosSelector, promoIDFromPropsSelector],
  (promos, promoID) => promos[promoID] ,
)

export const errorForPromoWithIDFromProps = createSelector(
  [errorsSelector, promoIDFromPropsSelector],
  (promos, promoID) => promos[promoID],
)

export const isFetchingForPromoWithIDFromProps = createSelector(
  [promoIsFetchingByIDSelector, promoIDFromPropsSelector],
  (promos, promoID) => promos[promoID] || false,
)

export const promosByPageSelector = createSelector(
  [promosSelector, promoPageSelector],
  (promos, page) => {
    if (page == null || page.childIDs == null) {
      return null
    }
    return page.childIDs.map(promoID => promos[promoID])
  },
)

export const paginationInfoByPageSelector = createSelector(
  [promoPageSelector],
  (page) => page.paginationInfo,
)

export const pageIsFetchingSelector = createSelector(
  [promoPageSelector],
  (page) => page.isFetching || false,
)

export const pageIsInitializedSelector = createSelector(
  [promoPageSelector],
  (page) => page.isInitialized || false,
)

export const pageErrorSelector = createSelector(
  [promoPageSelector],
  (page) => page.error || null,
)

