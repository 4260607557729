export default class ProductCreditTransaction {
  id: number
  notes: string
  amount: number

  productID: number
  variantID: number | null

  customerID: number | null
  vehicleID: number | null

  creditingInvoiceID: number | null
  creditingPromoID: number | null
  debitingInvoiceID: number | null

  createdAt: Date
  expiresAt: Date | null
  expiredAt: Date | null

  constructor(json: ProductCreditTransactionJSON) {
    this.id = json.id
    this.notes = json.notes
    this.amount = json.amount
    this.productID = json.productID
    this.variantID = json.variantID
    this.customerID = json.customerID
    this.vehicleID = json.vehicleID
    this.creditingInvoiceID = json.creditingInvoiceID
    this.creditingPromoID = json.creditingPromoID
    this.debitingInvoiceID = json.debitingInvoiceID
    this.createdAt = new Date(json.createdAt)
    if (json.expiresAt != null) {
      this.expiresAt = new Date(json.expiresAt)
    } else {
      this.expiresAt = null
    }
    if (json.expiredAt != null) {
      this.expiredAt = new Date(json.expiredAt)
    } else {
      this.expiredAt = null
    }
  }

  get [Symbol.toStringTag]() {
    return 'ProductCreditTransaction'
  }
}

export interface ProductCreditTransactionJSON {
  id: number
  notes: string
  amount: number
  productID: number
  customerID: number | null
  vehicleID: number | null
  variantID: number | null
  creditingInvoiceID: number | null
  creditingPromoID: number | null
  debitingInvoiceID: number | null
  createdAt: string
  expiresAt: string  | null
  expiredAt: string  | null
}
