import ProductCreditTransaction, {ProductCreditTransactionJSON } from '../model/ProductCreditTransaction'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getProductCreditTransactions = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<ProductCreditTransactionsResponse> => {
  // Build request
  const url = urlForEndpoint(`product-credits/transactions`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { productCreditTransactions: productCreditTransactionsJSON, paginationInfo } = await parseResponse(response)

    let productCreditTransactions = productCreditTransactionsJSON.map((productCreditTransactionJSON: ProductCreditTransactionJSON) => new ProductCreditTransaction(productCreditTransactionJSON))

    return {
      productCreditTransactions,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getProductCreditTransactionByID = async (productCreditTransactionID: number): Promise<ProductCreditTransaction> => {

  // Build request
  const url = urlForEndpoint(`product-credits/transactions/${productCreditTransactionID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { productCreditTransaction } = await parseResponse(response)
    return new ProductCreditTransaction(productCreditTransaction as ProductCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { productCreditTransactionID })
  }

}

export const putProductCreditTransaction = async (productCreditTransactionID: number, name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): Promise<ProductCreditTransaction> => {
  // Build request
  const url = urlForEndpoint(`product-credits/transactions/${productCreditTransactionID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    company,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { productCreditTransaction } = await parseResponse(response)
    return new ProductCreditTransaction(productCreditTransaction as ProductCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { productCreditTransactionID, name, phoneNumber, emailAddress })
  }
}

export const deleteProductCreditTransaction = async (productCreditTransactionID: number): Promise<ProductCreditTransaction> => {
  // Build request
  const url = urlForEndpoint(`product-credits/transactions/${productCreditTransactionID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { productCreditTransaction } = await parseResponse(response)
    return new ProductCreditTransaction(productCreditTransaction as ProductCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { productCreditTransactionID })
  }
}

export const postProductCreditTransaction = async (name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): Promise<ProductCreditTransaction> => {
  // Build request
  const url = urlForEndpoint(`product-credits/transactions`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    company,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { productCreditTransaction } = await parseResponse(response)
    return new ProductCreditTransaction(productCreditTransaction as ProductCreditTransactionJSON)
  } catch (err) {
    throw new LoopError(err, { name, phoneNumber, emailAddress })
  }
}

export interface ProductCreditTransactionIDsResponse {
  productCreditTransactionIDs: number[]
  requestParams: ProductCreditTransactionsRequestParams
}


export interface ProductCreditTransactionsResponse {
  productCreditTransactions: ProductCreditTransaction[]
  paginationInfo: PaginationInfo
  requestParams: ProductCreditTransactionsRequestParams
}

export interface ProductCreditTransactionsErrorResponse {
  error: Error
  requestParams: ProductCreditTransactionsRequestParams
}

export interface ProductCreditTransactionsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface ProductCreditTransactionByIDRequestParams {
  productCreditTransactionID: number
}

export interface ProductCreditTransactionByIDErrorResponse {
  error: Error
  requestParams: ProductCreditTransactionByIDRequestParams
}

export interface PostProductCreditTransactionRequestParams {
  name: string
  company: string | null
  phoneNumber: string | null
  emailAddress: string | null
}

export interface PostProductCreditTransactionErrorResponse {
  error: Error
  requestParams: PostProductCreditTransactionRequestParams
}

export interface PutProductCreditTransactionRequestParams extends PostProductCreditTransactionRequestParams {
  productCreditTransactionID: number
}

export interface PutProductCreditTransactionErrorResponse {
  error: Error
  requestParams: PutProductCreditTransactionRequestParams
}