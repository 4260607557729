import { useTheme } from '@mui/material'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { paramsFromSearchQuery } from '../../helpers/url'
import { RootReducerState } from '../../store'
import { submitAdminLogIn } from '../../store/actions/authentication'
import LoginPage from './LoginPage'

let AuthenticationWall = ({ children, isAuthenticated, location, actions}: IProps) => {
  const theme = useTheme(); // hack until I figure out why you can't call "useTheme()" in classes

  // Admin auth
  if (location.pathname == "/x") {
    const { m, k } = paramsFromSearchQuery(location.search)
    actions.submitAdminLogIn(+m, decodeURIComponent(k))
    return
  }


  if (isAuthenticated) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return (
      <LoginPage theme={theme}/>
    )
  }
}


const mapStateToProps = (state: RootReducerState) => {
  return {
    isAuthenticated: state.authentication.token != null,
    location: state.router.location
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({
    submitAdminLogIn
  }, dispatch),
})


const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux & {
  children: any
  isAuthenticated: boolean
  location: Location
}

// @ts-ignore
export default connector(AuthenticationWall)