import { Money, Currencies } from 'ts-money'
import Category, { CategoryJSON } from './Category'
import ProductVariant, { ProductVariantJSON } from './ProductVariant'

export default class Product {
  id: number
  name: string
  description: string
  normalPrice: Money
  memberPrice: Money
  category: Category | null
  isSystemGenerated: boolean
  primaryAttribute: string | null
  secondaryAttribute: string | null
  variants: ProductVariant[] | null
  branchesOfferedAt: number[]

  constructor(json: ProductJSON) {
    this.id = json.id
    this.name = json.name
    this.description = json.description
    this.normalPrice = Money.fromInteger(json.normalPrice, Currencies.MYR)
    this.memberPrice = Money.fromInteger(json.memberPrice, Currencies.MYR)
    this.isSystemGenerated = json.isSystemGenerated
    this.primaryAttribute = json.primaryVariantAttribute
    this.secondaryAttribute = json.secondaryVariantAttribute
    this.variants = null
    if (json.variants != null) {
      this.variants = json.variants.map(v => new ProductVariant(v))
    }

    this.category = null
    if (json.category != null) {
      this.category = new Category(json.category)
    }
    this.branchesOfferedAt = json.branchesOfferedAt
  }

  get [Symbol.toStringTag]() {
    return 'Product'
  }
}

export interface ProductJSON {
  id: number
  name: string
  description: string
  normalPrice: number
  memberPrice: number
  category: CategoryJSON | null
  isSystemGenerated: boolean
  primaryVariantAttribute: string | null
  secondaryVariantAttribute: string | null
  variants: ProductVariantJSON[] | null
  branchesOfferedAt: number[]
}
