import { CircularProgress } from '@mui/material'
import React from 'react'
import styles from './LoadingPlaceholder.less'

let LoadingPlaceholder = () => {
  return (
    <div className={styles.loadingPlaceholder}>
      <CircularProgress />
    </div>
  )
}

export default LoadingPlaceholder