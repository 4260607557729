import Employee, {EmployeeJSON } from '../model/Employee'
import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getEmployees = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<EmployeesResponse> => {
  // Build request
  const url = urlForEndpoint(`employees`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { employees: employeesJSON, paginationInfo } = await parseResponse(response)

    let employees = employeesJSON.map((employeeJSON: EmployeeJSON) => new Employee(employeeJSON))

    return {
      employees,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getEmployeeByID = async (employeeID: number): Promise<Employee> => {

  // Build request
  const url = urlForEndpoint(`employees/${employeeID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { employee } = await parseResponse(response)
    return new Employee(employee as EmployeeJSON)
  } catch (err) {
    throw new LoopError(err, { employeeID })
  }

}

export const putEmployee = async (employeeID: number, name: string, icNumber: string, phoneNumber: string | null, emailAddress: string | null): Promise<Employee> => {
  // Build request
  const url = urlForEndpoint(`employees/${employeeID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    icNumber,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { employee } = await parseResponse(response)
    return new Employee(employee as EmployeeJSON)
  } catch (err) {
    throw new LoopError(err, { employeeID, name, phoneNumber, emailAddress })
  }
}

export const deleteEmployee = async (employeeID: number): Promise<Employee> => {
  // Build request
  const url = urlForEndpoint(`employees/${employeeID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { employee } = await parseResponse(response)
    return new Employee(employee as EmployeeJSON)
  } catch (err) {
    throw new LoopError(err, { employeeID })
  }
}

export const postEmployee = async (name: string, icNumber: string, phoneNumber: string | null, emailAddress: string | null): Promise<Employee> => {
  // Build request
  const url = urlForEndpoint(`employees`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    icNumber,
    phoneNumber,
    emailAddress,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { employee } = await parseResponse(response)
    return new Employee(employee as EmployeeJSON)
  } catch (err) {
    throw new LoopError(err, { name, phoneNumber, emailAddress })
  }
}

export interface EmployeeIDsResponse {
  employeeIDs: number[]
  requestParams: EmployeesRequestParams
}


export interface EmployeesResponse {
  employees: Employee[]
  paginationInfo: PaginationInfo
  requestParams: EmployeesRequestParams
}

export interface EmployeesErrorResponse {
  error: Error
  requestParams: EmployeesRequestParams
}

export interface EmployeesRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface EmployeeByIDRequestParams {
  employeeID: number
}

export interface EmployeeByIDErrorResponse {
  error: Error
  requestParams: EmployeeByIDRequestParams
}

export interface PostEmployeeRequestParams {
  name: string
  icNumber: string
  phoneNumber: string | null
  emailAddress: string | null
}

export interface PostEmployeeErrorResponse {
  error: Error
  requestParams: PostEmployeeRequestParams
}

export interface PutEmployeeRequestParams extends PostEmployeeRequestParams {
  employeeID: number
}

export interface PutEmployeeErrorResponse {
  error: Error
  requestParams: PutEmployeeRequestParams
}