export default class PaginationInfo {
  currentPage: number = 1
  limit: number = 30
  totalRecords: number = 0
  pageCount: number = 0
  totalValue?: number = undefined

  get [Symbol.toStringTag]() {
    return 'PaginationInfo'
  }
}
