import { Money } from 'ts-money'
import Bundle, { BundleJSON } from '../model/Bundle'
import  { ProductIDQuantity } from '../model/Promo'

import PaginationInfo from '../model/PaginationInfo'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getBundles = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<BundlesResponse> => {

  // Build request
  const url = urlForEndpoint(`bundles`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const {bundles: bundlesJSON, paginationInfo} = await parseResponse(response)

    let bundles = bundlesJSON.map((bundleJSON: BundleJSON)  => new Bundle(bundleJSON))

    return {
      bundles,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, {sorting, page, limit, search})
  }
}

export const getBundleByID = async (bundleID: number): Promise<Bundle> => {

  // Build request
  const url = urlForEndpoint(`bundles/${bundleID}`)

  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const {bundle} = await parseResponse(response)
    return new Bundle(bundle as BundleJSON)
  } catch (err) {
    throw new LoopError(err, {bundleID})
  }

}

export const putBundle = async(bundleID: number, name: string, price: Money, productIDQuantities: ProductIDQuantity[]): Promise<Bundle>  => {
  // Build request
  const url = urlForEndpoint(`bundles/${bundleID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    price: price.amount,
    productIDQuantities
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const {bundle} = await parseResponse(response)
    return new Bundle(bundle as BundleJSON)
  } catch (err) {
    throw new LoopError(err, {bundleID, name, price, productIDQuantities})
  }
}

export const deleteBundle = async(bundleID: number): Promise<Bundle>  => {
  // Build request
  const url = urlForEndpoint(`bundles/${bundleID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const {bundle} = await parseResponse(response)
    return new Bundle(bundle as BundleJSON)
  } catch (err) {
    throw new LoopError(err, {bundleID})
  }
}

export const postBundle = async(name: string, price: Money, productIDQuantities: ProductIDQuantity[]): Promise<Bundle>  => {
  // Build request
  const url = urlForEndpoint(`bundles`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    price: price.amount,
    productIDQuantities
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const {bundle} = await parseResponse(response)
    return new Bundle(bundle as BundleJSON)
  } catch (err) {
    throw new LoopError(err, {name, price, productIDQuantities})
  }
}

export interface BundlesResponse {
  bundles: Bundle[]
  paginationInfo: PaginationInfo
  requestParams: BundlesRequestParams
}

export interface BundlesErrorResponse {
  error: Error
  requestParams: BundlesRequestParams
}

export interface BundlesRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface BundleByIDRequestParams {
  bundleID: number
}

export interface BundleByIDErrorResponse {
  error: Error
  requestParams: BundleByIDRequestParams
}

export interface PostBundleRequestParams {
  name: string
  price: Money
  productIDQuantities: ProductIDQuantity[]
}

export interface PostBundleErrorResponse {
  error: Error
  requestParams: PostBundleRequestParams
}

export interface PutBundleRequestParams extends PostBundleRequestParams{
  bundleID: number
}

export interface PutBundleErrorResponse {
  error: Error
  requestParams: PutBundleRequestParams
}

export interface PostLoyaltyBundleRequestParams {
  name: string
  description: string
  timeLimitDays: number
  requiredProductQuantities: ProductIDQuantity[]
  awardValidityDays: number
  awardedProductQuantities: ProductIDQuantity[]
}

export interface PostLoyaltyBundleErrorResponse {
  error: Error
  requestParams: PostLoyaltyBundleRequestParams
}

export interface PutLoyaltyBundleRequestParams extends PostLoyaltyBundleRequestParams{
  bundleID: number
}

export interface PutLoyaltyBundleErrorResponse {
  error: Error
  requestParams: PutLoyaltyBundleRequestParams
}