export default class Merchant {
  id: number
  name: string

  constructor(json: MerchantJSON) {
    this.id = json.id
    this.name = json.name
  }

  get [Symbol.toStringTag]() {
    return 'Merchant'
  }
}

export interface MerchantJSON {
  id: number
  name: string
}
