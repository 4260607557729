import { Money } from 'ts-money'
import Product, { ProductJSON } from '../model/Product'
import PaginationInfo from '../model/PaginationInfo'
import ProductVariant, { ProductVariantJSON } from '../model/ProductVariant'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getProducts = async (sorting: string = 'id', page: number = 1, limit: number = 30, search: string): Promise<ProductsResponse> => {

  // Build request
  const url = urlForEndpoint(`products`, {
    sorting,
    page,
    limit,
    search,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { products: productsJSON, paginationInfo } = await parseResponse(response)

    let products = productsJSON.map((productJSON: ProductJSON) => new Product(productJSON))

    return {
      products,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getProductByID = async (productID: number): Promise<Product> => {

  // Build request
  const url = urlForEndpoint(`products/${productID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product } = await parseResponse(response)
    return new Product(product as ProductJSON)
  } catch (err) {
    throw new LoopError(err, { productID })
  }

}

export const putProduct = async (productID: number, name: string, description: string, categoryID: number, normalPrice: Money, memberPrice: Money, branchVisibility: number[], primaryAttribute: string | null, secondaryAttribute: string | null): Promise<Product> => {
  // Build request
  const url = urlForEndpoint(`products/${productID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    description,
    categoryID,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
    primaryAttribute,
    secondaryAttribute,
    branchVisibility,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product } = await parseResponse(response)
    return new Product(product as ProductJSON)
  } catch (err) {
    throw new LoopError(err, {
      productID,
      name,
      description,
      categoryID,
      normalPrice,
      memberPrice,
      primaryAttribute,
      secondaryAttribute,
    })
  }
}

export const deleteProduct = async (productID: number): Promise<Product> => {
  // Build request
  const url = urlForEndpoint(`products/${productID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product } = await parseResponse(response)
    return new Product(product as ProductJSON)
  } catch (err) {
    throw new LoopError(err, { productID })
  }
}

export const postProduct = async (name: string, description: string, categoryID: number, normalPrice: Money, memberPrice: Money, branchVisibility: number[], primaryAttribute: string | null, secondaryAttribute: string | null): Promise<Product> => {
  // Build request
  const url = urlForEndpoint(`products`)

  const request = newRequest(HTTPMethods.POST, token())

  request.body = JSON.stringify({
    name,
    description,
    categoryID,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
    primaryAttribute,
    secondaryAttribute,
    branchVisibility
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product } = await parseResponse(response)
    return new Product(product as ProductJSON)
  } catch (err) {
    throw new LoopError(err, { name, description, categoryID, normalPrice, memberPrice, primaryAttribute, secondaryAttribute })
  }
}

export const postProductVariant = async (productID: number, primaryAttributeValue: string, secondaryAttributeValue: string | null, normalPrice: Money, memberPrice: Money): Promise<ProductVariantResponse> => {
  // Build request
  const url = urlForEndpoint(`products/${productID}/variants`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    primaryAttributeValue,
    secondaryAttributeValue,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product: productJSON, variant: productVariantJSON } = await parseResponse(response)
    return {
      product: new Product(productJSON as ProductJSON),
      variant: new ProductVariant(productVariantJSON as ProductVariantJSON),
    }
  } catch (err) {
    throw err
  }
}

export const putProductVariant = async (productID: number, variantID: number, primaryAttributeValue: string, secondaryAttributeValue: string | null, normalPrice: Money, memberPrice: Money): Promise<ProductVariantResponse> => {
  // Build request
  const url = urlForEndpoint(`products/${productID}/variants/${variantID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    primaryAttributeValue,
    secondaryAttributeValue,
    normalPrice: normalPrice.amount,
    memberPrice: memberPrice.amount,
  })

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product: productJSON, variant: productVariantJSON } = await parseResponse(response)
    return {
      product: new Product(productJSON as ProductJSON),
      variant: new ProductVariant(productVariantJSON as ProductVariantJSON),
    }
  } catch (err) {
    throw err
  }
}


export const deleteProductVariant = async (productID: number, variantID: number): Promise<ProductVariantResponse> => {
  // Build request
  const url = urlForEndpoint(`products/${productID}/variants/${variantID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { product: productJSON, variant: productVariantJSON } = await parseResponse(response)
    return {
      product: new Product(productJSON as ProductJSON),
      variant: new ProductVariant(productVariantJSON as ProductVariantJSON),
    }
  } catch (err) {
    throw err
  }
}

export interface ProductVariantResponse {
  index?: number
  product: Product
  variant: ProductVariant
}

export interface ProductsResponse {
  products: Product[]
  paginationInfo: PaginationInfo
  requestParams: ProductsRequestParams
}

export interface ProductsErrorResponse {
  error: Error
  requestParams: ProductsRequestParams
}

export interface ProductsRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
}

export interface ProductByIDRequestParams {
  productID: number
}

export interface ProductByIDErrorResponse {
  error: Error
  requestParams: ProductByIDRequestParams
}

export interface PostProductRequestParams {
  name: string
  description: string
  categoryID: number
  normalPrice: Money
  memberPrice: Money
  branchVisibility: number[]
  primaryAttribute: string | null
  secondaryAttribute: string | null
}

export interface PostProductErrorResponse {
  error: Error
  requestParams: PostProductRequestParams
}

export interface PutProductRequestParams extends PostProductRequestParams {
  productID: number
}

export interface PutProductErrorResponse {
  error: Error
  requestParams: PutProductRequestParams
}

export interface PostProductVariantRequestParams {
  index: number
  productID: number
  primaryAttributeValue: string
  secondaryAttributeValue: string | null
  normalPrice: Money
  memberPrice: Money
}

export interface PostProductVariantErrorResponse {
  error: Error
  requestParams: PostProductVariantRequestParams
}

export interface PutProductVariantRequestParams extends PostProductVariantRequestParams {
  variantID: number
}

export interface PutProductVariantErrorResponse {
  error: Error
  requestParams: PutProductVariantRequestParams
}