import { push } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Promo from '../../model/Promo'
import {
  CreateLoyaltyPromoAction,
  CreatePurchasePromoAction,
  DeletePromoAction,
  FetchPromoByIDAction,
  FetchPromosAction,
  PromosActions, RejectCreateLoyaltyPromoAction,
  rejectCreatePurchasePromo,
  RejectCreatePurchasePromoAction,
  rejectDeletePromo,
  RejectDeletePromoAction,
  rejectFetchPromoByID,
  RejectFetchPromoByIDAction,
  rejectFetchPromos,
  RejectFetchPromosAction, rejectUpdateLoyaltyPromo, RejectUpdateLoyaltyPromoAction,
  rejectUpdatePurchasePromo,
  RejectUpdatePurchasePromoAction, ResolveCreateLoyaltyPromoAction,
  resolveCreatePurchasePromo,
  ResolveCreatePurchasePromoAction,
  resolveDeletePromo,
  ResolveDeletePromoAction,
  resolveFetchPromoByID,
  ResolveFetchPromoByIDAction,
  resolveFetchPromos,
  ResolveFetchPromosAction, resolveUpdateLoyaltyPromo, ResolveUpdateLoyaltyPromoAction,
  resolveUpdatePurchasePromo,
  ResolveUpdatePurchasePromoAction, UpdateLoyaltyPromoAction,
  UpdatePurchasePromoAction,
} from '../actions/promos'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForPromoPage } from '../selectors/promos'

interface IDMappedPromos {
  [id: number]: Promo
}

interface PromosReducerState {
  byID: IDMappedPromos
  pages: { [hash: string]: PromosReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PromosReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PromosReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: PromosReducerState = initialState, action: PromosActions): PromosReducerState | Loop<PromosReducerState> => {
  switch (action.type) {
    case actionTypes.FETCH_PROMOS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPromosAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new PromosReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForPromoPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getPromos, {
              successActionCreator: resolveFetchPromos,
              failActionCreator:    rejectFetchPromos,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPromosAction
          const { promos, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForPromoPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       promos.map((promo: Promo) => promo.id),
            paginationInfo: paginationInfo,
          }

          // Map promo ids to promos
          let idMappedPromos: IDMappedPromos = {}
          promos.forEach(promo => {
            idMappedPromos[promo.id] = promo
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedPromos),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchPromosAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForPromoPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PROMO_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPromoByIDAction
          const { promoID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [promoID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [promoID]: null,
              },
            },
            Cmd.run(API.getPromoByID, {
              successActionCreator: resolveFetchPromoByID,
              failActionCreator:    rejectFetchPromoByID,
              args:                 [promoID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPromoByIDAction
          const { promo } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [promo.id]: promo,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [promo.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPromoByIDAction
          const { error, requestParams } = payload
          const { promoID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [promoID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [promoID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PURCHASE_PROMO: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdatePurchasePromoAction
          const { promoID, name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [promoID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [promoID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putPurchasePromo, {
              successActionCreator: resolveUpdatePurchasePromo,
              failActionCreator:    rejectUpdatePurchasePromo,
              args:                 [promoID, name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdatePurchasePromoAction
          const { promo } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [promo.id]: promo,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [promo.id]: false,
              },
              successFlashMessage: 'Promo successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ])
          )
        }

        case false: {
          const { payload } = action as RejectUpdatePurchasePromoAction
          const { error, requestParams } = payload
          const { promoID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [promoID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [promoID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PROMO: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeletePromoAction
          const { promoID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [promoID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [promoID]: null,
              },
            },
            Cmd.run(API.deletePromo, {
              successActionCreator: resolveDeletePromo,
              failActionCreator:    rejectDeletePromo,
              args:                 [promoID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeletePromoAction
          const { promo } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[promo.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [promo.id]: false,
              },
              successFlashMessage: 'Promo successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/promos')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeletePromoAction
          const { error, requestParams } = payload
          const { promoID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [promoID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [promoID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PURCHASE_PROMO: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreatePurchasePromoAction
          const { name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postPurchasePromo, {
              successActionCreator: resolveCreatePurchasePromo,
              failActionCreator:    rejectCreatePurchasePromo,
              args:                 [name, description, normalPrice, memberPrice, awardValidityDays, awardedProductQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreatePurchasePromoAction
          const { promo } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [promo.id]: promo,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Promo successfully created!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreatePurchasePromoAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_LOYALTY_PROMO: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateLoyaltyPromoAction
          const { name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postLoyaltyPromo, {
              successActionCreator: resolveCreatePurchasePromo,
              failActionCreator:    rejectCreatePurchasePromo,
              args:                 [name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateLoyaltyPromoAction
          const { promo } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [promo.id]: promo,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Promo successfully created!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateLoyaltyPromoAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_LOYALTY_PROMO: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateLoyaltyPromoAction
          const { promoID, name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [promoID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [promoID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putLoyaltyPromo, {
              successActionCreator: resolveUpdateLoyaltyPromo,
              failActionCreator:    rejectUpdateLoyaltyPromo,
              args:                 [promoID, name, description, timeLimitDays, invoicesRequired, selectedProductIDs, awardValidityDays, awardedProductQuantities],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateLoyaltyPromoAction
          const { promo } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [promo.id]: promo,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [promo.id]: false,
              },
              successFlashMessage: 'Promo successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ])
          )
        }

        case false: {
          const { payload } = action as RejectUpdateLoyaltyPromoAction
          const { error, requestParams } = payload
          const { promoID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [promoID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [promoID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

  // Default
  return state
}
