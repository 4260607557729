import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  CustomerByIDErrorResponse, CustomerIDsResponse,
  CustomersErrorResponse,
  CustomersRequestParams,
  CustomersResponse, CustomerStatsResponse,
  PostCustomerErrorResponse,
  PostCustomerRequestParams,
  PutCustomerErrorResponse,
  PutCustomerRequestParams,
} from '../../api/customers'
import Customer, { CustomerFilters, CustomerStats } from '../../model/Customer'
import PaginationInfo from '../../model/PaginationInfo'
import ProductCreditBalance from '../../model/ProductCreditBalance'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface SelectAllCustomersOnPageAction {
  type: typeof types.SELECT_ALL_ON_PAGE
  payload: CustomersRequestParams
}

export interface SelectAllCustomersAction {
  type: typeof types.SELECT_ALL
  success: undefined
  payload: CustomersRequestParams
}

export interface ResolveSelectAllCustomersAction {
  type: typeof types.SELECT_ALL
  success: boolean
  payload: {
    customerIDs: number[]
    requestParams: CustomersRequestParams
  }
}

export interface RejectSelectAllCustomersAction {
  type: typeof types.SELECT_ALL
  success: boolean
  payload: {
    error: Error
    requestParams: CustomersRequestParams
  }
}

export interface DeselectAllCustomersAction {
  type: typeof types.DESELECT_ALL
}

export interface ToggleSelectCustomerByID {
  type: typeof types.TOGGLE_SELECTION_BY_ID
  payload: number
}

export interface FetchCustomersAction {
  type: typeof types.FETCH_CUSTOMERS
  success: undefined
  payload: CustomersRequestParams
}

export interface ResolveFetchCustomersAction {
  type: typeof types.FETCH_CUSTOMERS
  success: boolean
  payload: {
    customers: Customer[]
    paginationInfo: PaginationInfo
    requestParams: CustomersRequestParams
  }
}

export interface RejectFetchCustomersAction {
  type: typeof types.FETCH_CUSTOMERS
  success: boolean
  payload: {
    error: Error
    requestParams: CustomersRequestParams
  }
}

export interface FetchCustomerByIDAction {
  type: typeof types.FETCH_CUSTOMER_BY_ID
  success: undefined
  payload: {
    customerID: number
  }
}

export interface ResolveFetchCustomerByIDAction {
  type: typeof types.FETCH_CUSTOMER_BY_ID
  success: boolean
  payload: {
    customer: Customer
  }
}

export interface RejectFetchCustomerByIDAction {
  type: typeof types.FETCH_CUSTOMER_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      customerID: number
    }
  }
}

export interface FetchCustomerStatsByIDAction {
  type: typeof types.FETCH_CUSTOMER_STATS_BY_ID
  success: undefined
  payload: {
    customerID: number
  }
}

export interface ResolveFetchCustomerStatsByIDAction {
  type: typeof types.FETCH_CUSTOMER_STATS_BY_ID
  success: boolean
  payload: CustomerStatsResponse
}

export interface RejectFetchCustomerStatsByIDAction {
  type: typeof types.FETCH_CUSTOMER_STATS_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      customerID: number
    }
  }
}

export interface UpdateCustomerAction {
  type: typeof types.UPDATE_CUSTOMER
  success: undefined
  payload: PutCustomerRequestParams
}

export interface ResolveUpdateCustomerAction {
  type: typeof types.UPDATE_CUSTOMER
  success: boolean
  payload: {
    customer: Customer
  }
}

export interface RejectUpdateCustomerAction {
  type: typeof types.UPDATE_CUSTOMER
  success: boolean
  payload: PutCustomerErrorResponse
}


export interface DeleteCustomerAction {
  type: typeof types.DELETE_CUSTOMER
  success: undefined
  payload: {
    customerID: number
  }
}

export interface ResolveDeleteCustomerAction {
  type: typeof types.DELETE_CUSTOMER
  success: boolean
  payload: {
    customer: Customer
  }
}

export interface RejectDeleteCustomerAction {
  type: typeof types.DELETE_CUSTOMER
  success: boolean
  payload: CustomerByIDErrorResponse
}

export interface CreateCustomerAction {
  type: typeof types.CREATE_CUSTOMER
  success: undefined
  payload: PostCustomerRequestParams
}

export interface ResolveCreateCustomerAction {
  type: typeof types.CREATE_CUSTOMER
  success: boolean
  payload: {
    customer: Customer
  }
}

export interface RejectCreateCustomerAction {
  type: typeof types.CREATE_CUSTOMER
  success: boolean
  payload: PostCustomerErrorResponse
}


export const selectAllCustomersOnPage = (sorting: string, page: number, limit: number, filters: CustomerFilters, search: string): SelectAllCustomersOnPageAction => ({
  type:    types.SELECT_ALL_ON_PAGE,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const selectAllCustomers = (sorting: string, page: number, limit: number, filters: CustomerFilters, search: string): SelectAllCustomersAction => ({
  type:    types.SELECT_ALL,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const deselectAllCustomers = (): DeselectAllCustomersAction => ({
  type: types.DESELECT_ALL,
})

export const toggleCustomerIDSelection = (customerID: number): ToggleSelectCustomerByID => ({
  type:    types.TOGGLE_SELECTION_BY_ID,
  payload: customerID,
})

export const resolveSelectAllCustomers = ({
                                            customerIDs,
                                            requestParams,
                                          }: CustomerIDsResponse): ResolveSelectAllCustomersAction => ({
  type:    types.SELECT_ALL,
  success: true,
  payload: {
    customerIDs,
    requestParams,
  },
})

export const rejectSelectAllCustomers = ({
                                           error,
                                           requestParams,
                                         }: CustomersErrorResponse): RejectSelectAllCustomersAction => ({
  type:    types.SELECT_ALL,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchCustomers = (sorting: string, page: number, limit: number, filters: CustomerFilters, search: string): FetchCustomersAction => ({
  type:    types.FETCH_CUSTOMERS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    filters,
    search,
  },
})

export const resolveFetchCustomers = ({
                                        customers,
                                        paginationInfo,
                                        requestParams,
                                      }: CustomersResponse): ResolveFetchCustomersAction => ({
  type:    types.FETCH_CUSTOMERS,
  success: true,
  payload: {
    customers,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchCustomers = ({ error, requestParams }: CustomersErrorResponse): RejectFetchCustomersAction => ({
  type:    types.FETCH_CUSTOMERS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchCustomerByID = (customerID: number): FetchCustomerByIDAction => ({
  type:    types.FETCH_CUSTOMER_BY_ID,
  success: undefined,
  payload: {
    customerID,
  },
})

export const resolveFetchCustomerByID = (customer: Customer): ResolveFetchCustomerByIDAction => ({
  type:    types.FETCH_CUSTOMER_BY_ID,
  success: true,
  payload: {
    customer,
  },
})

export const rejectFetchCustomerByID = ({
                                          error,
                                          requestParams,
                                        }: CustomerByIDErrorResponse): RejectFetchCustomerByIDAction => ({
  type:    types.FETCH_CUSTOMER_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})


export const fetchCustomerStatsByID = (customerID: number): FetchCustomerStatsByIDAction => ({
  type:    types.FETCH_CUSTOMER_STATS_BY_ID,
  success: undefined,
  payload: {
    customerID,
  },
})

export const resolveFetchCustomerStatsByID = (stats: CustomerStatsResponse): ResolveFetchCustomerStatsByIDAction => ({
  type:    types.FETCH_CUSTOMER_STATS_BY_ID,
  success: true,
  payload: stats,
})

export const rejectFetchCustomerStatsByID = ({
                                               error,
                                               requestParams,
                                             }: CustomerByIDErrorResponse): RejectFetchCustomerStatsByIDAction => ({
  type:    types.FETCH_CUSTOMER_STATS_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateCustomer = (customerID: number, name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): UpdateCustomerAction => ({
  type:    types.UPDATE_CUSTOMER,
  success: undefined,
  payload: {
    customerID,
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  },
})

export const resolveUpdateCustomer = (customer: Customer): ResolveUpdateCustomerAction => ({
  type:    types.UPDATE_CUSTOMER,
  success: true,
  payload: {
    customer,
  },
})

export const rejectUpdateCustomer = (payload: PutCustomerErrorResponse): RejectUpdateCustomerAction => ({
  type:    types.UPDATE_CUSTOMER,
  success: false,
  payload,
})


export const deleteCustomer = (customerID: number): DeleteCustomerAction => ({
  type:    types.DELETE_CUSTOMER,
  success: undefined,
  payload: {
    customerID,
  },
})

export const resolveDeleteCustomer = (customer: Customer): ResolveDeleteCustomerAction => ({
  type:    types.DELETE_CUSTOMER,
  success: true,
  payload: {
    customer,
  },
})

export const rejectDeleteCustomer = (payload: CustomerByIDErrorResponse): RejectDeleteCustomerAction => ({
  type:    types.DELETE_CUSTOMER,
  success: false,
  payload,
})


export const createCustomer = (name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): CreateCustomerAction => ({
  type:    types.CREATE_CUSTOMER,
  success: undefined,
  payload: {
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  },
})

export const resolveCreateCustomer = (customer: Customer): ResolveCreateCustomerAction => ({
  type:    types.CREATE_CUSTOMER,
  success: true,
  payload: {
    customer,
  },
})

export const rejectCreateCustomer = (payload: PostCustomerErrorResponse): RejectCreateCustomerAction => ({
  type:    types.CREATE_CUSTOMER,
  success: false,
  payload,
})

export type CustomersActions =
  FetchCustomersAction
  | RejectFetchCustomersAction
  | ResolveFetchCustomersAction
  | FetchCustomerByIDAction
  | ResolveFetchCustomerByIDAction
  | RejectFetchCustomerByIDAction
  | FetchCustomerStatsByIDAction
  | ResolveFetchCustomerStatsByIDAction
  | RejectFetchCustomerStatsByIDAction
  | UpdateCustomerAction
  | RejectUpdateCustomerAction
  | ResolveUpdateCustomerAction
  | CreateCustomerAction
  | ResolveCreateCustomerAction
  | RejectCreateCustomerAction
  | DeleteCustomerAction
  | ResolveDeleteCustomerAction
  | RejectDeleteCustomerAction
  | ClearFlashMessagesAction
  | SelectAllCustomersAction
  | ResolveSelectAllCustomersAction
  | RejectSelectAllCustomersAction
  | DeselectAllCustomersAction
  | ToggleSelectCustomerByID
  | SelectAllCustomersOnPageAction
  | LocationChangeAction
