import {
  CategoriesErrorResponse,
  CategoriesRequestParams,
  CategoriesResponse,
  CategoryByIDErrorResponse,
  PostCategoryErrorResponse,
  PostCategoryRequestParams,
  PutCategoryErrorResponse,
  PutCategoryRequestParams,
} from '../../api/categories'
import Category from '../../model/Category'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'
import {
  ResolveCreateProductAction,
  ResolveDeleteProductAction,
  ResolveUpdateProductAction,
} from './products'

export interface FetchCategoriesAction {
  type: typeof types.FETCH_CATEGORIES
  success: undefined
  payload: CategoriesRequestParams
}

export interface ResolveFetchCategoriesAction {
  type: typeof types.FETCH_CATEGORIES
  success: boolean
  payload: {
    categories: Category[]
    paginationInfo: PaginationInfo
    requestParams: CategoriesRequestParams
  }
}

export interface RejectFetchCategoriesAction {
  type: typeof types.FETCH_CATEGORIES
  success: boolean
  payload: {
    error: Error
    requestParams: CategoriesRequestParams
  }
}

export interface FetchCategoryByIDAction {
  type: typeof types.FETCH_CATEGORY_BY_ID
  success: undefined
  payload: {
    categoryID: number
  }
}

export interface ResolveFetchCategoryByIDAction {
  type: typeof types.FETCH_CATEGORY_BY_ID
  success: boolean
  payload: {
    category: Category
  }
}

export interface RejectFetchCategoryByIDAction {
  type: typeof types.FETCH_CATEGORY_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      categoryID: number
    }
  }
}

export interface UpdateCategoryAction {
  type: typeof types.UPDATE_CATEGORY
  success: undefined
  payload: PutCategoryRequestParams
}

export interface ResolveUpdateCategoryAction {
  type: typeof types.UPDATE_CATEGORY
  success: boolean
  payload: {
    category: Category
  }
}

export interface RejectUpdateCategoryAction {
  type: typeof types.UPDATE_CATEGORY
  success: boolean
  payload: PutCategoryErrorResponse
}


export interface DeleteCategoryAction {
  type: typeof types.DELETE_CATEGORY
  success: undefined
  payload: {
    categoryID: number
  }
}

export interface ResolveDeleteCategoryAction {
  type: typeof types.DELETE_CATEGORY
  success: boolean
  payload: {
    category: Category
  }
}

export interface RejectDeleteCategoryAction {
  type: typeof types.DELETE_CATEGORY
  success: boolean
  payload: CategoryByIDErrorResponse
}

export interface CreateCategoryAction {
  type: typeof types.CREATE_CATEGORY
  success: undefined
  payload: PostCategoryRequestParams
}

export interface ResolveCreateCategoryAction {
  type: typeof types.CREATE_CATEGORY
  success: boolean
  payload: {
    category: Category
  }
}

export interface RejectCreateCategoryAction {
  type: typeof types.CREATE_CATEGORY
  success: boolean
  payload: PostCategoryErrorResponse
}

export const fetchCategories = (sorting: string, page: number, limit: number, search: string): FetchCategoriesAction => ({
  type:    types.FETCH_CATEGORIES,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchCategories = ({
                                         categories,
                                         paginationInfo,
                                         requestParams,
                                       }: CategoriesResponse): ResolveFetchCategoriesAction => ({
  type:    types.FETCH_CATEGORIES,
  success: true,
  payload: {
    categories,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchCategories = ({
                                        error,
                                        requestParams,
                                      }: CategoriesErrorResponse): RejectFetchCategoriesAction => ({
  type:    types.FETCH_CATEGORIES,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchCategoryByID = (categoryID: number): FetchCategoryByIDAction => ({
  type:    types.FETCH_CATEGORY_BY_ID,
  success: undefined,
  payload: {
    categoryID,
  },
})

export const resolveFetchCategoryByID = (category: Category): ResolveFetchCategoryByIDAction => ({
  type:    types.FETCH_CATEGORY_BY_ID,
  success: true,
  payload: {
    category,
  },
})

export const rejectFetchCategoryByID = ({
                                          error,
                                          requestParams,
                                        }: CategoryByIDErrorResponse): RejectFetchCategoryByIDAction => ({
  type:    types.FETCH_CATEGORY_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateCategory = (categoryID: number, name: string): UpdateCategoryAction => ({
  type:    types.UPDATE_CATEGORY,
  success: undefined,
  payload: {
    categoryID,
    name,
  },
})

export const resolveUpdateCategory = (category: Category): ResolveUpdateCategoryAction => ({
  type:    types.UPDATE_CATEGORY,
  success: true,
  payload: {
    category,
  },
})

export const rejectUpdateCategory = (payload: PutCategoryErrorResponse): RejectUpdateCategoryAction => ({
  type:    types.UPDATE_CATEGORY,
  success: false,
  payload,
})


export const deleteCategory = (categoryID: number): DeleteCategoryAction => ({
  type:    types.DELETE_CATEGORY,
  success: undefined,
  payload: {
    categoryID,
  },
})

export const resolveDeleteCategory = (category: Category): ResolveDeleteCategoryAction => ({
  type:    types.DELETE_CATEGORY,
  success: true,
  payload: {
    category,
  },
})

export const rejectDeleteCategory = (payload: CategoryByIDErrorResponse): RejectDeleteCategoryAction => ({
  type:    types.DELETE_CATEGORY,
  success: false,
  payload,
})


export const createCategory = (name: string): CreateCategoryAction => ({
  type:    types.CREATE_CATEGORY,
  success: undefined,
  payload: {
    name,
  },
})

export const resolveCreateCategory = (category: Category): ResolveCreateCategoryAction => ({
  type:    types.CREATE_CATEGORY,
  success: true,
  payload: {
    category,
  },
})

export const rejectCreateCategory = (payload: PostCategoryErrorResponse): RejectCreateCategoryAction => ({
  type:    types.CREATE_CATEGORY,
  success: false,
  payload,
})

export type CategoriesActions =
  FetchCategoriesAction
  | RejectFetchCategoriesAction
  | ResolveFetchCategoriesAction
  | FetchCategoryByIDAction
  | ResolveFetchCategoryByIDAction
  | RejectFetchCategoryByIDAction
  | UpdateCategoryAction
  | RejectUpdateCategoryAction
  | ResolveUpdateCategoryAction
  | CreateCategoryAction
  | ResolveCreateCategoryAction
  | RejectCreateCategoryAction
  | DeleteCategoryAction
  | ResolveDeleteCategoryAction
  | RejectDeleteCategoryAction
  | ClearFlashMessagesAction
  // The following actions have effects on the states herein
  | ResolveUpdateProductAction
  | ResolveCreateProductAction
  | ResolveDeleteProductAction

