import { Money } from 'ts-money'
import {
  PostLoyaltyPromoErrorResponse,
  PostLoyaltyPromoRequestParams,
  PostPurchasePromoErrorResponse,
  PostPurchasePromoRequestParams,
  PromoByIDErrorResponse,
  PromosErrorResponse,
  PromosRequestParams,
  PromosResponse, PutLoyaltyPromoErrorResponse, PutLoyaltyPromoRequestParams,
  PutPurchasePromoErrorResponse,
  PutPurchasePromoRequestParams,
} from '../../api/promos'
import PaginationInfo from '../../model/PaginationInfo'
import Promo, { ProductIDQuantity } from '../../model/Promo'
import { CREATE_PURCHASE_PROMO } from '../constants/ActionTypes'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'


export interface FetchPromosAction {
  type: typeof types.FETCH_PROMOS
  success: undefined
  payload: PromosRequestParams
}

export interface ResolveFetchPromosAction {
  type: typeof types.FETCH_PROMOS
  success: boolean
  payload: {
    promos: Promo[]
    paginationInfo: PaginationInfo
    requestParams: PromosRequestParams
  }
}

export interface RejectFetchPromosAction {
  type: typeof types.FETCH_PROMOS
  success: boolean
  payload: {
    error: Error
    requestParams: PromosRequestParams
  }
}

export interface FetchPromoByIDAction {
  type: typeof types.FETCH_PROMO_BY_ID
  success: undefined
  payload: {
    promoID: number
  }
}

export interface ResolveFetchPromoByIDAction {
  type: typeof types.FETCH_PROMO_BY_ID
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectFetchPromoByIDAction {
  type: typeof types.FETCH_PROMO_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      promoID: number
    }
  }
}

export interface UpdatePurchasePromoAction {
  type: typeof types.UPDATE_PURCHASE_PROMO
  success: undefined
  payload: PutPurchasePromoRequestParams
}

export interface ResolveUpdatePurchasePromoAction {
  type: typeof types.UPDATE_PURCHASE_PROMO
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectUpdatePurchasePromoAction {
  type: typeof types.UPDATE_PURCHASE_PROMO
  success: boolean
  payload: PutPurchasePromoErrorResponse
}

export interface UpdateLoyaltyPromoAction {
  type: typeof types.UPDATE_LOYALTY_PROMO
  success: undefined
  payload: PutLoyaltyPromoRequestParams
}

export interface ResolveUpdateLoyaltyPromoAction {
  type: typeof types.UPDATE_LOYALTY_PROMO
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectUpdateLoyaltyPromoAction {
  type: typeof types.UPDATE_LOYALTY_PROMO
  success: boolean
  payload: PutLoyaltyPromoErrorResponse
}


export interface DeletePromoAction {
  type: typeof types.DELETE_PROMO
  success: undefined
  payload: {
    promoID: number
  }
}

export interface ResolveDeletePromoAction {
  type: typeof types.DELETE_PROMO
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectDeletePromoAction {
  type: typeof types.DELETE_PROMO
  success: boolean
  payload: PromoByIDErrorResponse
}

export interface CreatePurchasePromoAction {
  type: typeof types.CREATE_PURCHASE_PROMO
  success: undefined
  payload: PostPurchasePromoRequestParams
}

export interface ResolveCreatePurchasePromoAction {
  type: typeof types.CREATE_PURCHASE_PROMO
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectCreatePurchasePromoAction {
  type: typeof types.CREATE_PURCHASE_PROMO
  success: boolean
  payload: PostPurchasePromoErrorResponse
}


export interface CreateLoyaltyPromoAction {
  type: typeof types.CREATE_LOYALTY_PROMO
  success: undefined
  payload: PostLoyaltyPromoRequestParams
}

export interface ResolveCreateLoyaltyPromoAction {
  type: typeof types.CREATE_LOYALTY_PROMO
  success: boolean
  payload: {
    promo: Promo
  }
}

export interface RejectCreateLoyaltyPromoAction {
  type: typeof types.CREATE_LOYALTY_PROMO
  success: boolean
  payload: PostLoyaltyPromoErrorResponse
}


export const fetchPromos = (sorting: string, page: number, limit: number, search: string): FetchPromosAction => ({
  type:    types.FETCH_PROMOS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchPromos = ({
                                       promos,
                                       paginationInfo,
                                       requestParams,
                                     }: PromosResponse): ResolveFetchPromosAction => ({
  type:    types.FETCH_PROMOS,
  success: true,
  payload: {
    promos,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchPromos = ({ error, requestParams }: PromosErrorResponse): RejectFetchPromosAction => ({
  type:    types.FETCH_PROMOS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchPromoByID = (promoID: number): FetchPromoByIDAction => ({
  type:    types.FETCH_PROMO_BY_ID,
  success: undefined,
  payload: {
    promoID,
  },
})

export const resolveFetchPromoByID = (promo: Promo): ResolveFetchPromoByIDAction => ({
  type:    types.FETCH_PROMO_BY_ID,
  success: true,
  payload: {
    promo,
  },
})

export const rejectFetchPromoByID = ({
                                         error,
                                         requestParams,
                                       }: PromoByIDErrorResponse): RejectFetchPromoByIDAction => ({
  type:    types.FETCH_PROMO_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updatePurchasePromo = (promoID: number, name: string, description: string, normalPrice: Money, memberPrice: Money, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): UpdatePurchasePromoAction => ({
  type:    types.UPDATE_PURCHASE_PROMO,
  success: undefined,
  payload: {
    promoID,
    name,
    description,
    normalPrice,
    memberPrice,
    awardValidityDays,
    awardedProductQuantities,
  },
})

export const resolveUpdatePurchasePromo = (promo: Promo): ResolveUpdatePurchasePromoAction => ({
  type:    types.UPDATE_PURCHASE_PROMO,
  success: true,
  payload: {
    promo,
  },
})

export const rejectUpdatePurchasePromo = (payload: PutPurchasePromoErrorResponse): RejectUpdatePurchasePromoAction => ({
  type:    types.UPDATE_PURCHASE_PROMO,
  success: false,
  payload,
})


export const updateLoyaltyPromo = (promoID: number, name: string, description: string, timeLimitDays: number, invoicesRequired: number, selectedProductIDs: Array<number>, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): UpdateLoyaltyPromoAction => ({
  type:    types.UPDATE_LOYALTY_PROMO,
  success: undefined,
  payload: {
    promoID,
    name,
    description,
    timeLimitDays,
    invoicesRequired,
    selectedProductIDs,
    awardValidityDays,
    awardedProductQuantities,
  },
})

export const resolveUpdateLoyaltyPromo = (promo: Promo): ResolveUpdateLoyaltyPromoAction => ({
  type:    types.UPDATE_LOYALTY_PROMO,
  success: true,
  payload: {
    promo,
  },
})

export const rejectUpdateLoyaltyPromo = (payload: PutLoyaltyPromoErrorResponse): RejectUpdateLoyaltyPromoAction => ({
  type:    types.UPDATE_LOYALTY_PROMO,
  success: false,
  payload,
})


export const deletePromo = (promoID: number): DeletePromoAction => ({
  type:    types.DELETE_PROMO,
  success: undefined,
  payload: {
    promoID,
  },
})

export const resolveDeletePromo = (promo: Promo): ResolveDeletePromoAction => ({
  type:    types.DELETE_PROMO,
  success: true,
  payload: {
    promo,
  },
})

export const rejectDeletePromo = (payload: PromoByIDErrorResponse): RejectDeletePromoAction => ({
  type:    types.DELETE_PROMO,
  success: false,
  payload,
})


export const createPurchasePromo = (name: string, description: string, normalPrice: Money, memberPrice: Money, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): CreatePurchasePromoAction => ({
  type:    types.CREATE_PURCHASE_PROMO,
  success: undefined,
  payload: {
    name,
    description,
    normalPrice,
    memberPrice,
    awardValidityDays,
    awardedProductQuantities,
  },
})

export const resolveCreatePurchasePromo = (promo: Promo): ResolveCreatePurchasePromoAction => ({
  type:    types.CREATE_PURCHASE_PROMO,
  success: true,
  payload: {
    promo,
  },
})

export const rejectCreatePurchasePromo = (payload: PostPurchasePromoErrorResponse): RejectCreatePurchasePromoAction => ({
  type:    types.CREATE_PURCHASE_PROMO,
  success: false,
  payload,
})

export const createLoyaltyPromo = (name: string, description: string, timeLimitDays: number, invoicesRequired: number, selectedProductIDs: Array<number>, awardValidityDays: number, awardedProductQuantities: ProductIDQuantity[]): CreateLoyaltyPromoAction => ({
  type:    types.CREATE_LOYALTY_PROMO,
  success: undefined,
  payload: {
    name,
    description,
    timeLimitDays,
    invoicesRequired,
    selectedProductIDs,
    awardValidityDays,
    awardedProductQuantities,
  },
})

export const resolveCreateLoyaltyPromo = (promo: Promo): ResolveCreateLoyaltyPromoAction => ({
  type:    types.CREATE_LOYALTY_PROMO,
  success: true,
  payload: {
    promo,
  },
})

export const rejectCreateLoyaltyPromo = (payload: PostLoyaltyPromoErrorResponse): RejectCreateLoyaltyPromoAction => ({
  type:    types.CREATE_LOYALTY_PROMO,
  success: false,
  payload,
})

export type PromosActions =
  FetchPromosAction
  | RejectFetchPromosAction
  | ResolveFetchPromosAction
  | FetchPromoByIDAction
  | ResolveFetchPromoByIDAction
  | RejectFetchPromoByIDAction
  | UpdatePurchasePromoAction
  | RejectUpdatePurchasePromoAction
  | ResolveUpdatePurchasePromoAction
  | CreatePurchasePromoAction
  | ResolveCreatePurchasePromoAction
  | RejectCreatePurchasePromoAction
  | DeletePromoAction
  | ResolveDeletePromoAction
  | RejectDeletePromoAction
  | ClearFlashMessagesAction
  | CreateLoyaltyPromoAction
  | ResolveCreateLoyaltyPromoAction
  | RejectCreateLoyaltyPromoAction
  | UpdateLoyaltyPromoAction
  | RejectUpdateLoyaltyPromoAction
  | ResolveUpdateLoyaltyPromoAction
  
  