import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  EmployeeByIDErrorResponse, EmployeeIDsResponse,
  EmployeesErrorResponse,
  EmployeesRequestParams,
  EmployeesResponse,
  PostEmployeeErrorResponse,
  PostEmployeeRequestParams,
  PutEmployeeErrorResponse,
  PutEmployeeRequestParams,
} from '../../api/employees'
import Employee from '../../model/Employee'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchEmployeesAction {
  type: typeof types.FETCH_EMPLOYEES
  success: undefined
  payload: EmployeesRequestParams
}

export interface ResolveFetchEmployeesAction {
  type: typeof types.FETCH_EMPLOYEES
  success: boolean
  payload: {
    employees: Employee[]
    paginationInfo: PaginationInfo
    requestParams: EmployeesRequestParams
  }
}

export interface RejectFetchEmployeesAction {
  type: typeof types.FETCH_EMPLOYEES
  success: boolean
  payload: {
    error: Error
    requestParams: EmployeesRequestParams
  }
}

export interface FetchEmployeeByIDAction {
  type: typeof types.FETCH_EMPLOYEE_BY_ID
  success: undefined
  payload: {
    employeeID: number
  }
}

export interface ResolveFetchEmployeeByIDAction {
  type: typeof types.FETCH_EMPLOYEE_BY_ID
  success: boolean
  payload: {
    employee: Employee
  }
}

export interface RejectFetchEmployeeByIDAction {
  type: typeof types.FETCH_EMPLOYEE_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      employeeID: number
    }
  }
}

export interface UpdateEmployeeAction {
  type: typeof types.UPDATE_EMPLOYEE
  success: undefined
  payload: PutEmployeeRequestParams
}

export interface ResolveUpdateEmployeeAction {
  type: typeof types.UPDATE_EMPLOYEE
  success: boolean
  payload: {
    employee: Employee
  }
}

export interface RejectUpdateEmployeeAction {
  type: typeof types.UPDATE_EMPLOYEE
  success: boolean
  payload: PutEmployeeErrorResponse
}


export interface DeleteEmployeeAction {
  type: typeof types.DELETE_EMPLOYEE
  success: undefined
  payload: {
    employeeID: number
  }
}

export interface ResolveDeleteEmployeeAction {
  type: typeof types.DELETE_EMPLOYEE
  success: boolean
  payload: {
    employee: Employee
  }
}

export interface RejectDeleteEmployeeAction {
  type: typeof types.DELETE_EMPLOYEE
  success: boolean
  payload: EmployeeByIDErrorResponse
}

export interface CreateEmployeeAction {
  type: typeof types.CREATE_EMPLOYEE
  success: undefined
  payload: PostEmployeeRequestParams
}

export interface ResolveCreateEmployeeAction {
  type: typeof types.CREATE_EMPLOYEE
  success: boolean
  payload: {
    employee: Employee
  }
}

export interface RejectCreateEmployeeAction {
  type: typeof types.CREATE_EMPLOYEE
  success: boolean
  payload: PostEmployeeErrorResponse
}

export const fetchEmployees = (sorting: string, page: number, limit: number, search: string): FetchEmployeesAction => ({
  type:    types.FETCH_EMPLOYEES,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchEmployees = ({
                                        employees,
                                        paginationInfo,
                                        requestParams,
                                      }: EmployeesResponse): ResolveFetchEmployeesAction => ({
  type:    types.FETCH_EMPLOYEES,
  success: true,
  payload: {
    employees,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchEmployees = ({ error, requestParams }: EmployeesErrorResponse): RejectFetchEmployeesAction => ({
  type:    types.FETCH_EMPLOYEES,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchEmployeeByID = (employeeID: number): FetchEmployeeByIDAction => ({
  type:    types.FETCH_EMPLOYEE_BY_ID,
  success: undefined,
  payload: {
    employeeID,
  },
})

export const resolveFetchEmployeeByID = (employee: Employee): ResolveFetchEmployeeByIDAction => ({
  type:    types.FETCH_EMPLOYEE_BY_ID,
  success: true,
  payload: {
    employee,
  },
})

export const rejectFetchEmployeeByID = ({
                                          error,
                                          requestParams,
                                        }: EmployeeByIDErrorResponse): RejectFetchEmployeeByIDAction => ({
  type:    types.FETCH_EMPLOYEE_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateEmployee = (employeeID: number, name: string, icNumber: string, phoneNumber: string | null, emailAddress: string | null): UpdateEmployeeAction => ({
  type:    types.UPDATE_EMPLOYEE,
  success: undefined,
  payload: {
    employeeID,
    name,
    icNumber,
    phoneNumber,
    emailAddress,
  },
})

export const resolveUpdateEmployee = (employee: Employee): ResolveUpdateEmployeeAction => ({
  type:    types.UPDATE_EMPLOYEE,
  success: true,
  payload: {
    employee,
  },
})

export const rejectUpdateEmployee = (payload: PutEmployeeErrorResponse): RejectUpdateEmployeeAction => ({
  type:    types.UPDATE_EMPLOYEE,
  success: false,
  payload,
})


export const deleteEmployee = (employeeID: number): DeleteEmployeeAction => ({
  type:    types.DELETE_EMPLOYEE,
  success: undefined,
  payload: {
    employeeID,
  },
})

export const resolveDeleteEmployee = (employee: Employee): ResolveDeleteEmployeeAction => ({
  type:    types.DELETE_EMPLOYEE,
  success: true,
  payload: {
    employee,
  },
})

export const rejectDeleteEmployee = (payload: EmployeeByIDErrorResponse): RejectDeleteEmployeeAction => ({
  type:    types.DELETE_EMPLOYEE,
  success: false,
  payload,
})


export const createEmployee = (name: string, icNumber: string,  phoneNumber: string | null, emailAddress: string | null): CreateEmployeeAction => ({
  type:    types.CREATE_EMPLOYEE,
  success: undefined,
  payload: {
    name,
    icNumber,
    phoneNumber,
    emailAddress,
  },
})

export const resolveCreateEmployee = (employee: Employee): ResolveCreateEmployeeAction => ({
  type:    types.CREATE_EMPLOYEE,
  success: true,
  payload: {
    employee,
  },
})

export const rejectCreateEmployee = (payload: PostEmployeeErrorResponse): RejectCreateEmployeeAction => ({
  type:    types.CREATE_EMPLOYEE,
  success: false,
  payload,
})

export type EmployeesActions =
  FetchEmployeesAction
  | RejectFetchEmployeesAction
  | ResolveFetchEmployeesAction
  | FetchEmployeeByIDAction
  | ResolveFetchEmployeeByIDAction
  | RejectFetchEmployeeByIDAction
  | UpdateEmployeeAction
  | RejectUpdateEmployeeAction
  | ResolveUpdateEmployeeAction
  | CreateEmployeeAction
  | ResolveCreateEmployeeAction
  | RejectCreateEmployeeAction
  | DeleteEmployeeAction
  | ResolveDeleteEmployeeAction
  | RejectDeleteEmployeeAction
  | ClearFlashMessagesAction
  | LocationChangeAction
