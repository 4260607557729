import QueryString from 'querystring'
import { CustomerFilters } from '../model/Customer'

let paramsFromSearchQuery = (searchQuery: string): Record<string, any> => {
  // Ignore the `?` part of the search string e.g. ?username=codejockie
  const search = searchQuery.substring(1)
  // Split the query string on `&` e.g. ?username=codejockie&name=Kennedy
  const queries = search.split('&')

  // Contruct query object
  let builtQuery: Record<string, any> = {}
  queries.forEach(query => {
    if (query == "") return

    // Split on `=`, to get key and value
    let [queryKey, queryValue] = query.split('=')
    if (queryValue == "") return

    let cleanedValue = queryValue
    if (queryKey == "filters" ) {
      cleanedValue = decodeURIComponent(queryValue)
    }
    builtQuery[queryKey] = cleanedValue
  })

  return builtQuery
}

let searchQueryFromParams = (page: number, limit: number, order: string, filters: Object, search: string ) => {
  let params: Record<string, any> = {
    page,
    limit,
    order,
    search,
    filters: JSON.stringify(filters) as string
  }

  // Remove undefined values
  Object.keys(params).forEach(key => {
    if (params[key] === null) {
      delete params[key]
    }
  })

  // Stringify that shit
  return QueryString.stringify(params)
}

export { paramsFromSearchQuery }