import {
  Alert,
  alpha,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  lighten,
  TextField,
  Typography,
} from '@mui/material'
import React, { Component, FormEvent } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { bindActionCreators, Dispatch } from 'redux'
import { RootReducerState } from '../../store'
import { submitLoginForm } from '../../store/actions/authentication'

class LoginPage extends Component<IProps, IState> {
  private theme: any

  constructor(props: IProps) {
    super(props)

    this.state = {
      emailAddress: '',
      password:     '',
      rememberMe:   false,
    }
  }

  render() {
    let { emailAddress, password, rememberMe } = this.state

    let { error, isAuthenticating, theme } = this.props

    let isValidIsh = emailAddress.length > 5 && password.length >= 8


    return (
      <Box
        sx={{
          flex:   1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
                          theme.palette.mode === 'dark'
                            ? theme.colors.alpha.trueWhite[5]
                            : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
                          theme.palette.mode === 'dark'
                            ? `0 1px 0 ${alpha(
                              lighten(theme.colors.primary.main, 0.7),
                              0.15,
                            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                            : `0px 2px 4px -3px ${alpha(
                              theme.colors.alpha.black[100],
                              0.1,
                            )}, 0px 5px 12px -4px ${alpha(
                              theme.colors.alpha.black[100],
                              0.05,
                            )}`,
          },
        }}
      >

        <Container maxWidth="sm" sx={{ pt: 15 }}>
          <Card sx={{ p: 5 }}>
            <Typography variant="h1"
                        color="text.primary"
                        component="h1"
                        gutterBottom
                        fontWeight={500}>
              Sign In
            </Typography>
            <Typography variant="h4" component="div" color="text.secondary" fontSize={20} fontWeight={300} noWrap>
              Welcome back!
            </Typography>

            <Box component="form" onSubmit={this.handleSubmit}>
              <TextField
                required
                id="email"
                label="Email Address"
                value={emailAddress}
                onChange={(e) => {
                  this.setState({ emailAddress: e.target.value })
                }}
                fullWidth
                sx={{ mt: 5, mb: 2 }}
              />

              <TextField
                required
                id="password"
                label="Password"
                type="password"
                value={password}
                onChange={(e) => {
                  this.setState({ password: e.target.value })
                }}
                fullWidth
                sx={{ mb: 2 }}
              />

              {error &&
              <Alert color="error">The email address or password you have entered is incorrect</Alert>
              }
              {isAuthenticating &&
              <Box textAlign="center">
                <CircularProgress/>
              </Box>
              }

              <Button type="submit" size="large" color="primary" variant="contained" fullWidth disabled={isAuthenticating}
                      sx={{ mt: 5, height: '60px' }}>
                Log in
              </Button>

            </Box>

          </Card>
        </Container>


      </Box>
    )
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let { emailAddress, password, rememberMe } = this.state
    this.props.actions.submitLoginForm(emailAddress, password)
  }
}

const mapStateToProps = (state: RootReducerState) => {
  return {
    error:            state.authentication.error,
    isAuthenticating: state.authentication.isAuthenticating,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators({
    submitLoginForm,
  }, dispatch),
})

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

type IProps = PropsFromRedux & {
  theme: any
}

interface IState {
  emailAddress: string
  password: string
  rememberMe: boolean
}

export default connector(LoginPage)
