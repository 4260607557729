import { Money } from 'ts-money'
import Product, { ProductJSON } from '../model/Product'
import PaginationInfo from '../model/PaginationInfo'
import ProductCreditBalance, { ProductCreditBalanceJSON } from '../model/ProductCreditBalance'
import ProductVariant, { ProductVariantJSON } from '../model/ProductVariant'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getProductCreditBalancesForMerchant = async (): Promise<ProductCreditBalance[]> => {

  // Build request
  const url = urlForEndpoint(`product-credits/balances`, {})
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { balances: balancesJSON } = await parseResponse(response)

    return balancesJSON.map((json: ProductCreditBalanceJSON) => new ProductCreditBalance(json))

  } catch (err) {
    throw new LoopError(err, {})
  }
}
