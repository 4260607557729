export default class ProductCreditBalance {
  variantID?: number
  productID: number
  amount: number

  constructor(json: ProductCreditBalanceJSON) {
    this.amount = json.amount
    this.productID = json.productID
    this.variantID = json.variantID
  }

  get [Symbol.toStringTag]() {
    return 'ProductCreditBalance'
  }
}

export interface ProductCreditBalanceJSON {
  variantID?: number
  productID: number
  amount: number
}
