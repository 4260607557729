import { LocationChangeAction } from '@lagunovsky/redux-react-router'
import {
  ProductCreditTransactionByIDErrorResponse,
  ProductCreditTransactionsErrorResponse,
  ProductCreditTransactionsRequestParams,
  ProductCreditTransactionsResponse,
  PostProductCreditTransactionErrorResponse,
  PostProductCreditTransactionRequestParams,
  PutProductCreditTransactionErrorResponse,
  PutProductCreditTransactionRequestParams,
} from '../../api/productCreditTransactions'
import ProductCreditTransaction from '../../model/ProductCreditTransaction'
import PaginationInfo from '../../model/PaginationInfo'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'

export interface FetchProductCreditTransactionsAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTIONS
  success: undefined
  payload: ProductCreditTransactionsRequestParams
}

export interface ResolveFetchProductCreditTransactionsAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTIONS
  success: boolean
  payload: {
    productCreditTransactions: ProductCreditTransaction[]
    paginationInfo: PaginationInfo
    requestParams: ProductCreditTransactionsRequestParams
  }
}

export interface RejectFetchProductCreditTransactionsAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTIONS
  success: boolean
  payload: {
    error: Error
    requestParams: ProductCreditTransactionsRequestParams
  }
}

export interface FetchProductCreditTransactionByIDAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID
  success: undefined
  payload: {
    productCreditTransactionID: number
  }
}

export interface ResolveFetchProductCreditTransactionByIDAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID
  success: boolean
  payload: {
    productCreditTransaction: ProductCreditTransaction
  }
}

export interface RejectFetchProductCreditTransactionByIDAction {
  type: typeof types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      productCreditTransactionID: number
    }
  }
}

export interface UpdateProductCreditTransactionAction {
  type: typeof types.UPDATE_PRODUCT_CREDIT_TRANSACTION
  success: undefined
  payload: PutProductCreditTransactionRequestParams
}

export interface ResolveUpdateProductCreditTransactionAction {
  type: typeof types.UPDATE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: {
    productCreditTransaction: ProductCreditTransaction
  }
}

export interface RejectUpdateProductCreditTransactionAction {
  type: typeof types.UPDATE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: PutProductCreditTransactionErrorResponse
}


export interface DeleteProductCreditTransactionAction {
  type: typeof types.DELETE_PRODUCT_CREDIT_TRANSACTION
  success: undefined
  payload: {
    productCreditTransactionID: number
  }
}

export interface ResolveDeleteProductCreditTransactionAction {
  type: typeof types.DELETE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: {
    productCreditTransaction: ProductCreditTransaction
  }
}

export interface RejectDeleteProductCreditTransactionAction {
  type: typeof types.DELETE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: ProductCreditTransactionByIDErrorResponse
}

export interface CreateProductCreditTransactionAction {
  type: typeof types.CREATE_PRODUCT_CREDIT_TRANSACTION
  success: undefined
  payload: PostProductCreditTransactionRequestParams
}

export interface ResolveCreateProductCreditTransactionAction {
  type: typeof types.CREATE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: {
    productCreditTransaction: ProductCreditTransaction
  }
}

export interface RejectCreateProductCreditTransactionAction {
  type: typeof types.CREATE_PRODUCT_CREDIT_TRANSACTION
  success: boolean
  payload: PostProductCreditTransactionErrorResponse
}

export const fetchProductCreditTransactions = (sorting: string, page: number, limit: number, search: string): FetchProductCreditTransactionsAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTIONS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchProductCreditTransactions = ({
                                        productCreditTransactions,
                                        paginationInfo,
                                        requestParams,
                                      }: ProductCreditTransactionsResponse): ResolveFetchProductCreditTransactionsAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTIONS,
  success: true,
  payload: {
    productCreditTransactions,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchProductCreditTransactions = ({ error, requestParams }: ProductCreditTransactionsErrorResponse): RejectFetchProductCreditTransactionsAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTIONS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchProductCreditTransactionByID = (productCreditTransactionID: number): FetchProductCreditTransactionByIDAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID,
  success: undefined,
  payload: {
    productCreditTransactionID,
  },
})

export const resolveFetchProductCreditTransactionByID = (productCreditTransaction: ProductCreditTransaction): ResolveFetchProductCreditTransactionByIDAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID,
  success: true,
  payload: {
    productCreditTransaction,
  },
})

export const rejectFetchProductCreditTransactionByID = ({
                                          error,
                                          requestParams,
                                        }: ProductCreditTransactionByIDErrorResponse): RejectFetchProductCreditTransactionByIDAction => ({
  type:    types.FETCH_PRODUCT_CREDIT_TRANSACTION_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateProductCreditTransaction = (productCreditTransactionID: number, name: string, company: string | null, phoneNumber: string | null, emailAddress: string | null): UpdateProductCreditTransactionAction => ({
  type:    types.UPDATE_PRODUCT_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    productCreditTransactionID,
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveUpdateProductCreditTransaction = (productCreditTransaction: ProductCreditTransaction): ResolveUpdateProductCreditTransactionAction => ({
  type:    types.UPDATE_PRODUCT_CREDIT_TRANSACTION,
  success: true,
  payload: {
    productCreditTransaction,
  },
})

export const rejectUpdateProductCreditTransaction = (payload: PutProductCreditTransactionErrorResponse): RejectUpdateProductCreditTransactionAction => ({
  type:    types.UPDATE_PRODUCT_CREDIT_TRANSACTION,
  success: false,
  payload,
})


export const deleteProductCreditTransaction = (productCreditTransactionID: number): DeleteProductCreditTransactionAction => ({
  type:    types.DELETE_PRODUCT_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    productCreditTransactionID,
  },
})

export const resolveDeleteProductCreditTransaction = (productCreditTransaction: ProductCreditTransaction): ResolveDeleteProductCreditTransactionAction => ({
  type:    types.DELETE_PRODUCT_CREDIT_TRANSACTION,
  success: true,
  payload: {
    productCreditTransaction,
  },
})

export const rejectDeleteProductCreditTransaction = (payload: ProductCreditTransactionByIDErrorResponse): RejectDeleteProductCreditTransactionAction => ({
  type:    types.DELETE_PRODUCT_CREDIT_TRANSACTION,
  success: false,
  payload,
})


export const createProductCreditTransaction = (name: string, company: string | null,  phoneNumber: string | null, emailAddress: string | null): CreateProductCreditTransactionAction => ({
  type:    types.CREATE_PRODUCT_CREDIT_TRANSACTION,
  success: undefined,
  payload: {
    name,
    company,
    phoneNumber,
    emailAddress,
  },
})

export const resolveCreateProductCreditTransaction = (productCreditTransaction: ProductCreditTransaction): ResolveCreateProductCreditTransactionAction => ({
  type:    types.CREATE_PRODUCT_CREDIT_TRANSACTION,
  success: true,
  payload: {
    productCreditTransaction,
  },
})

export const rejectCreateProductCreditTransaction = (payload: PostProductCreditTransactionErrorResponse): RejectCreateProductCreditTransactionAction => ({
  type:    types.CREATE_PRODUCT_CREDIT_TRANSACTION,
  success: false,
  payload,
})

export type ProductCreditTransactionsActions =
  FetchProductCreditTransactionsAction
  | RejectFetchProductCreditTransactionsAction
  | ResolveFetchProductCreditTransactionsAction
  | FetchProductCreditTransactionByIDAction
  | ResolveFetchProductCreditTransactionByIDAction
  | RejectFetchProductCreditTransactionByIDAction
  | UpdateProductCreditTransactionAction
  | RejectUpdateProductCreditTransactionAction
  | ResolveUpdateProductCreditTransactionAction
  | CreateProductCreditTransactionAction
  | ResolveCreateProductCreditTransactionAction
  | RejectCreateProductCreditTransactionAction
  | DeleteProductCreditTransactionAction
  | ResolveDeleteProductCreditTransactionAction
  | RejectDeleteProductCreditTransactionAction
  | ClearFlashMessagesAction
  | LocationChangeAction
