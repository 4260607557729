import { Cmd, loop, Loop } from 'redux-loop'
import API from '../../api'
import Dashboard from '../../model/Dashboard'
import {
  DashboardActions, fetchDashboard,
  FetchDashboardAction,
  rejectFetchDashboard,
  RejectFetchDashboardAction,
  resolveFetchDashboard,
  ResolveFetchDashboardAction, SetDashboardBranchAction, setDashboardPeriod, SetDashboardPeriodAction,
} from '../actions/dashboard'
import * as actionTypes from '../constants/ActionTypes'

interface DashboardReducerState {
  dashboard: Dashboard | null
  isFetching: boolean
  error: Error | null
  period: 'week' | 'month' | 'today' | 'last-week' | 'last-month' | 'year-to-date'
  branchID?: number
}

const initialState: DashboardReducerState = {
  dashboard:  null,
  isFetching: false,
  error:      null,
  period:     'today',
  branchID:   undefined,
}

export default function dashboard(state: DashboardReducerState = initialState, action: DashboardActions): DashboardReducerState | Loop<DashboardReducerState> {
  switch (action.type) {
    case actionTypes.FETCH_DASHBOARD: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchDashboardAction
          const { period, branchID } = payload

          return loop(
            {
              ...state,
              period,
              branchID,
              isFetching: true,
            },
            Cmd.run(API.getDashboard, {
              successActionCreator: resolveFetchDashboard,
              failActionCreator:    rejectFetchDashboard,
              args:                 [period, branchID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchDashboardAction
          const { dashboard } = payload

          return {
            ...state,
            isFetching: false,
            dashboard,
          }
        }

        case false: {
          const { payload } = action as RejectFetchDashboardAction
          const { error, requestParams } = payload

          return {
            ...state,
            isFetching: false,
            error,
          }
        }
      }

      break
    }

    case actionTypes.SET_DASHBOARD_PERIOD: {
      let { branchID } = state
      let { period } = action as SetDashboardPeriodAction

      return loop(
        {
          ...state,
          period,
        },
        Cmd.action(fetchDashboard(period, branchID)),
      )
    }

    case actionTypes.SET_DASHBOARD_BRANCH_ID: {
      let { period } = state
      let branchID = action.branchID || undefined

      return loop(
        {
          ...state,
          branchID,
        },
        Cmd.action(fetchDashboard(period, branchID)),
      )
    }

    default:
      return Object.assign({}, state)
  }
}
