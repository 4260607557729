import { Cmd, loop, Loop } from 'redux-loop'
import {
  FlashMessage, FlashMessageType,
  removeFlash,
  RemoveFlashMessageAction,
  ShowFlashMessageAction,
  UIActionTypes,
} from '../actions/ui'
import {
  CLEAR_FLASH_MESSAGES,
  REMOVE_FLASH_MESSAGE,
  SHOW_FLASH_MESSAGE,
  TOGGLE_NAVIGATION,
} from '../constants/ActionTypes'

interface UIReducerState {
  navigationOpen: boolean
  flashMessages: FlashMessage[]
}

const initialState = (): UIReducerState => {
  return {
    navigationOpen: false,
    flashMessages:  [],
  }
}

export default function ui(state: UIReducerState = initialState(), action: UIActionTypes): UIReducerState | Loop<UIReducerState> {
  switch (action.type) {
    case TOGGLE_NAVIGATION: {
      return {
        ...state,
        navigationOpen: !state.navigationOpen
      }
    }

    case SHOW_FLASH_MESSAGE: {
      let { payload } = action as ShowFlashMessageAction

      return loop({
          ...state,
          flashMessages: [...state.flashMessages, payload],
        },
        Cmd.list([
          Cmd.setTimeout(Cmd.action(removeFlash(payload)), 5000),
        ]),
      )
    }

    case REMOVE_FLASH_MESSAGE: {
      let { payload } = action as RemoveFlashMessageAction

      let index = state.flashMessages.findIndex(flashMessage => flashMessage.message == payload.message && flashMessage.type == payload.type)
      let flashMessages = state.flashMessages.slice()
      flashMessages.splice(index, 1)

      return {
        ...state,
        flashMessages
      }
    }


    default:
      return Object.assign({}, state)
  }
}
