import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import Employee from '../../model/Employee'
import {
  CreateEmployeeAction,
  DeleteEmployeeAction,
  FetchEmployeeByIDAction,
  FetchEmployeesAction,
  EmployeesActions,
  rejectCreateEmployee,
  RejectCreateEmployeeAction,
  rejectDeleteEmployee,
  RejectDeleteEmployeeAction,
  rejectFetchEmployeeByID,
  RejectFetchEmployeeByIDAction,
  rejectFetchEmployees,
  RejectFetchEmployeesAction,
  rejectUpdateEmployee,
  RejectUpdateEmployeeAction,
  resolveCreateEmployee,
  ResolveCreateEmployeeAction,
  resolveDeleteEmployee,
  ResolveDeleteEmployeeAction,
  resolveFetchEmployeeByID,
  ResolveFetchEmployeeByIDAction,
  resolveFetchEmployees,
  ResolveFetchEmployeesAction,
  resolveUpdateEmployee,
  ResolveUpdateEmployeeAction,
  UpdateEmployeeAction,
} from '../actions/employees'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForEmployeePage } from '../selectors/employees'

interface IDMappedEmployees {
  [id: number]: Employee
}

interface EmployeesReducerState {
  byID: IDMappedEmployees
  pages: { [hash: string]: EmployeesReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class EmployeesReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: EmployeesReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: EmployeesReducerState = initialState, action: EmployeesActions): EmployeesReducerState | Loop<EmployeesReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_EMPLOYEES: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchEmployeesAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new EmployeesReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForEmployeePage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getEmployees, {
              successActionCreator: resolveFetchEmployees,
              failActionCreator:    rejectFetchEmployees,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchEmployeesAction
          const { employees, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForEmployeePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       employees.map((employee: Employee) => employee.id),
            paginationInfo: paginationInfo,
          }

          // Map employee ids to employees
          let idMappedEmployees: IDMappedEmployees = {}
          employees.forEach(employee => {
            idMappedEmployees[employee.id] = employee
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedEmployees),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchEmployeesAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForEmployeePage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_EMPLOYEE_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchEmployeeByIDAction
          const { employeeID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [employeeID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [employeeID]: null,
              },
            },
            Cmd.run(API.getEmployeeByID, {
              successActionCreator: resolveFetchEmployeeByID,
              failActionCreator:    rejectFetchEmployeeByID,
              args:                 [employeeID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchEmployeeByIDAction
          const { employee } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [employee.id]: employee,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [employee.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchEmployeeByIDAction
          const { error, requestParams } = payload
          const { employeeID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [employeeID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [employeeID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_EMPLOYEE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdateEmployeeAction
          const { employeeID, name, icNumber, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [employeeID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [employeeID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putEmployee, {
              successActionCreator: resolveUpdateEmployee,
              failActionCreator:    rejectUpdateEmployee,
              args:                 [employeeID, name, icNumber, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdateEmployeeAction
          const { employee } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [employee.id]: employee,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [employee.id]: false,
              },
              successFlashMessage: 'Employee successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdateEmployeeAction
          const { error, requestParams } = payload
          const { employeeID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [employeeID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [employeeID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_EMPLOYEE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeleteEmployeeAction
          const { employeeID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [employeeID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [employeeID]: null,
              },
            },
            Cmd.run(API.deleteEmployee, {
              successActionCreator: resolveDeleteEmployee,
              failActionCreator:    rejectDeleteEmployee,
              args:                 [employeeID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeleteEmployeeAction
          const { employee } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[employee.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [employee.id]: false,
              },
              successFlashMessage: 'Employee successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/employees')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeleteEmployeeAction
          const { error, requestParams } = payload
          const { employeeID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [employeeID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [employeeID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_EMPLOYEE: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreateEmployeeAction
          const { name, icNumber, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postEmployee, {
              successActionCreator: resolveCreateEmployee,
              failActionCreator:    rejectCreateEmployee,
              args:                 [name, icNumber, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreateEmployeeAction
          const { employee } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [employee.id]: employee,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Employee successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/employees/${employee.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreateEmployeeAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
