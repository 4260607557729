import { Money, Currencies } from 'ts-money'
import Category, { CategoryJSON } from './Category'
import SmsMessage, { SmsMessageJSON } from './SmsMessage'

export default class SmsBlast {
  id: number
  filters: string
  title: string | null
  body: string
  creditCost: number
  messages: SmsMessage[]
  startedSendingAt: Date | null
  completedSendingAt: Date | null
  createdAt: Date
  updatedAt: Date

  constructor(json: SmsBlastJSON) {
    this.id = json.id
    this.filters = json.filters
    this.title = json.title
    this.body = json.body
    this.creditCost = json.creditCost
    this.messages = []
    if (json.messages != null) {
      this.messages = json.messages.map(v => new SmsMessage(v))
    }
    this.startedSendingAt = json.startedSendingAt != null ? new Date(json.startedSendingAt) : null
    this.completedSendingAt = json.completedSendingAt != null ? new Date(json.completedSendingAt) : null
    this.createdAt =  new Date(json.createdAt)
    this.updatedAt =  new Date(json.updatedAt)
  }

  get [Symbol.toStringTag]() {
    return 'SmsBlast'
  }
}

export interface SmsBlastJSON {
  id: number
  filters: string
  title: string | null
  body: string
  creditCost: number
  messages: SmsMessageJSON[]
  startedSendingAt: string | null
  completedSendingAt: string | null
  createdAt: Date
  updatedAt: Date
}
