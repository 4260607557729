import { LocationChangeAction, push, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { Cmd, Loop, loop } from 'redux-loop'
import API from '../../api'
import PaginationInfo from '../../model/PaginationInfo'
import PartnerAgent from '../../model/PartnerAgent'
import {
  CreatePartnerAgentAction,
  DeletePartnerAgentAction,
  FetchPartnerAgentByIDAction,
  FetchPartnerAgentsAction,
  PartnerAgentsActions,
  rejectCreatePartnerAgent,
  RejectCreatePartnerAgentAction,
  rejectDeletePartnerAgent,
  RejectDeletePartnerAgentAction,
  rejectFetchPartnerAgentByID,
  RejectFetchPartnerAgentByIDAction,
  rejectFetchPartnerAgents,
  RejectFetchPartnerAgentsAction,
  rejectUpdatePartnerAgent,
  RejectUpdatePartnerAgentAction,
  resolveCreatePartnerAgent,
  ResolveCreatePartnerAgentAction,
  resolveDeletePartnerAgent,
  ResolveDeletePartnerAgentAction,
  resolveFetchPartnerAgentByID,
  ResolveFetchPartnerAgentByIDAction,
  resolveFetchPartnerAgents,
  ResolveFetchPartnerAgentsAction,
  resolveUpdatePartnerAgent,
  ResolveUpdatePartnerAgentAction,
  UpdatePartnerAgentAction,
} from '../actions/partnerAgents'
import * as actionTypes from '../constants/ActionTypes'
import { CLEAR_FLASH_MESSAGES } from '../constants/ActionTypes'
import { hashKeyForPartnerAgentPage } from '../selectors/partnerAgents'

interface IDMappedPartnerAgents {
  [id: number]: PartnerAgent
}

interface PartnerAgentsReducerState {
  byID: IDMappedPartnerAgents
  pages: { [hash: string]: PartnerAgentsReducerPage }
  isFetchingByID: { [id: number]: boolean }
  successFlashMessage: string | null
  errorByID: { [id: number]: Error | null }
}

export class PartnerAgentsReducerPage {
  error: Error | null = null
  isFetching: boolean = false
  childIDs: number[] = []
  paginationInfo: PaginationInfo = new PaginationInfo()
  isInitialized: boolean = false
}

const initialState: PartnerAgentsReducerState = {
  byID:                {},
  pages:               {},
  isFetchingByID:      {},
  successFlashMessage: null,
  errorByID:           {},
}

export default (state: PartnerAgentsReducerState = initialState, action: PartnerAgentsActions): PartnerAgentsReducerState | Loop<PartnerAgentsReducerState> => {
  switch (action.type) {

    case actionTypes.FETCH_PARTNER_AGENTS: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPartnerAgentsAction
          const { sorting, page, limit, search } = payload

          // Build page object
          const pageObject = new PartnerAgentsReducerPage()
          pageObject.isFetching = true
          pageObject.isInitialized = true

          // Create a hash key for the page
          const pageKey = hashKeyForPartnerAgentPage(payload)

          // Set state and fetch
          return loop(
            Object.assign({}, state, {
              pages: Object.assign({}, state.pages, {
                [pageKey]: pageObject,
              }),
            }),
            Cmd.run(API.getPartnerAgents, {
              successActionCreator: resolveFetchPartnerAgents,
              failActionCreator:    rejectFetchPartnerAgents,
              args:                 [sorting, page, limit, search],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPartnerAgentsAction
          const { partnerAgents, paginationInfo, requestParams } = payload

          const pageKey = hashKeyForPartnerAgentPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching:     false,
            error:          null,
            childIDs:       partnerAgents.map((partnerAgent: PartnerAgent) => partnerAgent.id),
            paginationInfo: paginationInfo,
          }

          // Map partnerAgent ids to partnerAgents
          let idMappedPartnerAgents: IDMappedPartnerAgents = {}
          partnerAgents.forEach(partnerAgent => {
            idMappedPartnerAgents[partnerAgent.id] = partnerAgent
          })


          // Place in correct page
          return Object.assign({}, state, {
            byID:  Object.assign({}, state.byID, idMappedPartnerAgents),
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }

        case false: {
          const { payload } = action as RejectFetchPartnerAgentsAction
          const { error, requestParams } = payload

          // Create a hash key for the page
          const pageKey = hashKeyForPartnerAgentPage(requestParams)

          // Page object
          let pageObject = {
            ...state.pages[pageKey],
            isFetching: false,
            error,
          }

          // Place in correct page
          return Object.assign({}, state, {
            pages: Object.assign({}, state.pages, {
              [pageKey]: pageObject,
            }),
          })
        }
      }

      break
    }

    case actionTypes.FETCH_PARTNER_AGENT_BY_ID: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as FetchPartnerAgentByIDAction
          const { partnerAgentID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [partnerAgentID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [partnerAgentID]: null,
              },
            },
            Cmd.run(API.getPartnerAgentByID, {
              successActionCreator: resolveFetchPartnerAgentByID,
              failActionCreator:    rejectFetchPartnerAgentByID,
              args:                 [partnerAgentID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveFetchPartnerAgentByIDAction
          const { partnerAgent } = payload

          return {
            ...state,
            byID:           {
              ...state.byID,
              [partnerAgent.id]: partnerAgent,
            },
            isFetchingByID: {
              ...state.isFetchingByID,
              [partnerAgent.id]: false,
            },
          }
        }

        case false: {
          const { payload } = action as RejectFetchPartnerAgentByIDAction
          const { error, requestParams } = payload
          const { partnerAgentID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [partnerAgentID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [partnerAgentID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.UPDATE_PARTNER_AGENT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as UpdatePartnerAgentAction
          const { partnerAgentID, name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [partnerAgentID]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [partnerAgentID]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.putPartnerAgent, {
              successActionCreator: resolveUpdatePartnerAgent,
              failActionCreator:    rejectUpdatePartnerAgent,
              args:                 [partnerAgentID, name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveUpdatePartnerAgentAction
          const { partnerAgent } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [partnerAgent.id]: partnerAgent,
              },
              isFetchingByID:      {
                ...state.isFetchingByID,
                [partnerAgent.id]: false,
              },
              successFlashMessage: 'Partner agent successfully updated!',
            },
            Cmd.list([
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectUpdatePartnerAgentAction
          const { error, requestParams } = payload
          const { partnerAgentID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [partnerAgentID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [partnerAgentID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.DELETE_PARTNER_AGENT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as DeletePartnerAgentAction
          const { partnerAgentID } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID: {
                ...state.isFetchingByID,
                [partnerAgentID]: true,
              },
              errorByID:      {
                ...state.errorByID,
                [partnerAgentID]: null,
              },
            },
            Cmd.run(API.deletePartnerAgent, {
              successActionCreator: resolveDeletePartnerAgent,
              failActionCreator:    rejectDeletePartnerAgent,
              args:                 [partnerAgentID],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveDeletePartnerAgentAction
          const { partnerAgent } = payload

          // Deletey-poo
          let byID = {
            ...state.byID,
          }
          delete byID[partnerAgent.id]

          return loop(
            {
              ...state,
              byID,
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [partnerAgent.id]: false,
              },
              successFlashMessage: 'Partner agent successfully deleted!',
            },
            Cmd.list([
              Cmd.action(push('/partner-agents')),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectDeletePartnerAgentAction
          const { error, requestParams } = payload
          const { partnerAgentID } = requestParams

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [partnerAgentID]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [partnerAgentID]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CREATE_PARTNER_AGENT: {
      let { success } = action
      switch (success) {
        case undefined: { // Make request
          let { payload } = action as CreatePartnerAgentAction
          const { name, company, phoneNumber, emailAddress } = payload

          // Set state and fetch
          return loop(
            {
              ...state,
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: true,
              },
              errorByID:           {
                ...state.errorByID,
                [-1]: null,
              },
              successFlashMessage: null,
            },
            Cmd.run(API.postPartnerAgent, {
              successActionCreator: resolveCreatePartnerAgent,
              failActionCreator:    rejectCreatePartnerAgent,
              args:                 [name, company, phoneNumber, emailAddress],
            }),
          )
        }

        case true: {
          let { payload } = action as ResolveCreatePartnerAgentAction
          const { partnerAgent } = payload

          return loop({
              ...state,
              byID:                {
                ...state.byID,
                [partnerAgent.id]: partnerAgent,
              },
              pages:               {},
              isFetchingByID:      {
                ...state.isFetchingByID,
                [-1]: false,
              },
              successFlashMessage: 'Partner agent successfully created!',
            },
            Cmd.list([
              Cmd.action(push(`/partner-agents/${partnerAgent.id}`)),
              Cmd.setTimeout(Cmd.action({ type: CLEAR_FLASH_MESSAGES }), 5000),
            ]),
          )
        }

        case false: {
          const { payload } = action as RejectCreatePartnerAgentAction
          const { error } = payload

          // Place in correct page
          return {
            ...state,
            isFetchingByID: {
              ...state.isFetchingByID,
              [-1]: false,
            },
            errorByID:      {
              ...state.errorByID,
              [-1]: error,
            },
          }
        }
      }

      break
    }

    case actionTypes.CLEAR_FLASH_MESSAGES: {
      return {
        ...state,
        successFlashMessage: null,
      }
    }

  }

// Default
  return state
}
