import Customer, { CustomerFilters, CustomerJSON, CustomerStats, CustomerStatsJSON } from '../model/Customer'
import PaginationInfo from '../model/PaginationInfo'
import ProductCreditBalance, { ProductCreditBalanceJSON } from '../model/ProductCreditBalance'
import LoopError from '../store/errors/LoopError'
import { fetchWithErrors, HTTPMethods, newRequest, parseResponse, token, urlForEndpoint } from './helpers'

export const getCustomers = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: CustomerFilters, search: string): Promise<CustomersResponse> => {

  // Prepare filters
  let flattenedFilters = getFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`customers`, {
    sorting,
    page,
    limit,
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { customers: customersJSON, paginationInfo } = await parseResponse(response)

    let customers = customersJSON.map((customerJSON: CustomerJSON) => new Customer(customerJSON))

    return {
      customers,
      paginationInfo,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

export const getCustomerIDs = async (sorting: string = 'id', page: number = 1, limit: number = 30, filters: CustomerFilters, search: string): Promise<CustomerIDsResponse> => {

  // Prepare filters
  let flattenedFilters = getFlattenedFilters(filters)

  // Build request
  const url = urlForEndpoint(`customer-ids`, {
    search,
    ...flattenedFilters,
  })
  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { customerIDs } = await parseResponse(response)

    return {
      customerIDs,
      requestParams: {
        sorting,
        page,
        limit,
        filters,
        search,
      },
    }

  } catch (err) {
    throw new LoopError(err, { sorting, page, limit, search })
  }
}

const getFlattenedFilters = (filters: CustomerFilters): any => {
  // Prepare filters
  let flattenedFilters = {
    lastPurchasedProduct: filters.lastPurchasedProduct,
    frequencyOfVisits:    JSON.stringify(filters.frequencyOfVisits),
    numberOfVisits:       JSON.stringify(filters.numberOfVisits),
  }
  if (filters.lastPurchasedProduct == null || filters.lastPurchasedProduct.length == 0) {
    // @ts-ignore
    delete flattenedFilters.lastPurchasedProduct
  }
  if (filters.frequencyOfVisits == null) {
    // @ts-ignore
    delete flattenedFilters.frequencyOfVisits
  }
  if (filters.numberOfVisits == null) {
    // @ts-ignore
    delete flattenedFilters.numberOfVisits
  }
  return flattenedFilters
}

export const getCustomerByID = async (customerID: number): Promise<Customer> => {

  // Build request
  const url = urlForEndpoint(`customers/${customerID}`)

  const request = newRequest(HTTPMethods.GET, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { customerID })
  }

}

export const getCustomerStatsByID = async (customerID: number): Promise<CustomerStatsResponse> => {

  // Build request
  const url = urlForEndpoint(`customers/${customerID}/stats`)

  const request = newRequest(HTTPMethods.GET, token())

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { stats, creditBalances: creditBalancesJSON } = await parseResponse(response)

    const creditBalances = (creditBalancesJSON as Array<ProductCreditBalanceJSON>).map(json => new ProductCreditBalance(json))

    return {
      stats: new CustomerStats(stats as CustomerStatsJSON),
      creditBalances
    }
  } catch (err) {
    throw new LoopError(err, { customerID })
  }

}

export const putCustomer = async (customerID: number, name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): Promise<Customer> => {
  // Build request
  const url = urlForEndpoint(`customers/${customerID}`)

  const request = newRequest(HTTPMethods.PUT, token())
  request.body = JSON.stringify({
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response
    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { customerID, name, phoneNumber, emailAddress })
  }
}

export const deleteCustomer = async (customerID: number): Promise<Customer> => {
  // Build request
  const url = urlForEndpoint(`customers/${customerID}`)

  const request = newRequest(HTTPMethods.DELETE, token())

  // Fetch
  const response = await fetchWithErrors(url, request)

  // Handle errors and return response
  try {
    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { customerID })
  }
}

export const postCustomer = async (name: string, phoneNumber: string, emailAddress: string | null, address: string | null, gender: string | null, dateOfBirth: string | null): Promise<Customer> => {
  // Build request
  const url = urlForEndpoint(`customers`)

  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    name,
    phoneNumber,
    emailAddress,
    address,
    gender,
    dateOfBirth,
  })

  try {
    // Fetch
    const response = await fetchWithErrors(url, request)

    // Handle errors and return response

    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { name, phoneNumber, emailAddress })
  }
}

export const linkVehicleToCustomer = async (customerID: number, licensePlate: string): Promise<Customer> => {
  // Build request
  const url = urlForEndpoint(`customers/${customerID}/vehicles`)
  const request = newRequest(HTTPMethods.POST, token())
  request.body = JSON.stringify({
    licensePlate,
  })

  try {
    const response = await fetchWithErrors(url, request)
    const { customer } = await parseResponse(response)
    return new Customer(customer as CustomerJSON)
  } catch (err) {
    throw new LoopError(err, { customerID })
  }
}

export interface CustomerIDsResponse {
  customerIDs: number[]
  requestParams: CustomersRequestParams
}

export interface CustomerStatsResponse {
  stats: CustomerStats
  creditBalances: Array<ProductCreditBalance>
}

export interface CustomersResponse {
  customers: Customer[]
  paginationInfo: PaginationInfo
  requestParams: CustomersRequestParams
}

export interface CustomersErrorResponse {
  error: Error
  requestParams: CustomersRequestParams
}

export interface CustomersRequestParams {
  sorting: string
  page: number
  limit: number
  search: string
  filters: CustomerFilters
}

export interface CustomerByIDRequestParams {
  customerID: number
}

export interface CustomerByIDErrorResponse {
  error: Error
  requestParams: CustomerByIDRequestParams
}

export interface PostCustomerRequestParams {
  name: string
  phoneNumber: string
  emailAddress: string | null
  gender: string | null
  address: string | null
  dateOfBirth: string | null
}

export interface PostCustomerErrorResponse {
  error: Error
  requestParams: PostCustomerRequestParams
}

export interface PutCustomerRequestParams extends PostCustomerRequestParams {
  customerID: number
}

export interface PutCustomerErrorResponse {
  error: Error
  requestParams: PutCustomerRequestParams
}