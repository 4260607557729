import { Money } from 'ts-money'
import {
  PostSmsBlastErrorResponse,
  PostSmsBlastRequestParams,
  SmsBlastByIDErrorResponse,
  SmsBlastsErrorResponse,
  SmsBlastsRequestParams,
  SmsBlastsResponse,
  PutSmsBlastErrorResponse,
  PutSmsBlastRequestParams,
} from '../../api/smsBlasts'
import PaginationInfo from '../../model/PaginationInfo'
import SmsBlast from '../../model/SmsBlast'
import * as types from '../constants/ActionTypes'
import { ClearFlashMessagesAction } from './general'


export interface FetchSmsBlastsAction {
  type: typeof types.FETCH_SMS_BLASTS
  success: undefined
  payload: SmsBlastsRequestParams
}

export interface ResolveFetchSmsBlastsAction {
  type: typeof types.FETCH_SMS_BLASTS
  success: boolean
  payload: {
    smsBlasts: SmsBlast[]
    paginationInfo: PaginationInfo
    requestParams: SmsBlastsRequestParams
  }
}

export interface RejectFetchSmsBlastsAction {
  type: typeof types.FETCH_SMS_BLASTS
  success: boolean
  payload: {
    error: Error
    requestParams: SmsBlastsRequestParams
  }
}

export interface FetchSmsBlastByIDAction {
  type: typeof types.FETCH_SMS_BLAST_BY_ID
  success: undefined
  payload: {
    smsBlastID: number
  }
}

export interface ResolveFetchSmsBlastByIDAction {
  type: typeof types.FETCH_SMS_BLAST_BY_ID
  success: boolean
  payload: {
    smsBlast: SmsBlast
  }
}

export interface RejectFetchSmsBlastByIDAction {
  type: typeof types.FETCH_SMS_BLAST_BY_ID
  success: boolean
  payload: {
    error: Error
    requestParams: {
      smsBlastID: number
    }
  }
}

export interface UpdateSmsBlastAction {
  type: typeof types.UPDATE_SMS_BLAST
  success: undefined
  payload: PutSmsBlastRequestParams
}

export interface ResolveUpdateSmsBlastAction {
  type: typeof types.UPDATE_SMS_BLAST
  success: boolean
  payload: {
    smsBlast: SmsBlast
  }
}

export interface RejectUpdateSmsBlastAction {
  type: typeof types.UPDATE_SMS_BLAST
  success: boolean
  payload: PutSmsBlastErrorResponse
}


export interface DeleteSmsBlastAction {
  type: typeof types.DELETE_SMS_BLAST
  success: undefined
  payload: {
    smsBlastID: number
  }
}


export interface ResolveDeleteSmsBlastAction {
  type: typeof types.DELETE_SMS_BLAST
  success: boolean
  payload: {
    smsBlast: SmsBlast
  }
}

export interface RejectDeleteSmsBlastAction {
  type: typeof types.DELETE_SMS_BLAST
  success: boolean
  payload: SmsBlastByIDErrorResponse
}


export interface SendSmsBlastAction {
  type: typeof types.SEND_SMS_BLAST
  success: undefined
  payload: {
    smsBlastID: number
  }
}


export interface ResolveSendSmsBlastAction {
  type: typeof types.SEND_SMS_BLAST
  success: boolean
  payload: {
    smsBlast: SmsBlast
  }
}

export interface RejectSendSmsBlastAction {
  type: typeof types.SEND_SMS_BLAST
  success: boolean
  payload: SmsBlastByIDErrorResponse
}


export interface CreateSmsBlastAction {
  type: typeof types.CREATE_SMS_BLAST
  success: undefined
  payload: PostSmsBlastRequestParams
}

export interface ResolveCreateSmsBlastAction {
  type: typeof types.CREATE_SMS_BLAST
  success: boolean
  payload: {
    smsBlast: SmsBlast
  }
}

export interface RejectCreateSmsBlastAction {
  type: typeof types.CREATE_SMS_BLAST
  success: boolean
  payload: PostSmsBlastErrorResponse
}


export const fetchSmsBlasts = (sorting: string, page: number, limit: number, search: string): FetchSmsBlastsAction => ({
  type:    types.FETCH_SMS_BLASTS,
  success: undefined,
  payload: {
    sorting,
    page,
    limit,
    search,
  },
})

export const resolveFetchSmsBlasts = ({
                                       smsBlasts,
                                       paginationInfo,
                                       requestParams,
                                     }: SmsBlastsResponse): ResolveFetchSmsBlastsAction => ({
  type:    types.FETCH_SMS_BLASTS,
  success: true,
  payload: {
    smsBlasts,
    paginationInfo,
    requestParams,
  },
})

export const rejectFetchSmsBlasts = ({ error, requestParams }: SmsBlastsErrorResponse): RejectFetchSmsBlastsAction => ({
  type:    types.FETCH_SMS_BLASTS,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const fetchSmsBlastByID = (smsBlastID: number): FetchSmsBlastByIDAction => ({
  type:    types.FETCH_SMS_BLAST_BY_ID,
  success: undefined,
  payload: {
    smsBlastID,
  },
})

export const resolveFetchSmsBlastByID = (smsBlast: SmsBlast): ResolveFetchSmsBlastByIDAction => ({
  type:    types.FETCH_SMS_BLAST_BY_ID,
  success: true,
  payload: {
    smsBlast,
  },
})

export const rejectFetchSmsBlastByID = ({
                                         error,
                                         requestParams,
                                       }: SmsBlastByIDErrorResponse): RejectFetchSmsBlastByIDAction => ({
  type:    types.FETCH_SMS_BLAST_BY_ID,
  success: false,
  payload: {
    error,
    requestParams,
  },
})

export const updateSmsBlast = (smsBlastID: number, filters: string, title: string, body: string, recipientIDs: number[]): UpdateSmsBlastAction => ({
  type:    types.UPDATE_SMS_BLAST,
  success: undefined,
  payload: {
    smsBlastID,
    filters,
    title,
    body,
    recipientIDs,
  },
})

export const resolveUpdateSmsBlast = (smsBlast: SmsBlast): ResolveUpdateSmsBlastAction => ({
  type:    types.UPDATE_SMS_BLAST,
  success: true,
  payload: {
    smsBlast,
  },
})

export const rejectUpdateSmsBlast = (payload: PutSmsBlastErrorResponse): RejectUpdateSmsBlastAction => ({
  type:    types.UPDATE_SMS_BLAST,
  success: false,
  payload,
})


export const deleteSmsBlast = (smsBlastID: number): DeleteSmsBlastAction => ({
  type:    types.DELETE_SMS_BLAST,
  success: undefined,
  payload: {
    smsBlastID,
  },
})

export const resolveDeleteSmsBlast = (smsBlast: SmsBlast): ResolveDeleteSmsBlastAction => ({
  type:    types.DELETE_SMS_BLAST,
  success: true,
  payload: {
    smsBlast,
  },
})

export const rejectDeleteSmsBlast = (payload: SmsBlastByIDErrorResponse): RejectDeleteSmsBlastAction => ({
  type:    types.DELETE_SMS_BLAST,
  success: false,
  payload,
})

export const sendSmsBlast = (smsBlastID: number): SendSmsBlastAction => ({
  type:    types.SEND_SMS_BLAST,
  success: undefined,
  payload: {
    smsBlastID,
  },
})

export const resolveSendSmsBlast = (smsBlast: SmsBlast): ResolveSendSmsBlastAction => ({
  type:    types.SEND_SMS_BLAST,
  success: true,
  payload: {
    smsBlast,
  },
})

export const rejectSendSmsBlast = (payload: SmsBlastByIDErrorResponse): RejectSendSmsBlastAction => ({
  type:    types.SEND_SMS_BLAST,
  success: false,
  payload,
})

export const createSmsBlast = (filters: string, title: string, body: string, recipientIDs: number[]): CreateSmsBlastAction => ({
  type:    types.CREATE_SMS_BLAST,
  success: undefined,
  payload: {
    filters,
    title,
    body,
    recipientIDs,
  },
})

export const resolveCreateSmsBlast = (smsBlast: SmsBlast): ResolveCreateSmsBlastAction => ({
  type:    types.CREATE_SMS_BLAST,
  success: true,
  payload: {
    smsBlast,
  },
})

export const rejectCreateSmsBlast = (payload: PostSmsBlastErrorResponse): RejectCreateSmsBlastAction => ({
  type:    types.CREATE_SMS_BLAST,
  success: false,
  payload,
})


export type SmsBlastsActions =
  FetchSmsBlastsAction
  | RejectFetchSmsBlastsAction
  | ResolveFetchSmsBlastsAction
  | FetchSmsBlastByIDAction
  | ResolveFetchSmsBlastByIDAction
  | RejectFetchSmsBlastByIDAction
  | UpdateSmsBlastAction
  | RejectUpdateSmsBlastAction
  | ResolveUpdateSmsBlastAction
  | CreateSmsBlastAction
  | ResolveCreateSmsBlastAction
  | RejectCreateSmsBlastAction
  | DeleteSmsBlastAction
  | ResolveDeleteSmsBlastAction
  | RejectDeleteSmsBlastAction
  | SendSmsBlastAction
  | ResolveSendSmsBlastAction
  | RejectSendSmsBlastAction
  | ClearFlashMessagesAction
