import { Currencies, Money } from 'ts-money'

export default class InvoiceItem {
  id: number
  invoiceID: number
  quantity: number
  productName: string
  productID: number
  unitPrice: Money
  totalPrice: Money
  discount: Money
  preDiscount?: Money
  notes?: string

  constructor(json: InvoiceItemJSON) {
    this.id = json.id
    this.invoiceID = json.invoiceID
    this.quantity = json.quantity
    this.productName = json.productName
    this.productID = json.productID
    this.unitPrice = Money.fromInteger(json.unitAmount, 'MYR')
    this.totalPrice = Money.fromInteger(json.totalAmount, 'MYR')
    this.discount = Money.fromInteger(json.discountAmount, Currencies.MYR)
    this.notes = json.notes
    if (json.preDiscountAmount != null) {
      this.preDiscount = Money.fromInteger(json.preDiscountAmount, Currencies.MYR)
    }
  }

  get [Symbol.toStringTag]() {
    return 'InvoiceItem'
  }
}

export interface InvoiceItemJSON {
  id: number
  invoiceID: number
  quantity: number
  productName: string
  productID: number
  unitAmount: number
  totalAmount: number
  discountAmount: number
  preDiscountAmount?: number
  notes?: string
}
